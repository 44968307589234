import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import BeneficiaryFilter from "../../features/beneficiary/beneficiaryFilter";
import BeneficiaryList from "../../features/beneficiary/beneficiaryList";
import { useGridApiRef } from "@mui/x-data-grid";
import { useLazyGetBeneficiaryQuery } from "../../services/beneficiary";
import { showError } from "../../constants/toasts";

const BeneficiaryCustomers = () => {
    const navigate = useNavigate();
    const[isLoading,setIsLoading]=useState(false);
   const[beneficiary,setBeneficiary]=useState<any>([]);
    const[getAllData]=useLazyGetBeneficiaryQuery();
    const[currentStatus,setCurrentStatus]=useState(-1);
    const getAllDataList=async()=>{
     try{ 
      setIsLoading(true);
      const response=await getAllData(
        {
          page: 1,
          query: "",
        }).unwrap();
        if(response?.statusCode==200)
          { 
            
            console.log("TESSTTTTTTTTTT",response?.data);
            setBeneficiary(response?.data);
          }}
      catch(error:any)
        {
          console.log("ERRROR",error)
          showError(error?.data?.message);
        }
        finally{
          setIsLoading(false);
        }
        }
        useEffect(()=>{
            getAllDataList();
        },[])
  const[selectedRow,setSelectedRow]=useState<any>({
    row:[],
    column:[]
  });
  console.log('selectedRow :', selectedRow);

  const gridApi=useGridApiRef();

    const[searchTerm,setSearchTerm]=useState("");
    const[columnVisibilityModel1,setColumnVisibilityModel1]=useState<any>({
        name:true,
        membershipNo:true,
        mobile:true,
        shipmentNumber:true,
        email:true,
        gender:true,
        currentLocation:true,
        status:true,    
      });
      function getFilteredBeneficiary(data:any){
        let tempData=data;
        if(searchTerm)
        tempData=tempData?.filter((data2:any)=>data2?.name.toLowerCase().includes(searchTerm.trim().toLowerCase()))
        if(currentStatus!==-1)
          {
            tempData=tempData?.filter((data2:any)=>data2?.shipmentDetails?.shipmentStatus==currentStatus)
          }
        return tempData;
      }
    return (
        <>
            <div className="main_title">
                <h1>List of Beneficiary Customers</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Registrations - End User
                </p>
            </div>
            <div className="cards">
                <BeneficiaryFilter 
                selectedRow={selectedRow}  
                gridApi={gridApi}
                setSearchTerm={setSearchTerm}
                columnVisibilityModel={columnVisibilityModel1}
                setColumnVisibilityModel={setColumnVisibilityModel1} 
                setCurrentStatus={setCurrentStatus}
 />
                <BeneficiaryList 
                gridApi={gridApi} 
                setSelectedRow={setSelectedRow}
                columnVisibilityModel={columnVisibilityModel1}
                beneficiary={getFilteredBeneficiary(beneficiary)}/>
            </div>
        </>
    );
};

export default BeneficiaryCustomers;
