//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/traveler";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllTravelerResponse = {
    data: any;
    // traveler: any[];
    count: number;
  };
  
type GetTokenParams = {
    // limit?: number;
    // wpagination?:boolean;
    type?:number;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi:any=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getTraveler:builder.query<
CommonResponseType & {data:any},
    GetTokenParams>({
        // query:({page,limit,query})=>{
        query:({page,type,query})=>{
          let url = END_POINTS.traveler;
        const queryParams = [];
        if (page) {
          queryParams.push('wpagination=true');
        }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        if (type) {
          queryParams.push(`type=${type}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),

getTravelerById:builder.query<CommonResponseType & {data:any},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.traveler}/${id}`,
        method:"GET",
    })
}),

changeTravelerStatus: builder.query<
CommonResponseType & { data: any },
{ id: string }
>({
query: ({ id }) => ({
  url: `${END_POINTS.changeUserStatus}/${id}`,
  method: "GET",
}),
}),

editTravelerById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string|undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.traveler}/${id}`,
        method: "PUT",
        body,
      }),
    }),

deleteTravelerById:builder.mutation<
CommonResponseType & {data:any},
{id:string|undefined}
>({
    query:({id})=>({
        url:`${END_POINTS.traveler}/${id}`,
        method:'DELETE'
    })
}),

addTraveler:builder.mutation<
CommonResponseType & {data:any},
CommonBody
>({
  query:(body)=>({
    url:END_POINTS.traveler,
    method:'POST',
    body
  })
}),

deleteTravelerById2:builder.mutation<
CommonResponseType & {data:any},
{id:string|undefined}
>({
    query:({id})=>({
        url:`${END_POINTS.traveler}/${id}`,
        method:'DELETE'
    })
}),
getAllTravelByUserId:builder.query<
CommonResponseType & {data:any} ,{id:string}
>({
  query:({id})=>({
    url:END_POINTS.traveler+`?userId=${id}`,
    method:'GET',
  })
}),

})
})
export const{
 useLazyGetTravelerQuery,
 useLazyGetTravelerByIdQuery,
 useEditTravelerByIdMutation,
 useAddTravelerMutation,
 useDeleteTravelerByIdMutation,
 useLazyGetAllTravelByUserIdQuery
}=userApi;
