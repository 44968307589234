import React from "react";
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const GoogleRating = () => {

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  return (
    <>
      <div className="cards g_rating">
        <h2><img src="/static/images/google_play_icon.svg" alt="" /> Google Ratings</h2>
        <div className="d_flex">
          <div className="left_s">
            <h3>4.5</h3>
            <div className="star_rating">
              <StarIcon className="active" />
              <StarIcon className="active" />
              <StarIcon className="active" />
              <StarIcon className="active" />
              <StarIcon />
            </div>
            <p>9,693,354</p>
          </div>
          <div className="right_s">
            <p>
              <b>5</b>
              <BorderLinearProgress variant="determinate" value={95} />
            </p>
            <p>
              <b>4</b>
              <BorderLinearProgress variant="determinate" value={25} />
            </p>
            <p>
              <b>3</b>
              <BorderLinearProgress variant="determinate" value={30} />
            </p>
            <p>
              <b>2</b>
              <BorderLinearProgress variant="determinate" value={10} />
            </p>
            <p>
              <b>1</b>
              <BorderLinearProgress variant="determinate" value={15} />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleRating;
