import { Modal, Button, Tooltip } from '@mui/material'
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField';
import useAuth from '../hooks/useAuth';

interface AddCommentModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>
    commentData: any
    setCommentData: any
    editByIdData: any
}

export default function AddComment({ open, onClose, setOpen, commentData, setCommentData, editByIdData }: AddCommentModalProps) {
    const [comment, setComment] = useState({ title: "", message: "" })
    const user = useAuth();
    console.log('user :', user);
    const handleAddComment = () => {
        setCommentData((prev: any) => [...prev, { fullName: user?.fullName, title: comment.title, message: comment.message, image: user?.image, }])
        setComment({ message: "", title: "" })
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    return (
        <Modal
            className="modal addComment_modal"
            id="addCommentModal"
            aria-labelledby="addComment-modal-title"
            aria-describedby="addComment-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Add Comments</h2>
                        <p>Comment given by admins</p>
                    </div>
                    <form className="form" action="">
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Title"
                                name="title"
                                onChange={handleChange}
                                fullWidth
                                hiddenLabel
                                value={comment?.title}
                            />
                        </div>
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Description"
                                fullWidth
                                hiddenLabel
                                name="message"
                                multiline
                                maxRows={5}
                                minRows={5}
                                onChange={handleChange}
                                value={comment?.message}
                            />
                        </div>
                        <div className="footer_btn_flex">
                            <Button className="btn btn_primary" onClick={handleAddComment}>Add Comment</Button>
                        </div>
                    </form>

                    <ul className="fcComment_list v2">
                        {commentData?.map((data: any) => <li>
                            <Tooltip title={data?.fullName}><figure><img src={data?.image || "/static/images/user_attachment.jpg"} alt="User" /></figure></Tooltip>
                            <p>
                                <b>{data?.title || "Richard Anderson"}</b>
                                <span>{data?.message || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown."}</span>
                            </p>
                        </li>)}
                    </ul>
                    <div className="footer_btn_flex">
                        <Button className="btn btn_highlight" onClick={() => setOpen(false)}>Cancel</Button>
                        <Button className="btn btn_primary" onClick={editByIdData}>Save</Button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
