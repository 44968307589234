/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import TableComponent from "../../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import AddComment from "../../../Modals/addComment";
import ShipmentSummaryModal from "../../../Modals/shipmentSummary";
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAuth from "../../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import { NewOrder } from "../../../types/newOrder";
import increaseDays from "../../../utils/increaseDays";
import { ORDER_TRACKING_STATUS } from "../../../constants/shipmentOrder";

const CancelledFlightsList = ({
    columnVisibilityModel,
    newOrders,
    setSelectedRow,
    gridApi,
    getDataList,
  }: {
    columnVisibilityModel: any;
    newOrders: NewOrder[];
    setSelectedRow: any;
    gridApi: any;
    getDataList: any;
  }) => {
    const navigate = useNavigate();

    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const [openModal2, setOpenModal2] = useState(false);
    const handleCloseModal2 = () => {
        setOpenModal2(false);
    };
const[shipmentDetails,setShipmentDetails]=useState<NewOrder|any>();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

        const rows: any = [];

        newOrders?.map((data: any) => {
          rows.push({
            data: data,
            id: data?._id,
            travelers: data?.traveller?.fullName,
            travelerImage: data?.traveller?.personVerification?.profileImage,
            travelerId: data?.traveller._id,
            travelerEmail: data?.traveller?.email || "",
            travelerName:
              data?.traveller?.firstName + " " + data?.traveller?.lastName,
            travelerMobile: data?.traveller?.countryCode + data?.traveller?.phone,
            freightCustomer:
              data?.customer?.firstName + " " + data?.customer?.lastName,
            freightCustomerImage: data?.customer?.personVerification?.profileImage,
            freightCustomerId: data?.customer?._id,
            freightCustomerMobile:
              data?.customer?.countryCode + data?.customer?.phone,
            shipmentNo: data?.shipmentId?.shipmentId
              ? data?.shipmentId?.shipmentId
              : "-",
            currentCity: data?.travelDetail?.departingFrom
              ? data?.travelDetail?.departingFrom
              : "-",
            destinationCity: data?.travelDetail?.destination
              ? data?.travelDetail?.destination
              : "-",
            dateOfOrder: data?.createdAt?.slice(0, 10),
            expectingDeliveryDate: increaseDays(data?.createdAt, 2)?.slice(0, 10),
            dateOfAcceptance: data?.acceptanceDate
              ? data?.acceptanceDate?.slice(0, 10)
              : "-",
            departureDate: data?.travelDetail?.departureDate
              ? data?.travelDetail?.departureDate?.slice(0, 10)
              : "-",
            status: ORDER_TRACKING_STATUS[data?.trackingStatus],
            statusEnum: data?.trackingStatus,
          });
        });
    const user=useAuth();
    const ActionCell=({data}:any)=>{
        const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
        const open1 = Boolean(anchorEl1);
        const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl1(event.currentTarget);
        };
        const handleClose1 = () => {
          setAnchorEl1(null);
        };
    return   <div className="table_actions">
    <Box>
      <IconButton
        aria-controls={open1 ? "basic-menu1" : undefined}
        aria-haspopup="true"
        aria-expanded={open1 ? "true" : undefined}
        onClick={handleClick1}
      >
        <img src="/static/images/more_icon.svg" alt="" />
      </IconButton>
      <Menu
        className="setting_menu"
        id="basic-menu1"
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        MenuListProps={{
          "aria-labelledby": "basic-button1",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "8px",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>Not Yet</MenuItem>
        <MenuItem>Returned</MenuItem>
      </Menu>
    </Box>
    </div>
      }
      const columns: GridColDef<(typeof rows)[number]>[] = [
        {
          flex: 1,
          minWidth: 200,
          field: "traveler",
          headerName: "Traveler",
          editable: false,
          renderCell: (data: any) => {
            return (
              <div className="user_block">
                <figure
                  onClick={() =>
                    user?.role == ADMIN_ROLE_ID ||
                    user?.subadminRoleId?.permissions?.[6]?.isView
                      ? navigate("/profile-detail/" + data?.row?.travelerId)
                      : null
                  }
                >
                  <img
                    src={
                      data?.row?.travelerImage
                        ? data?.row?.travelerImage
                        : "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
                <p>
                  <b>{data?.row?.travelerName}</b> {data?.row?.travelerEmail}
                </p>
              </div>
            );
          },
        },
        {
          flex: 1,
          minWidth: 130,
          field: "travelerMobile",
          headerName: "Contact",
          editable: false,
          sortable: false,
        },
        {
          flex: 1,
          minWidth: 130,
          field: "travelerEmail",
          headerName: "Email",
          editable: false,
          sortable: false,
        },
        {
          flex: 1,
          minWidth: 130,
          field: "delayDepartureDate",
          headerName: "Delay Departure Date",
          editable: false,
          sortable: false,
        },
        {
          flex: 1,
          minWidth: 130,
          field: "dateOfAcceptance",
          headerName: "Date of Acceptance",
          editable: false,
          sortable: false,
        },
        {
          flex: 1,
          minWidth: 120,
          field: "currentCity",
          headerName: "Current city",
          editable: false,
        },
        {
          flex: 1,
          minWidth: 120,
          field: "destinationCity",
          headerName: "Destination City",
          editable: false,
        },
        {
          flex: 1,
          minWidth: 120,
          field: "wayOfTravel",
          headerName: "Way of Travel",
          editable: false,
        },
        {
          flex: 1,
          minWidth: 120,
          field: "availableWeight",
          headerName: "Available Weight",
          editable: false,
        },
        {
          flex: 1,
          minWidth: 120,
          field: "flag",
          headerName: "Flag",
          editable: false,
          renderCell: () => {
            return (
                <IconButton className="text_icon">
                    <img src="/static/images/flag_icon.svg" alt="Icon" />
                </IconButton>
            );
        },
        },
        {
          flex: 1,
          minWidth: 120,
          field: "comment",
          headerName: "Comment",
          editable: false,
          renderCell: () => {
            return (
                <IconButton className="text_icon">
                    <img src="/static/images/comment_icon.svg" alt="Icon" onClick={() => setOpenModal(true)} />
                </IconButton>
            );
        },
        },
        {
          flex: 1,
          minWidth: 120,
          field: "shipments",
          headerName: "Shipments",
          editable: false,
          sortable: false,
          renderCell: (data:any) => {
            return (
              <p
                className="view_link"
                onClick={() => {
                  setShipmentDetails(data?.row?.data);
                  setOpenModal2(true);
                }}
              >
                View detail
              </p>
            );
          },
        },
        {
          flex: 1,
          minWidth: 120,
          field: "departureDate",
          headerName: "Departure Date",
          editable: false,
          renderCell: (data) => {
            return (
              <p className="departure_date">
                {data?.row?.departureDate}
    
                <IconButton>
                  <img
                    src="/static/images/edit2_icon.svg"
                    alt="Icon"
                    onClick={() => setOpenModal(true)}
                  />
                </IconButton>
              </p>
            );
          },
        },
        {
          flex: 1,
          minWidth: 120,
          field: "status",
          headerName: "Status",
          editable: false,
          renderCell: () => {
            return <Button className="btn_text c_success">Confirmed</Button>;
          },
        },
        {
          flex: 1,
          minWidth: 80,
          field: "action",
          headerName: "Action",
          editable: false,
          sortable: false,
          renderCell: ({data}:any) => {
            return (
            <ActionCell data={data}/>
            );
          },
        },
      ];

    let hidden = ["fullName"];

    const accordionContent = (
        <>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    Shipping NO  XXX XXX 000100
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table className="v2" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Shipment customers</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Luggage type</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Total Weight (Kgs)</TableCell>
                                    <TableCell>Offer</TableCell>
                                    <TableCell>status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>
                                        <div className="user_block">
                                            <figure onClick={() => navigate("/profile-detail")}>
                                                <img src="/static/images/user_attachment.jpg" alt="" />
                                            </figure>
                                            <p><b>Emma smith</b> samith@gmail.com</p>
                                        </div>
                                    </TableCell>
                                    <TableCell>+963024587412</TableCell>
                                    <TableCell className="double_value">
                                        <span>Suite case</span>
                                        <span>Box</span>
                                    </TableCell>
                                    <TableCell className="double_value">
                                        <span>2</span>
                                        <span>2</span>
                                    </TableCell>
                                    <TableCell className="double_value">
                                        <span>10KG</span>
                                        <span>10KG</span>
                                    </TableCell>
                                    <TableCell className="double_value">
                                        <span>$90</span>
                                        <span>$90</span>
                                    </TableCell>
                                    <TableCell>
                                        <Button className="btn_text c_danger">Retuned Request</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    Shipping NO  XXX XXX 000100
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table className="v2" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Shipment customers</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Luggage type</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Total Weight (Kgs)</TableCell>
                                    <TableCell>Offer</TableCell>
                                    <TableCell>status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>1</TableCell>
                                    <TableCell>
                                        <div className="user_block">
                                            <figure onClick={() => navigate("/profile-detail")}>
                                                <img src="/static/images/user_attachment.jpg" alt="" />
                                            </figure>
                                            <p><b>Emma smith</b> samith@gmail.com</p>
                                        </div>
                                    </TableCell>
                                    <TableCell>+963024587412</TableCell>
                                    <TableCell className="double_value">
                                        <span>Suite case</span>
                                        <span>Box</span>
                                    </TableCell>
                                    <TableCell className="double_value">
                                        <span>2</span>
                                        <span>2</span>
                                    </TableCell>
                                    <TableCell className="double_value">
                                        <span>10KG</span>
                                        <span>10KG</span>
                                    </TableCell>
                                    <TableCell className="double_value">
                                        <span>$90</span>
                                        <span>$90</span>
                                    </TableCell>
                                    <TableCell>
                                        <Button className="btn_text c_danger">Retuned Request</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
        </>
    );

    return (
        <>
            <div className="sorting_table">
                <TableComponent
                  columns={columns}
                  rows={rows}
                  gridApi={gridApi}
                  setSelectedRow={setSelectedRow}
                  columnVisibilityModel={columnVisibilityModel}
                />
            </div>

            <AddComment open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} />
            <ShipmentSummaryModal open={openModal2} onClose={handleCloseModal2} setOpen={setOpenModal2} accordionContent={accordionContent} />
        </>
    );
};

export default CancelledFlightsList;
