import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Button } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ADMIN_ROLE_ID } from "../../constants/role";
import useAuth from "../../hooks/useAuth";

const UninstalledUsers = () => {
const user=useAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('')

  const rows = [
    {
      id: 1,
      date: "12 Jan 2024",
      gender: "Female",
      mobile: "(555) 123-4567",
      country: "Saudi Arab",
      socialMedia: "Social",
    },
    {
      id: 2,
      date: "12 Jan 2024",
      gender: "Female",
      mobile: "(555) 123-4567",
      country: "Saudi Arab",
      socialMedia: "Social",
    },
    {
      id: 3,
      date: "12 Jan 2024",
      gender: "Female",
      mobile: "(555) 123-4567",
      country: "Saudi Arab",
      socialMedia: "Social",
    },
    {
      id: 4,
      date: "12 Jan 2024",
      gender: "Female",
      mobile: "(555) 123-4567",
      country: "Saudi Arab",
      socialMedia: "Social",
    }
  ];

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 125,
      field: "membershipId",
      headerName: "Membership #",
      editable: false,
      sortable: false,
      renderCell: () => {
        return (
          <p className="c_black">GN12xxxxxxx</p>
        )
      }
    },
    {
      flex: 1,
      minWidth: 200,
      field: "name",
      headerName: "Name",
      editable: false,
      sortable: false,
      renderCell: (data:any) => {
        return (
          <div className="user_block">
            <figure onClick={() => 
              ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)? navigate("/profile-detail/"+data?.row?.userId) : null)

              }><img src="/static/images/user_attachment.jpg" alt="" /></figure>
            <p><b>Emma Smith</b> samith@gmail.com</p>
          </div>
        )
      }
    },
    {
      flex: 1,
      field: "date",
      headerName: "Date",
      editable: false,
    },
    {
      flex: 1,
      field: "gender",
      headerName: "Gender",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
    },
    {
      flex: 1,
      field: "country",
      headerName: "Country",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "orderSubmited",
      headerName: "Order submited",
      editable: false,
      renderCell: () => {
        return (
          <p className="c_primary">Yes</p>
        )
      }
    },
    {
      flex: 1,
      minWidth: 140,
      field: "socialMedia",
      headerName: "Social Media",
      editable: false,
      sortable: false,
      renderCell: () => {
        return (
          <div className="table_actions">
            <Box>
              <IconButton className="round_btn">
                <img src="/static/images/facebook_icon.svg" alt="Icon" />
              </IconButton>
            </Box>
            <Box>
              <IconButton className="round_btn">
                <img src="/static/images/instagram_icon.svg" alt="Icon" />
              </IconButton>
            </Box>
            <Box>
              <IconButton className="round_btn">
                <img src="/static/images/twitter_icon.svg" alt="Icon" />
              </IconButton>
            </Box>
          </div>
        )
      }
    }
  ];

  let hidden = ["fullName"];

  return (
    <>
      <div className="main_title">
        <h1>List of Uninstalled users</h1>
        <p><span onClick={() => navigate("/dashboard")}>Dashboard</span> - Uninstalled users</p>
      </div>
      <div className="cards">
        <div className="table_header">
          <div className="left_s">
            <SearchBar searchTerm={searchTerm} setDebouncedSearchTerm={setSearchTerm} placeholder='Search Users' />
          </div>
          <div className="right_s">
            <Button className="btn btn_highlight">
              <img src="/static/images/broadcast_icon.svg" alt="Icon" /> Broadcast Message
            </Button>
            <Button className="btn btn_highlight">
              <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
            </Button>
          </div>
        </div>
        <div className="sorting_table">
          <TableComponent
            columns={columns?.filter((item) => !hidden.includes(item.field))}
            rows={rows}
          />
        </div>
      </div>
    </>
  );
};

export default UninstalledUsers;
