import React from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import ReportStats from "../../features/report/reportStats";
import ReportFilter from "../../features/report/reportFilter";
import ReportRightCards from "../../features/report/reportRightCards";
import WorldMap from "../../features/report/worldMap";
import WeightsBreakdown from "../../features/report/weightsBreakdown";

const Reports = () => {

    const navigate = useNavigate();

    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const open3 = Boolean(anchorEl3);
    const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    return (
        <>
            <div className="main_title d_flex">
                <div className="left_s">
                    <h1>Report</h1>
                    <p>
                        <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                        YTD Report
                    </p>
                </div>
                <div className="right_s">
                    <Box>
                        <Button
                            className="btn btn_white"
                            aria-controls={open3 ? "basic-menu3" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open3 ? "true" : undefined}
                            onClick={handleClick3}
                        >
                            <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu3"
                            anchorEl={anchorEl3}
                            open={open3}
                            onClose={handleClose3}
                            MenuListProps={{
                                "aria-labelledby": "basic-button3",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem>
                                <h2>Choose Option</h2>
                                <p>Select an option to export</p>
                            </MenuItem>
                            <MenuItem className="export_btns">
                                <Button>PDF</Button>
                                <Button>CSV</Button>
                                <Button>Excel</Button>
                                <Button>Txt</Button>
                                <Button>PPT</Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </div>
            </div>

            <div className="report_page">
                <ReportStats />
                <ReportFilter />
                <div className="gap_m">
                    <div className="left_s">
                        <WorldMap />
                        <WeightsBreakdown />
                    </div>
                    <div className="right_s">
                        <ReportRightCards />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reports;
