import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import { Box, Tabs, Tab } from "@mui/material";
import ProfileTop from "../../features/ProfileDetail/profileTop";
import PersonalInformation from "../../features/ProfileDetail/personalInformation";
import TravelList from "../../features/ProfileDetail/travelList";
import OrderList from "../../features/ProfileDetail/orderList";
import InvitedPersons from "../../features/ProfileDetail/invitedPersons";
import ProfileReviews from "../../features/ProfileDetail/reviews";
import { useLazyGetUsersByIdQuery } from "../../services/user";
import { showError } from "../../constants/toasts";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ProfileDetail = () => {

    const navigate = useNavigate();
    const{id}=useParams();
    const [valueTabs, setValueTabs] = React.useState(0);
    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValueTabs(newValue);
    };
    const[userData,setUserData]=React.useState({});
    const[getUserbyId]=useLazyGetUsersByIdQuery();

    const getData=async(id:string)=>{
        try{
        const response=await getUserbyId({id:id}).unwrap();
        if(response.statusCode==200)
        {
        setUserData(response?.data);
        }
        
        }
        catch(error:any)
        {
        showError(error?.data?.message || "");
        }}

    useEffect(()=>{
        if(id)
            {
           getData(id);     
            }

    
    },[])


    return (
        <>
            <div className="main_title">
                <h1>Profile Detail</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Registrations - Profile Detail
                </p>
            </div>

            <ProfileTop userData={userData} />

            <Box className="custom_tabs2" sx={{ width: "100%" }}>
                <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    aria-label="basic tabs example"
                    className="custom_tabs_links"
                >
                    <Tab label="Personal Information" {...a11yProps(0)} />
                    <Tab label="Travels List" {...a11yProps(1)} />
                    <Tab label="Orders List" {...a11yProps(2)} />
                    <Tab label="Invited Persons" {...a11yProps(3)} />
                    <Tab label="Reviews" {...a11yProps(4)} />
                </Tabs>
            </Box>

            <div className="cards">
                <CustomTabPanel value={valueTabs} index={0}>
                    <PersonalInformation userData={userData}/>
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={1}>
                    <TravelList />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={2}>
                    <OrderList />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={3}>
                    <InvitedPersons userData={userData}/>
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={4}>
                    <ProfileReviews />
                </CustomTabPanel>
            </div>
        </>
    );
};

export default ProfileDetail;
