//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/freightCustomer";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllFreightCustomerResponse = {
    data: any;
    // freightCustomer: any[];
    count: number;
  };
  
type GetTokenParams = {
    // limit?: number;
    // wpagination?:boolean;
    type?:number;
    page?: number;
    query?: string;
  };
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi:any=emptySplitApi.injectEndpoints({
endpoints:(builder)=>({
getFreightCustomer:builder.query<
CommonResponseType & {data:any},
    GetTokenParams>({
        // query:({page,limit,query})=>{
        query:({page,type,query})=>{
          let url = END_POINTS.freightCustomer;
        const queryParams = [];
        if (page) {
          queryParams.push('wpagination=true');
        }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        if (type) {
          queryParams.push(`type=${type}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
          return{
            url:url,
            method:'GET',}
        }
}),

getFreightCustomerById:builder.query<CommonResponseType & {data:any},
{id:string|undefined}>({
    query:({id})=>({
        url:`${END_POINTS.freightCustomer}/${id}`,
        method:"GET",
    })
}),

changeFreightCustomerStatus: builder.query<
CommonResponseType & { data: any },
{ id: string }
>({
query: ({ id }) => ({
  url: `${END_POINTS.changeUserStatus}/${id}`,
  method: "GET",
}),
}),

editFreightCustomerById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string|undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.freightCustomer}/${id}`,
        method: "PUT",
        body,
      }),
    }),

deleteFreightCustomerById:builder.mutation<
CommonResponseType & {data:any},
{id:string}
>({
    query:({id})=>({
        url:`${END_POINTS.freightCustomer}/${id}`,
        method:'DELETE'
    })
}),

addFreightCustomer:builder.mutation<
CommonResponseType & {data:any},
CommonBody
>({
  query:(body)=>({
    url:END_POINTS.freightCustomer,
    method:'POST',
    body
  })
})

})
})
export const{
 useLazyGetFreightCustomerQuery,
 useLazyGetFreightCustomerByIdQuery,
 useEditFreightCustomerByIdMutation,
 useAddFreightCustomerMutation,
 useDeleteFreightCustomerByIdMutation
}=userApi;
