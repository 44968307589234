import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

type InputTypes = {
  placeholder: string;
  clearBackground?: boolean;
  password?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  customIcon?: JSX.Element | null;
  value?: string;
  name?: string;
  id?: string;
  onChange?: (value: any) => void;
  onBlur?: any;
  helperText?: any;
  inputProps?: any;
};

const Input = ({
  value,
  name,
  onBlur,
  id,
  onChange,
  helperText,
  placeholder,
  inputProps,
  clearBackground,
  password = false,
  multiline = false,
  disabled = false,
  rows = 1,
  customIcon = null,
}: InputTypes) => {
  const [showPassword, setShowPassword] = useState(password);
  return (
    <TextField
      name={name}
      value={value}
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      hiddenLabel
      variant="outlined"
      fullWidth
      className="text_field"
      style={{
        marginBottom: 15,
      }}
      multiline={multiline}
      disabled={disabled}
      inputProps={inputProps}
      rows={rows}
      placeholder={placeholder}
      type={!showPassword ? "text" : "password"}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment className="eye_btn" position="end">
            {password ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event: any) => event.preventDefault()}
                edge="end"
              >
                {!showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ) : customIcon ? (
              customIcon
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Input;
