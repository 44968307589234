import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { SIDEBAR_WIDTH } from "../constants";
import "./Layout.scss";
import secureLocalStorage from "react-secure-storage";
import { getFromStorage, STORAGE_KEYS, removeFromStorage } from "../helpers";
import { useAppDispatch } from "../hooks/store";
import useAuth from "../hooks/useAuth";
import { setCredentials } from "../reducers/authSlice";
import { useLazyGetUserQuery, usePostLogoutMutation } from "../services/auth";

interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getUser] = useLazyGetUserQuery();
  const[isLoading,setIsLoading]=useState(false);
  const [logoutMethod] = usePostLogoutMutation();
  const[adminNotification,setAdminNotification]=useState<any>([]);
  const [openLogout, setOpenLogout] = useState<boolean>(false);

  const user = useAuth();
useEffect(()=>{

  if(user){
    secureLocalStorage.setItem("permissions",JSON.stringify(user?.subadminRoleId?.permissions));
    secureLocalStorage.setItem("role",user?.role);
    console.log("Ssssssss",user?.role);
    console.log("sssssssssssss",user?.subadminRoleId?.permissions);
  
  
  } 
},[user])
console.log("user: ", user);
  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      // getUserDetails();
    }
  }, []);

  const handleLogout = async () => {
    try {

      const res = await logoutMethod({}).unwrap();
      if(res.statusCode==200){
      navigate("/");
      removeFromStorage(STORAGE_KEYS.token);
      removeFromStorage(STORAGE_KEYS.tempToken);
      dispatch(
        setCredentials({
          user: null,
          token: null,
        })
      );}
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <Box className="site_header">
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          ml: { lg: `${SIDEBAR_WIDTH}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div className="topbar_left">
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              edge="start"
              onClick={handleSidebarToggle}
              sx={{
                display: { lg: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Button
              className="btn btn_white"
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </Button>
            <Button
              className="btn btn_white"
            // onClick={() => navigate("/")}
            >
              Quick Links
            </Button>
            <Button
              className="btn btn_white"
            // onClick={() => navigate("/")}
            >
              Orders
            </Button>
          </div>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
              gap: "9px",
            }}
          >
            <Box>
              <IconButton className="icon_btn">
                <img src="/static/images/grid_icon.svg" alt="Icon" />
              </IconButton>
            </Box>

            <Box>
              <IconButton className="icon_btn">
                <img src="/static/images/search_icon.svg" alt="Icon" />
              </IconButton>
            </Box>

            <Box>
              <IconButton
                className="icon_btn"
                aria-controls={open1 ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                <img src="/static/images/notification_icon.svg" alt="Icon" />
              </IconButton>
              <Menu
                className="notiDropdown_menu"
                id="basic-menu1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="noti_head">
                  <Typography component="h2">Notifications</Typography>
                  <Button
                    className="btn btn_primary sm"
                    onClick={() => navigate("/recieved-notifications")}
                  >
                    See All
                  </Button>
                </Box>
                <ul className="noti_list">
                  <li>
                    <figure>
                      <img
                        src="/static/images/profile_placeholder.jpg"
                        alt=""
                      />
                    </figure>
                    <div className="noti_info">
                      <Typography component="h3">
                        Emma Smith
                        <Typography component="span">1 Day ago</Typography>
                      </Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img
                        src="/static/images/profile_placeholder.jpg"
                        alt=""
                      />
                    </figure>
                    <div className="noti_info">
                      <Typography component="h3">
                        Emma Smith
                        <Typography component="span">1 Day ago</Typography>
                      </Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img
                        src="/static/images/profile_placeholder.jpg"
                        alt=""
                      />
                    </figure>
                    <div className="noti_info">
                      <Typography component="h3">
                        Emma Smith
                        <Typography component="span">1 Day ago</Typography>
                      </Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur.
                      </Typography>
                    </div>
                  </li>
                </ul>
              </Menu>
            </Box>

            <Box>
              <IconButton className="icon_btn" onClick={() => navigate("/chat")}>
                <img src="/static/images/chat_icon.svg" alt="Icon" />
              </IconButton>
            </Box>

            <Box>
              <IconButton
                className="topbar_user_btn"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img src={user?.image?user?.image:"/static/images/user-placeholder.jpg"} alt="" />
              </IconButton>
              <Menu
                className="userDropdown_menu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/profile")}
                >
                  <IconButton className="drpdwn_icns">
                    <PersonIcon />
                  </IconButton>
                  My Profile
                </MenuItem> */}

                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={handleLogout}
                >
                  <IconButton className="drpdwn_icns">
                    <LogoutIcon />
                  </IconButton>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Topbar;
