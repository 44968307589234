import React, { useState } from "react";
import AuthLayout from "../../layout/authLayout";
import Input from "../../components/Input";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import { useFormik } from "formik";
import { successToast, errorToast } from "../../helpers";
import { useResetPasswordMutation } from "../../services/auth";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import * as Yup from "yup";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const [searchParam,setSearchParams]=useSearchParams();
  const formik = useFormik({
    initialValues: {
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      newpassword: Yup.string()
        .label("password")
        .required("New password is required.")
    .min(6, "Password must have at least 6 characters")
        .required("Password is required."),

      confirmpassword: Yup.string()
        .oneOf([Yup.ref("newpassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        resetToken:searchParam.get('token'),
        password: formik.values.newpassword,
      };
      console.log("RESET",body)

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response:any = await resetPassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          navigate("/");
          successToast("Password reset successfully" || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <div className="authBox">
        <h2>New Password</h2>
        <p>Choose strong password for your account</p>
        <form onSubmit={formik.handleSubmit}>
          <Input 
          placeholder="New Password"
          name="newpassword" 
          value={formik.values.newpassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          password={true}
          helperText={formik.touched.newpassword && formik.errors.newpassword}
          />
          <Input placeholder="Confirm Password" 
             name="confirmpassword" 
             value={formik.values.confirmpassword}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
          password={true}

             helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
             
             />
          <div className="form_control d_flex" />
          <Button value="Update Password"/>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
