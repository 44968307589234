import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PassportResidenceModal from "../../Modals/passportResidenceModal";
import VehicleRegistrationModal from "../../Modals/vehicleRegistration";
import { showError } from '../../constants/toasts';
import { useParams } from 'react-router-dom';
import { useLazyGetAllTravelByUserIdQuery } from '../../services/travelers';
import PassportModal from '../../Modals/passportModal';
import { IconButton } from '@mui/material';
import { travelTypeImages } from '../../constants/wayOfTravelImages';

const TravelList = () => {
    const{id}=useParams();
    const[idData,setIdData]=useState({
        idCard:"",
        residenceCard:""
      });
      console.log(idData);
      const[vehicleData,setVehicleData]=useState<{vehicleReg?:string,
        side?:string,
        front?:string,
        back?:string,
        ticket?:string,
        wayOfTravel?:string
    }>({
        vehicleReg:"",
          side:"",
          front:"",
          back:"",
          ticket:"",
          wayOfTravel:""
      });
    const [openModal1, setOpenModal1] = useState(false);
    const handleCloseModal1 = () => {
        setOpenModal1(false);
    };

    const [openModal2, setOpenModal2] = useState(false);
    const handleCloseModal2 = () => {
        setOpenModal2(false);
    };
    const getResidentTypeName=(residenceType:number)=>{
        if(residenceType==1)
            {
                return "CITIZEN"
            }
            else if(residenceType==2)
                {
                    return "RESIDENT";
                }
                else if(residenceType==3)
                    {
                        return "VISITOR";
                    }
                    else
                    {
                        return "";
                    }
    }
    const getTravelTypeName=(travelType:number)=>{
        if(travelType==1)
            {
                return "Private Vehicle"
            }
            else if(travelType==2)
                {
                    return "Airlines"
                }
                else if(travelType==3)
                    {
                        return "Voyage"
                    }
                    else if(travelType==4)
                        {
                            return "Railway"
                        }
                        else if(travelType==5)
                            {
                                return "Public Road"
                            }
                            else 
                               {
                                 return ""
                                }
    }
const[getAllTravelers]=useLazyGetAllTravelByUserIdQuery();
const[travelData,setTravelData]=useState<any>([]);
const [travelType,setTravelType]=useState<number[][]>([])
console.log('travelType :', travelType);
const getTravelerData=async()=>{
    try{
        const response=await getAllTravelers({id:id}).unwrap();
        if(response.statusCode==200)
        {
            setTravelData(response?.data?.data);
            if(response?.data?.data)
            for(let key of response?.data?.data)
            {
                let temp=[];
                if(key?.travelDetail)
                {for(let key2 of key?.travelDetail)
                {
                    temp.push(key2?.travelType)
                }
                setTravelType((prev)=>[...prev,temp])}
            }
        }
        
        }
        catch(error:any)
        {
        showError(error?.data?.message || "");
        }
}
useEffect(()=>{
if(id)
getTravelerData();

},[])
    const [expanded, setExpanded] = React.useState<false|number>(false);

    const handleChange =
        (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    return (
        <>
            <div className="custom_accordion">
                
                {
                  Array.isArray(travelData) &&  travelData?.map((data:any,index:number)=>{

                return <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        TR  {data?.travelId?data?.travelId:"-"}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="profileInfo_list">
                        <ul className="text_info gap_p">
                    <li className="w_50">
                        <strong>TR</strong>
                        <span>{data?.travelId?data?.travelId:"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Name</strong>
                        <span>{data?.userDetails?.[0]?.fullName?data?.userDetails?.[0]?.fullName:"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>E-mail</strong>
                        <span>{data?.userDetails?.[0]?.email?data?.userDetails?.[0]?.email:"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Residency Type </strong>
                        <span>{data?.personVerification?.residenceType?getResidentTypeName(data?.personVerification?.residenceType):"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Gender</strong>
                        <span>{data?.userDetails?.[0]?.gender?(data?.userDetails?.[0]?.gender=="1"?"Male":"Female"):"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Current City</strong>
                        <span>{(data?.currentAddress?.city?data?.currentAddress?.city:"-")+", "+(data?.currentAddress?.district?data?.currentAddress?.district:"-")}</span>
                    </li>
                    <li className="w_50">
                        <strong>Passport /ID</strong>
                        <IconButton className="text_icon" onClick={() => {
                setIdData({
                  idCard:data?.personVerification?.idCard,
                  residenceCard:data?.personVerification?.residenceCard
                })
                setOpenModal1(true)
                }}>
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>
                    </li>
                    <li className="w_50">
                        <strong>Current Address</strong>
                        <span>{data?.currentAddress?.address?data?.currentAddress?.address:"-"}</span>
                    </li>
                    {     travelType?.[index]?.some(data=>data==1) &&   <li className="w_50">
                        <strong>Vehicle Reg</strong>
                        <IconButton className="text_icon" onClick={()=>{
                
                // setVehicleData({...userData?.lastShipment?.vehicleDetail,
                //   ticket:userData?.lastShipment?.ticket,
                //   wayOfTravel:getTravelTypeName(userData?.lastShipment?.travelType)
                // });
                setVehicleData({...data?.vehicleDetail,ticket:null});
                  if(data?.travelDetail?.length)
                  setOpenModal2(true)
                  
                
                }}>
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>  </li>}
                   {travelType?.[index]?.some(data=>data>1) &&  <li className="w_50">
                        <strong>Travel Ticket</strong>
                        <IconButton className="text_icon" onClick={()=>{
          
                setVehicleData({
                    ticket:data?.ticket,
                    wayOfTravel:getTravelTypeName(data?.travelType)
                  });
                  if(data?.travelDetail?.length)
                  setOpenModal2(true)
                  
                
                }}>
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>  </li>}
                    {/* <li className="w_50">
                        <strong>Ticket/ Vehicle Reg</strong>
                        <IconButton className="text_icon" onClick={()=>{
                
                setVehicleData(data?.travelType==1?{...data?.vehicleDetail}:{
                  ticket:data?.ticket,
                  wayOfTravel:getTravelTypeName(data?.travelType)
                });
                
                if((data?.ticket.length||data?.vehicleDetail?.front) && data?.travelType)
                setOpenModal2(true)
            
            
            }}
                
                
                >
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>  </li> */}
                    {/* <li className="w_50">
                        <strong>Used weights</strong>
                        <span>16 Kg</span>
                    </li> */}

                    <li className="w_50">
                        <strong>Departure City/Date</strong>
                        <span>{(data?.travelDetail?.[0]?.departingFrom?data?.travelDetail?.[0]?.departingFrom:"-") + " /" +(data?.travelDetail?.[0]?.departureDate? data?.travelDetail?.[0]?.departureDate?.slice(0,10):"-")}</span>
                    </li>
{/*                     
                    <li className="w_50">
                        <strong>Way of Travel</strong>
                        <span>{data?.travelType?getTravelTypeName(data?.travelType):"-"}</span>
                    </li> */}
                  
                  
                {   data?.destinationAddress?.map((data2:any,index:number)=>{

              return     <>
                        <li className="w_50">
                            <strong>Arrival City ({index+1})/Date</strong>
                            <span>{(data2?.city?data2?.city:"-")+"/"+(data?.travelDetail?.[index]?.arrivalDate?data?.travelDetail?.[index]?.arrivalDate?.slice(0,10):"-")}</span>
                        </li>
                        <li className="w_50">
                            <strong>Mobile ({index+1})</strong>
                            <span>{data2?.phone?(data2?.countryCode+data2?.phone):"-"}</span>
                        </li>
                        <li className="w_50">
                            <strong>Destination Address ({index+1})</strong>
                            <span>{data?.travelDetail?.[index]?.destination?data?.travelDetail?.[index]?.destination:"-"}</span>
                        </li>
                        <li className="w_50">
                        <strong>Way of Travel  ({index+1})</strong>
                        <span>{data?.travelDetail?.[index]?.travelType?(<span>{getTravelTypeName(data?.travelDetail?.[index]?.travelType)}<figure><img className="black-image-profile" src={travelTypeImages[data?.travelDetail?.[index]?.travelType]}/></figure></span>):"-"}</span>
                    </li>
                        <li className="w_50">
                            <strong>Available weights ({index+1})</strong>
                            <span>{data?.travelDetail?.[index]?.availableWeight?`${data?.travelDetail?.[index]?.availableWeight} Kg`:"-"}</span>
                        </li>
                   </>




                }) 
                  }
                  
                    <li className="w_50">
                        <strong>Arrival Date</strong>
                        <span>{data?.travelDetail?.[data?.travelDetail?.length-1]?.arrivalDate?data?.travelDetail?.[data?.travelDetail?.length-1]?.arrivalDate?.slice(0,10):"-"}</span>
                    </li>
                </ul>
                        </div>
                    </AccordionDetails>
                </Accordion>

                    })
}
{
    !travelData?.length ?<h1 style={{textAlign:"center",padding:"80px"}}>No Travels</h1>:null
}
                {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        TR  XXX XXX 000002
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="profileInfo_list">
                            <ul className="text_info gap_p">
                                <li className="w_50">
                                    <strong>TR</strong>
                                    <span>XXX XXX 000001</span>
                                </li>
                                <li className="w_50">
                                    <strong>Name</strong>
                                    <span>Max Smith</span>
                                </li>
                                <li className="w_50">
                                    <strong>E-mail</strong>
                                    <span>jhone@gmail.com</span>
                                </li>
                                <li className="w_50">
                                    <strong>Residency Type </strong>
                                    <span>Native</span>
                                </li>
                                <li className="w_50">
                                    <strong>Gender</strong>
                                    <span>Male</span>
                                </li>
                                <li className="w_50">
                                    <strong>Current City</strong>
                                    <span>New York</span>
                                </li>
                                <li className="w_50">
                                    <strong>Passport /ID</strong>
                                    <span className="cursor_p" onClick={() => setOpenModal1(true)}>C03008596 <img src="/static/images/external_icon.svg" alt="Icon" /></span>
                                </li>
                                <li className="w_50">
                                    <strong>Current Address</strong>
                                    <span>456 Park Avenue, NY 10022</span>
                                </li>
                                <li className="w_50">
                                    <strong>Ticket/ Vehicle Reg</strong>
                                    <span className="cursor_p" onClick={() => setOpenModal2(true)}>112312312 <img src="/static/images/external_icon.svg" alt="Icon" /></span>
                                </li>
                                <li className="w_50">
                                    <strong>Used weights</strong>
                                    <span>16 Kg</span>
                                </li>
                                <li className="w_50">
                                    <strong>Departure City/Date</strong>
                                    <span>New York (12 Jan 2023)</span>
                                </li>
                                <li className="w_50">
                                    <strong>Available weights</strong>
                                    <span>52 Kg</span>
                                </li>
                                <li className="w_50">
                                    <strong>Way of Travel</strong>
                                    <span>Air</span>
                                </li>
                                <li className="w_50">
                                    <strong>Arrival City (1)/Date</strong>
                                    <span>New York (12 Jan 2023)</span>
                                </li>
                                <li className="w_50">
                                    <strong>Mobile (1)</strong>
                                    <span>+123456789</span>
                                </li>
                                <li className="w_50">
                                    <strong>Destination Address (1)</strong>
                                    <span>Kohenoor city faisalabad</span>
                                </li>
                                <li className="w_50">
                                    <strong>Mobile (2)</strong>
                                    <span>+123456789</span>
                                </li>
                                <li className="w_50">
                                    <strong>Arrival City (2)/Date</strong>
                                    <span>New York (12 Jan 2023)</span>
                                </li>
                                <li className="w_50">
                                    <strong>Mobile (3)</strong>
                                    <span>+123456789</span>
                                </li>
                                <li className="w_50">
                                    <strong>Destination Address (2)</strong>
                                    <span>Kohenoor city faisalabad</span>
                                </li>
                                <li className="w_50">
                                    <strong>Arrival Date</strong>
                                    <span>13/08/2023</span>
                                </li>
                                <li className="w_50">
                                    <strong>No of Travels </strong>
                                    <span>250</span>
                                </li>
                            </ul>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        TR  XXX XXX 000003
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="profileInfo_list">
                            <ul className="text_info gap_p">
                                <li className="w_50">
                                    <strong>TR</strong>
                                    <span>XXX XXX 000001</span>
                                </li>
                                <li className="w_50">
                                    <strong>Name</strong>
                                    <span>Max Smith</span>
                                </li>
                                <li className="w_50">
                                    <strong>E-mail</strong>
                                    <span>jhone@gmail.com</span>
                                </li>
                                <li className="w_50">
                                    <strong>Residency Type </strong>
                                    <span>Native</span>
                                </li>
                                <li className="w_50">
                                    <strong>Gender</strong>
                                    <span>Male</span>
                                </li>
                                <li className="w_50">
                                    <strong>Current City</strong>
                                    <span>New York</span>
                                </li>
                                <li className="w_50">
                                    <strong>Passport /ID</strong>
                                    <span className="cursor_p" onClick={() => setOpenModal1(true)}>C03008596 <img src="/static/images/external_icon.svg" alt="Icon" /></span>
                                </li>
                                <li className="w_50">
                                    <strong>Current Address</strong>
                                    <span>456 Park Avenue, NY 10022</span>
                                </li>
                                <li className="w_50">
                                    <strong>Ticket/ Vehicle Reg</strong>
                                    <span className="cursor_p" onClick={() => setOpenModal2(true)}>112312312 <img src="/static/images/external_icon.svg" alt="Icon" /></span>
                                </li>
                                <li className="w_50">
                                    <strong>Used weights</strong>
                                    <span>16 Kg</span>
                                </li>
                                <li className="w_50">
                                    <strong>Departure City/Date</strong>
                                    <span>New York (12 Jan 2023)</span>
                                </li>
                                <li className="w_50">
                                    <strong>Available weights</strong>
                                    <span>52 Kg</span>
                                </li>
                                <li className="w_50">
                                    <strong>Way of Travel</strong>
                                    <span>Air</span>
                                </li>
                                <li className="w_50">
                                    <strong>Arrival City (1)/Date</strong>
                                    <span>New York (12 Jan 2023)</span>
                                </li>
                                <li className="w_50">
                                    <strong>Mobile (1)</strong>
                                    <span>+123456789</span>
                                </li>
                                <li className="w_50">
                                    <strong>Destination Address (1)</strong>
                                    <span>Kohenoor city faisalabad</span>
                                </li>
                                <li className="w_50">
                                    <strong>Mobile (2)</strong>
                                    <span>+123456789</span>
                                </li>
                                <li className="w_50">
                                    <strong>Arrival City (2)/Date</strong>
                                    <span>New York (12 Jan 2023)</span>
                                </li>
                                <li className="w_50">
                                    <strong>Mobile (3)</strong>
                                    <span>+123456789</span>
                                </li>
                                <li className="w_50">
                                    <strong>Destination Address (2)</strong>
                                    <span>Kohenoor city faisalabad</span>
                                </li>
                                <li className="w_50">
                                    <strong>Arrival Date</strong>
                                    <span>13/08/2023</span>
                                </li>
                                <li className="w_50">
                                    <strong>No of Travels </strong>
                                    <span>250</span>
                                </li>
                            </ul>
                        </div>
                    </AccordionDetails>
                </Accordion> */}
            </div>

            <PassportModal open={openModal1} onClose={handleCloseModal1} setOpen={setOpenModal1} idData={idData} />

<VehicleRegistrationModal  open={openModal2} onClose={handleCloseModal2} setOpen={setOpenModal2} vehicleData={vehicleData} />

        </>
    );
};

export default TravelList;
