import { END_POINTS } from "../helpers/constants/urls";
// import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: any },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.dashboard}`,
        method: "GET",
      }),
    }),
})});
export const {
    useLazyGetDashboardQuery
}=dashboardApi;