import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';

const InvitedPersons = ({userData}:any) => {

    return (
        <>
            <div className="invitedPersons_info">
                <ul className="text_list">
                    <li>
                        <img src="/static/images/tick_square_icon.svg" alt="Icon" />
                        <span>Number of invited respondents : <b>{userData?.user?.inviteLinkCount}</b></span>
                    </li>
                    <li>
                        <img src="/static/images/timer_icon.svg" alt="Icon" />
                        <span>Number of total orders : <b>{userData?.orders}</b></span>
                    </li>
                </ul>

                {/* <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table className="v2" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Invitation id</TableCell>
                                <TableCell>Email Address</TableCell>
                                <TableCell>Contact  #</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>INV1234455</TableCell>
                                <TableCell>jhondoe2@gmail.com</TableCell>
                                <TableCell>+923027851246</TableCell>
                                <TableCell>12 Jan, 2023</TableCell>
                                <TableCell>Pending</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>2</TableCell>
                                <TableCell>INV1234455</TableCell>
                                <TableCell>jhondoe2@gmail.com</TableCell>
                                <TableCell>+923027851246</TableCell>
                                <TableCell>12 Jan, 2023</TableCell>
                                <TableCell>Completed</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>3</TableCell>
                                <TableCell>INV1234455</TableCell>
                                <TableCell>jhondoe2@gmail.com</TableCell>
                                <TableCell>+923027851246</TableCell>
                                <TableCell>12 Jan, 2023</TableCell>
                                <TableCell>Pending</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>4</TableCell>
                                <TableCell>INV1234455</TableCell>
                                <TableCell>jhondoe2@gmail.com</TableCell>
                                <TableCell>+923027851246</TableCell>
                                <TableCell>12 Jan, 2023</TableCell>
                                <TableCell>Completed</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>5</TableCell>
                                <TableCell>INV1234455</TableCell>
                                <TableCell>jhondoe2@gmail.com</TableCell>
                                <TableCell>+923027851246</TableCell>
                                <TableCell>12 Jan, 2023</TableCell>
                                <TableCell>Pending</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer> */}
            </div>
        </>
    );
};

export default InvitedPersons;
