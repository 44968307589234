/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import {
    Button,
    Box,
    Menu,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { showError } from "../../constants/toasts";
import { exportToExcel } from "../../utils/exportToExcel";
import { exportToPdf } from "../../utils/exportToPdf";
interface props{
    selectedRow:any 
    gridApi:any
    setSearchTerm:any
    columnVisibilityModel:any
    setColumnVisibilityModel:any
    setCurrentStatus:any
}
const BeneficiaryFilter = ({selectedRow,gridApi,setSearchTerm,columnVisibilityModel,setColumnVisibilityModel,setCurrentStatus}:props) => {
    const[searchValue,setSearchValue]=useState("");

    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const open2 = Boolean(anchorEl2);
    const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    
    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const open3 = Boolean(anchorEl3);
    const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    return (
        <>
            <div className="table_header">
            <div className="left_s">
         <form onSubmit={(e)=>{
          e.preventDefault();
          setSearchTerm(searchValue)}}>
            <Box className="search_box">
              <SearchBar
                searchTerm={searchValue}
                setDebouncedSearchTerm={setSearchValue}
                placeholder="Search beneficiary"
                 value={searchValue}
                 onCross={() => {
                  setSearchTerm("");
                  setSearchValue("")}}
                 onChange={(e:any)=>setSearchValue(e.target.value)}
              />
              <Button className="btn btn_secondary" type="submit">Search</Button>
            </Box>
         </form>
        </div>
                <div className="right_s">
                    <Box>
                        <Button
                            className="btn btn_highlight"
                            aria-controls={open1 ? "basic-menu1" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? "true" : undefined}
                            onClick={handleClick1}
                        >
                            <img src="/static/images/eye_icon.svg" alt="Icon" /> Display
                            Fields
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu1"
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose1}
                            MenuListProps={{
                                "aria-labelledby": "basic-button1",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "left", vertical: "top" }}
                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        >
                            <MenuItem>
                                <h2>Display & Sort Fields</h2>
                                <p>Displaying 10 fields in a row</p>
                            </MenuItem>
                            <MenuItem className="checkbox_group">
                                <FormGroup className="gap_m">
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.name} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,name:!columnVisibilityModel.name}
                                        }
                                        )}/>}
                                        label="Name"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.email} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,email:!columnVisibilityModel.email}
                                        }
                                        )}/>}
                                        label="Email Address"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.membershipNo} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,membershipNo:!columnVisibilityModel.membershipNo}
                                        }
                                        )}/>}
                                        label="Membership #"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.gender} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,gender:!columnVisibilityModel.gender}
                                        }
                                        )}/>}
                                        label="Gender"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.mobile} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,mobile:!columnVisibilityModel.mobile}
                                        }
                                        )}/>}
                                        label="Mobile"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.currentLocation} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,currentLocation:!columnVisibilityModel.currentLocation}
                                        }
                                        )}/>}
                                        label="Current Location"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.shipmentNumber} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,shipmentNumber:!columnVisibilityModel.shipmentNumber}
                                        }
                                        )}/>}
                                        label="Shipment Number"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox  checked={columnVisibilityModel.status} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>{
                                          return {...prevData,status:!columnVisibilityModel.status}
                                        }
                                        )}/>}
                                        label="Status"
                                    />
                                </FormGroup>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <Button
                            className="btn btn_highlight"
                            aria-controls={open2 ? "basic-menu2" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? "true" : undefined}
                            onClick={handleClick2}>
                            <img src="/static/images/filter_icon.svg" alt="Icon" /> Filter
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu2"
                            anchorEl={anchorEl2}
                            open={open2}
                            onClose={handleClose2}
                            MenuListProps={{
                                "aria-labelledby": "basic-button2",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "left", vertical: "top" }}
                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        >
                            <MenuItem className="export_btns v2">
                                <Button onClick={()=>{
                                    setAnchorEl2(null);
                                    setCurrentStatus(-1)}}>All Status</Button>
                                <Button onClick={()=>{
                                    setAnchorEl2(null);
                                    setCurrentStatus(1)}}>Pending</Button>
                                <Button onClick={()=>{
                                    setAnchorEl2(null);
                                    setCurrentStatus(2)}}>Accepted</Button>
                                <Button onClick={()=>{
                                    setAnchorEl2(null);
                                    setCurrentStatus(3)}}>Ongoing</Button>
                                <Button onClick={()=>{
                                    setAnchorEl2(null);
                                    setCurrentStatus(4)}}>Completed</Button>
                                <Button onClick={()=>{
                                    setAnchorEl2(null);
                                    setCurrentStatus(5)}}>Cancelled</Button>
                                {/* <Button>All Status</Button> */}
                                {/* <Button>delay</Button>
                                <Button>Completed</Button>
                                <Button>On the way</Button>
                                <Button>Cancelled</Button> */}
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <Button
                            className="btn btn_highlight"
                            aria-controls={open3 ? "basic-menu3" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open3 ? "true" : undefined}
                            onClick={handleClick3}
                        >
                            <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu3"
                            anchorEl={anchorEl3}
                            open={open3}
                            onClose={handleClose3}
                            MenuListProps={{
                                "aria-labelledby": "basic-button3",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem>
                                <h2>Choose Option</h2>
                                <p>Select an option to export</p>
                            </MenuItem>
                            <MenuItem className="export_btns">
                <Button onClick={()=>{selectedRow?.row?.length ? exportToPdf(selectedRow?.row,selectedRow?.column?.slice(0,selectedRow?.column?.length-2)):showError("Please select atleast one row")}}>PDF</Button>
                <Button onClick={()=>{selectedRow?.row?.length ? gridApi.current.exportDataAsCsv():showError("Please select atleast one row")}}>CSV</Button>
                <Button onClick={()=>{selectedRow?.row?.length ? exportToExcel(selectedRow?.row,selectedRow?.column):showError("Please select atleast one row")}}>Excel</Button>
                {/* <Button>Txt</Button> */}
              </MenuItem>
                        </Menu>
                    </Box>
                </div>
            </div>
        </>
    );
};

export default BeneficiaryFilter;