import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Box, Button, Menu, MenuItem, Tabs, Tab } from "@mui/material";
import ReportIssueList from "../../features/reportAnIssue/reportIssueList";
import CancelRefundList from "../../features/reportAnIssue/cancelRefund";
import RemoveItemList from "../../features/reportAnIssue/removeItem";
import CancelledRequestList from "../../features/reportAnIssue/cancelledRequest";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ReportAnIssue = () => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    const [valueTabs, setValueTabs] = React.useState(0);
    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValueTabs(newValue);
    };

    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const open3 = Boolean(anchorEl3);
    const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    return (
        <>
            <div className="main_title">
                <h1>Report an issue</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Report an issue
                </p>
            </div>

            <div className="table_header">
                <div className="left_s">
                    <Box className="custom_tabs1" sx={{ width: "100%" }}>
                        <Tabs
                            value={valueTabs}
                            onChange={handleChangeTabs}
                            aria-label="basic tabs example"
                            className="custom_tabs_links"
                        >
                            <Tab label="Report an issues (5)" {...a11yProps(0)} />
                            <Tab label="Cancel & Refund (10)" {...a11yProps(1)} />
                            <Tab label="Remove Items (5)" {...a11yProps(2)} />
                            <Tab label="Cancelled New Request (5)" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                </div>
                <div className="right_s">
                    <Box className="search_box c_white">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setSearchTerm}
                            placeholder="Search..."
                        />
                    </Box>
                    <Box>
                        <Button
                            className="btn btn_secondary"
                            aria-controls={open3 ? "basic-menu3" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open3 ? "true" : undefined}
                            onClick={handleClick3}
                        >
                            <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu3"
                            anchorEl={anchorEl3}
                            open={open3}
                            onClose={handleClose3}
                            MenuListProps={{
                                "aria-labelledby": "basic-button3",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem>
                                <h2>Choose Option</h2>
                                <p>Select an option to export</p>
                            </MenuItem>
                            <MenuItem className="export_btns">
                                <Button>PDF</Button>
                                <Button>CSV</Button>
                                <Button>Excel</Button>
                                <Button>Txt</Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </div>
            </div>

            <div className="cards">
                <CustomTabPanel value={valueTabs} index={0}>
                    <ReportIssueList />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={1}>
                    <CancelRefundList />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={2}>
                    <RemoveItemList />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={3}>
                    <CancelledRequestList />
                </CustomTabPanel>
            </div>
        </>
    );
};

export default ReportAnIssue;

