import React from "react";
import { Button } from "@mui/material";

const SelectDuration = () => {

  return (
    <>
      <div className="cards s_duration">
        <div className="cards_header">
          <div className="left_s">
            <h2><img src="/static/images/calender_icon.svg" alt="Icon" /> Select Duration</h2>
          </div>
          <div className="right_s">
            <p>
              <span>Month</span>
              <span>Week</span>
              <span>Today</span>
            </p>
            <Button className="btn btn_highlight"><img src="/static/images/upload_icon.svg" alt="Icon" /> Export</Button>
          </div>
        </div>
        <ul className="duration_list gap_p">
          <li>
            <figure><img src="/static/images/duration_icon5.svg" alt="" /></figure>
            <p>
              <b>2520</b>
              <span>Travel through airline</span>
            </p>
          </li>
          <li>
            <figure><img src="/static/images/duration_icon4.svg" alt="" /></figure>
            <p>
              <b>3652</b>
              <span>Travel through train</span>
            </p>
          </li>
          <li>
            <figure><img src="/static/images/duration_icon3.svg" alt="" /></figure>
            <p>
              <b>985</b>
              <span>Travel through ship</span>
            </p>
          </li>
          <li>
            <figure><img src="/static/images/duration_icon2.svg" alt="" /></figure>
            <p>
              <b>320</b>
              <span>Travel through car</span>
            </p>
          </li>
          <li>
            <figure><img src="/static/images/duration_icon1.svg" alt="" /></figure>
            <p>
              <b>985</b>
              <span>Travel through bus</span>
            </p>
          </li>
          <li>
            <figure><img src="/static/images/duration_icon1.svg" alt="" /></figure>
            <p>
              <b>F30% - M70%</b>
              <span>Travelers Gender</span>
            </p>
          </li>
          <li>
            <figure><img src="/static/images/duration_icon1.svg" alt="" /></figure>
            <p>
              <b>F30% - M70%</b>
              <span>Shipping Customer</span>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SelectDuration;
