import { IconButton, Modal } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Delete, CameraAltTwoTone } from '@mui/icons-material';
import { showError, showToast } from '../constants/toasts';
import { errorToast } from '../helpers';
import { UploadMedia } from '../utils/uploadMedia';

interface VehicleRegistrationModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    ticket:string[];
    wayOfTravel:string|null;
    setTicket:any;
}

export default function EditTicketModal({ open, onClose, setOpen ,ticket,wayOfTravel,setTicket }: VehicleRegistrationModalProps) {
    const [tempTicket,setTempTicket]=useState<string[]>([]);
    useEffect(()=>{
setTempTicket([...ticket])
    },[ticket])
    const[isLoading,setIsLoading]=useState(false);
    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const files = event.target.files;
        const file = files?.length ? files[0] : null;
    
        try {
          if (!file) {
            return;
          }
          const allowedExtensions = ["png","jpg","jpeg"];
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          console.log("sssssssssssssssssssss",fileExtension);
          if (!allowedExtensions.includes(fileExtension||"")) {
             setIsLoading(false);
            showError("Invalid file format: only png, jpg images are allowed");
            return;
          }
          setIsLoading(true);
          const res = await UploadMedia(file,fileExtension);
          
          if (res?.statusCode === 200) {
            setTempTicket(prev=>[...prev,res?.data]);
          } else {
            errorToast(res?.message);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          errorToast("Error uploading image. Please try again.");
        }
        setIsLoading(false);
      };



    return (
      <Modal
      className="modal vehicleRegistration_modal"
      id="vehicleRegistrationModal"
      aria-labelledby="vehicleRegistration-modal-title"
      aria-describedby="vehicleRegistration-modal-description"
      open={open}
      onClose={onClose}
  >
      <div className="modal-dialog">
          <div className="modal-body">
              <div className="btn-close" onClick={() => setOpen(false)}>
                  <CloseIcon />
              </div>
              <div className="modal_title">
                  <h2>{"Travel using ticket"}</h2>
                 <p>Add all tickets
                      </p>
              </div>
            
            {/* <figure className="registration_img">
                  <img src={vehicleData?.vehicleReg?vehicleData?.vehicleReg:} alt="Registration" />
              </figure> */}
              <figure className="registration_img"  style={{position:"relative"}}>
                 
                    <input
                      accept="image/*" // You can set the accepted file types
                      style={{ display: 'none' }}
                      id="file-upload4"
                      type="file"
                      onChange={(e:any)=>{
                          handleImageUpload(e);
                          e.target.value=null;
                      }}
                    />
                    <label htmlFor="file-upload4">
                      <IconButton color="default" component="span" 
                      sx={{position:"absolute",bottom:-15,left:"45%",border:"1px solid black"}}
                      style={{backgroundColor:"aliceblue"}}
                      >
                        <CameraAltTwoTone/>
                      </IconButton>
                    </label>
                  <img style={{width:"505px",height:"236px"}}   src={"/static/images/ticket-placeholder.png"} alt="Front" ></img>

              </figure>
              {<div className="vehicle_img gap_m" style={{marginTop:"40px"}}>
            

            {tempTicket?.length ? tempTicket?.map((data:string,index:number)=>{
            return <figure  style={{position:"relative",border:"1px solid black",width:"155px",height:"155px",borderRadius:"8px"}}>
             <IconButton color="error" sx={{position:"absolute",top:5,right:5}} onClick={()=>{
               setTempTicket((prev:any)=>prev.filter((data:any,index2:number)=>index2!==index))
             }}><Delete/></IconButton>
             <img style={{width:"100%",height:"100%"}}  src={data?data:"/static/images/ticket-placeholder.png"} alt="Front" ></img>
         </figure>
            }) 
         :<h3>No Tickets</h3>}
         </div>}
              <div className="footer_btn_flex">
                  <Button className="btn btn_secondary" onClick={()=>{
                    setTicket(tempTicket)
                    setOpen(false)
                    showToast("Travel Ticket updated successfully")
                  }}>Submit</Button>
                  <Button className="btn btn_highlight"
                  onClick={()=>{
                    setOpen(false)
                  }}>Cancel</Button>
              </div>
          </div>
      </div>
  </Modal>
    )
}
