/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import {
    Button,
    Box,
    Menu,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { showError } from "../../../constants/toasts";
import { exportToExcel } from "../../../utils/exportToExcel";
import { exportToPdf } from "../../../utils/exportToPdf";

const DeliverTravelersFilter = ({setColumnVisibilityModel,columnVisibilityModel,setSearchTerm,searchTerm,gridApi,selectedRow,setSelectedStatus}:any) => {
    

    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const open2 = Boolean(anchorEl2);
    const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const open3 = Boolean(anchorEl3);
    const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };
    const [searchValue, setSearchValue] = useState("");

    return (
        <>
            <div className="table_header">
                <div className="left_s">
                <Box className="search_box c_white">
                     <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setSearchTerm}
                  placeholder="Search shipment track"
                 value={searchTerm}
                 onCross={() => {
                 setSearchTerm("");
                 setSearchValue("")
                }}
                 onChange={(e:any)=>{
                    setSearchTerm(e.target.value)
                    setSearchValue(e.target.value)}

                 }
              />
                </Box>
                </div>
                <div className="right_s">
                <Box>
                    <Button
                        className="btn btn_white"
                        aria-controls={open1 ? "basic-menu1" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick1}
                    >
                        <img src="/static/images/eye_icon.svg" alt="Icon" /> Display
                        Fields
                    </Button>
                    <Menu
                        className="filter_menu"
                        id="basic-menu1"
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        MenuListProps={{
                            "aria-labelledby": "basic-button1",
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                borderRadius: "10px",
                                mt: 1,
                            },
                        }}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    >
                        <MenuItem>
                            <h2>Display & Sort Fields</h2>
                            <p>Displaying 10 fields in a row</p>
                        </MenuItem>
                        <MenuItem className="checkbox_group">
                            <FormGroup className="gap_m">
                                
                                <FormControlLabel
                                    control={<Checkbox
                                       checked={columnVisibilityModel?.freightCustomer} 
                                       onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,freightCustomer:!prevData.freightCustomer}))}
                                       />}
                                    label="Freight Customer"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                       checked={columnVisibilityModel?.travelers} 
                                       onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,travelers:!prevData.travelers}))}
                                       />}
                                    label="Travelers"
                                />
                                {/* <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.contact} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,contact:!prevData.contact}))}
                                        />}
                                    label="Contact"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.email} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,email:!prevData.email}))}
                                        />}
                                    label="Email"
                                /> */}
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.currentCity} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,currentCity:!prevData.currentCity}))}
                                        />}
                                    label="Current City"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.destinationCity} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,destinationCity:!prevData.destinationCity}))}
                                        />}
                                    label="Destination City"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.shipmentNo} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,shipmentNo:!prevData.shipmentNo}))}
                                        />}
                                    label="Shipment No"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.offers} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,offers:!prevData.offers}))}
                                        />}
                                    label="Offers"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.viewDetail} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,viewDetail:!prevData.viewDetail}))}
                                        />}
                                    label="View Detail"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.status} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,status:!prevData.status}))}
                                        />}
                                    label="Status"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.dateOfOrder} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,dateOfOrder:!prevData.dateOfOrder}))}
                                        />}
                                    label="Date of Order"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.departureDate} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,departureDate:!prevData.departureDate}))}
                                        />}
                                    label="Departure Date"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.delayDepartureDate} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,delayDepartureDate:!prevData.delayDepartureDate}))}
                                        />}
                                    label="Delay Departure date"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.arrivedDate} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,arrivedDate:!prevData.arrivedDate}))}
                                        />}
                                    label="Arrived date"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.expectingDeliveryDate} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,expectingDeliveryDate:!prevData.expectingDeliveryDate}))}
                                        />}
                                    label="Expecting Delivery Date"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.viewRemark} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,viewRemark:!prevData.viewRemark}))}
                                        />}
                                    label="View Remark"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.dateOfAcceptance} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,dateOfAcceptance:!prevData.dateOfAcceptance}))}
                                        />}
                                    label="Date of Acceptance"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={columnVisibilityModel?.action} 
                                        onChange={()=>setColumnVisibilityModel((prevData:any)=>({...prevData,action:!prevData.action}))}
                                        />}
                                    label="Action"
                                />
                            </FormGroup>
                        </MenuItem>
                    </Menu>
                </Box>
                  
                  
                    <Box>
                    <Button
                        className="btn btn_white"
                        aria-controls={open2 ? "basic-menu2" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? "true" : undefined}
                        onClick={handleClick2}
                    >
                        Select Status <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                        className="filter_menu"
                        id="basic-menu2"
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                        MenuListProps={{
                            "aria-labelledby": "basic-button2",
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                borderRadius: "10px",
                                mt: 1,
                            },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <MenuItem className="export_btns v2">
                        <Button onClick={()=>{
                                setSelectedStatus(0)
                                setAnchorEl2(null)
                            }}>All Status</Button>
                                <Button onClick={()=>{
                                setSelectedStatus(6)
                                setAnchorEl2(null)
                            }}>On the way</Button>
                                <Button onClick={()=>{
                                setSelectedStatus(7)
                                setAnchorEl2(null)
                            }}>Departure</Button>
                               <Button onClick={()=>{
                                setSelectedStatus(8)
                                setAnchorEl2(null)
                            }}>Delay Departure</Button>
                                <Button onClick={()=>{
                                setSelectedStatus(9)
                                setAnchorEl2(null)
                            }}>Returned</Button>
                                <Button onClick={()=>{
                                setSelectedStatus(10)
                                setAnchorEl2(null)
                            }}>Arrived</Button>
                                <Button onClick={()=>{
                                setSelectedStatus(11)
                                setAnchorEl2(null)
                            }}>Delay of Delivery</Button>
                                <Button onClick={()=>{
                                setSelectedStatus(12)
                                setAnchorEl2(null)
                            }}>Completed</Button>
                            </MenuItem>
                    </Menu>
                </Box>
                    <Box>
                    <Button
                        className="btn btn_secondary"
                        aria-controls={open3 ? "basic-menu3" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open3 ? "true" : undefined}
                        onClick={handleClick3}
                    >
                        <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
                    </Button>
                    <Menu
                        className="filter_menu"
                        id="basic-menu3"
                        anchorEl={anchorEl3}
                        open={open3}
                        onClose={handleClose3}
                        MenuListProps={{
                            "aria-labelledby": "basic-button3",
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: "visible",
                                boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                borderRadius: "10px",
                                mt: 1,
                            },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <MenuItem>
                            <h2>Choose Option</h2>
                            <p>Select an option to export</p>
                        </MenuItem>
                        <MenuItem className="export_btns">
                <Button onClick={()=>{selectedRow?.row?.length ? exportToPdf(selectedRow?.row,selectedRow?.column.slice(0,-1)):showError("Please select atleast one row")}}>PDF</Button>
                <Button onClick={()=>{selectedRow?.row?.length ? gridApi.current.exportDataAsCsv():showError("Please select atleast one row")}}>CSV</Button>
                <Button onClick={()=>{selectedRow?.row?.length ? exportToExcel(selectedRow?.row,selectedRow?.column):showError("Please select atleast one row")}}>Excel</Button>
                {/* <Button>Txt</Button> */}
              </MenuItem>
                    </Menu>
                </Box>
                </div>
            </div>
        </>
    );
};

export default DeliverTravelersFilter;
