import React from "react";
import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ShipmentSummaryModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accordionContent: JSX.Element;
}

export default function ShipmentSummaryModal({
  open,
  onClose,
  setOpen,
  accordionContent,
}: ShipmentSummaryModalProps) {
  return (
    <Modal
      className="modal shipmentSummary_modal"
      id="shipmentSummaryModal"
      aria-labelledby="shipmentSummary-modal-title"
      aria-describedby="shipmentSummary-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Summary Information</h2>
          </div>

          <div className="custom_accordion">{accordionContent}</div>
        </div>
      </div>
    </Modal>
  );
}
