import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, IconButton } from "@mui/material";

const RecievedNotifications = () => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    return (
        <>
            <div className="main_title">
                <h1>Recieved Notifications</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Recieved Notifications
                </p>
            </div>

            <div className="cards">
                <div className="table_header">
                    <div className="left_s">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setSearchTerm}
                            placeholder="Search..."
                        />
                    </div>
                </div>
                <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className="v3">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>Emma Smith</p>
                                    </div>
                                </TableCell>
                                <TableCell>New Notification Recieved</TableCell>
                                <TableCell className="max_c">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </TableCell>
                                <TableCell>12 Jan 2024</TableCell>
                                <TableCell>
                                    <Box>
                                        <IconButton>
                                            <img src="/static/images/trash_icon.svg" alt="Icon" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>2</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>Emma Smith</p>
                                    </div>
                                </TableCell>
                                <TableCell>New Notification Recieved</TableCell>
                                <TableCell className="max_c">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </TableCell>
                                <TableCell>12 Jan 2024</TableCell>
                                <TableCell>
                                    <Box>
                                        <IconButton>
                                            <img src="/static/images/trash_icon.svg" alt="Icon" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>3</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>Emma Smith</p>
                                    </div>
                                </TableCell>
                                <TableCell>New Notification Recieved</TableCell>
                                <TableCell className="max_c">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </TableCell>
                                <TableCell>12 Jan 2024</TableCell>
                                <TableCell>
                                    <Box>
                                        <IconButton>
                                            <img src="/static/images/trash_icon.svg" alt="Icon" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>4</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>Emma Smith</p>
                                    </div>
                                </TableCell>
                                <TableCell>New Notification Recieved</TableCell>
                                <TableCell className="max_c">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </TableCell>
                                <TableCell>12 Jan 2024</TableCell>
                                <TableCell>
                                    <Box>
                                        <IconButton>
                                            <img src="/static/images/trash_icon.svg" alt="Icon" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>5</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>Emma Smith</p>
                                    </div>
                                </TableCell>
                                <TableCell>New Notification Recieved</TableCell>
                                <TableCell className="max_c">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </TableCell>
                                <TableCell>12 Jan 2024</TableCell>
                                <TableCell>
                                    <Box>
                                        <IconButton>
                                            <img src="/static/images/trash_icon.svg" alt="Icon" />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default RecievedNotifications;
