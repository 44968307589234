/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import FreightCustomerDetail from "../../Modals/freightCustomerDetail";
import { Cancel } from "@mui/icons-material";
interface props{
    gridApi:any,
    setSelectedRow:any,
    columnVisibilityModel:any,
    beneficiary:any
}
const BeneficiaryList = ({gridApi,setSelectedRow,columnVisibilityModel,beneficiary}:props) => {
    const SHIPMENT_STATUS:any= {
        1:"PENDING",
        2:"ACCEPTED",
       3: "ONGOING",
       4: "COMPLETED",
        5:"CANCELLED"
      };
    const navigate = useNavigate();
    const[shipmentDetails,setShipmentDetails]=useState([]);
    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const rows :any = [];
    beneficiary?.map((data:any)=>{
        rows?.push({
            id:data?._id,
            name:data?.name,
            countryCode:data?.countryCode,
            phone:data?.phone,
            gender:data?.userDetails?.gender?(data?.userDetails?.gender=="1"?"Male":"Female"):"-",
            mobile:data?.countryCode+data?.phone,
            currentLocation:data?.city+", "+data?.country,
            email:data?.userDetails?.email?data?.userDetails?.email:"-",
            image:data?.userDetails?.personVerification?.profileImage?data?.userDetails?.personVerification?.profileImage:"/static/images/user-placeholder.jpg"
            // data?.shipmentDetails?.personVerification?.profileImage
            ,
            TR:"TR"+data?.shipmentDetails?.shipmentId,
            shipmentData:{...data?.shipmentDetails,isHome:data?.deliverToHome},
            membershipNo:"GN"+(data?.membershipId?data?.membershipId:"000000000000"),
            statusValue:data?.shipmentDetails?.shipmentStatus,
            status:SHIPMENT_STATUS[data?.shipmentDetails?.shipmentStatus],
            userId:data?.userId,
        })
    })
    const columns: GridColDef<(typeof rows)[number]>[] = [
        
        // {
        //     flex: 1,
        //     minWidth: 160,
        //     field: "TR",
        //     headerName: "TR",
        //     editable: false,
        //     sortable: false,
        // },
        {
            flex: 1,
            minWidth: 200,
            field: "name",
            headerName: "Name",
            editable: false,
            renderCell: (data:any) => {
                return (
                    <div className="user_block"
                    style={{position:"relative"}}
                    >
                        <figure 
                      
                        onClick={() => 
                           data?.row?.userId? navigate("/profile-detail/"+data?.row?.userId):null
                        }
                        >
                            {!data?.row?.userId && <IconButton  style={{position:"absolute",bottom:-15,left:15}}><Cancel fontSize="small" color="error"/></IconButton>}
                            <img src={data?.row?.image?data?.row?.image:"/static/images/user-placeholder.jpg"} alt="" />
                        </figure>
                        <p><b>{data?.row?.name}</b> {data?.row?.email}</p>
                    </div>
                );
            },
        },
        {
            flex: 1,
            minWidth: 170,
            field: "membershipNo",
            headerName: "Membership #",
            editable: false,
            sortable: false,
        },
        {
            flex: 1,
            minWidth: 100,
            field: "gender",
            headerName: "Gender",
            editable: false,
            align:"center",
            sortable: false,
        },
        {
            flex: 1,
            minWidth: 100,
            field: "mobile",
            headerName: "Mobile",
            editable: false,
            sortable: false,
        },
        {
            flex: 1,
            minWidth: 100,
            field: "email",
            headerName: "Email",
            editable: false,
            sortable: false,
            align:"center"
        },
        {
            flex: 1,
            minWidth: 180,
            field: "currentLocation",
            headerName: "Current Location",
            editable: false,
        },
        {
            flex: 1,
            minWidth: 150,
            field: "shipmentNumber",
            headerName: "Shipment Number",
            editable: false,
            sortable: false,
            disableExport:true,
            renderCell: (data:any) => {
                return (
                    <Button className="btn_text c_highlight" onClick={() => {
                        setShipmentDetails(data?.row?.shipmentData)
                        setOpenModal(true)
                    
                    }}>View Detail</Button>
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "status",
            headerName: "Status",
            editable: false,
            sortable: false,
            renderCell: (data:any) => {
                return (
                        <>
    
                        {data?.row?.status &&  <Button className="btn_text c_black">{data?.row?.status}</Button>}
                        {data?.row?.status &&  <Button className="btn_text c_blue">{data?.row?.status}</Button>}
                        {data?.row?.status &&  <Button className="btn_text c_warning">{data?.row?.status}</Button>}
                        {data?.row?.status &&  <Button className="btn_text c_success">{data?.row?.status}</Button>}
                        {data?.row?.status &&  <Button className="btn_text c_danger">{data?.row?.status}</Button>}
                    
                        </>
                );
            },
        },
    ];

    let hidden = ["fullName"];

    return (
        <>
            <div className="sorting_table">
                <TableComponent
                
                    columns={columns?.filter((item) => !hidden.includes(item.field))}
                    rows={rows} 
                    columnVisibilityModel={columnVisibilityModel} 
                    setSelectedRow={setSelectedRow} 
                    gridApi={gridApi}                />
            </div>
            
            <FreightCustomerDetail open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} 
            shipmentDetails={shipmentDetails}
             />
        </>
    );
};

export default BeneficiaryList;
