/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ReportFilter = () => {

    const [selectField1, setSelectField1] = React.useState('default');
    const handleChange1 = (event: SelectChangeEvent) => {
        setSelectField1(event.target.value as string);
    };

    const [selectField2, setSelectField2] = React.useState('default');
    const handleChange2 = (event: SelectChangeEvent) => {
        setSelectField2(event.target.value as string);
    };

    return (
        <>
            <div className="table_header select_filter">
                <div className="d_flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="From Date" />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="To Date" />
                    </LocalizationProvider>
                    <Select
                        fullWidth
                        labelId="country_from"
                        id="country_from"
                        value={selectField1}
                        onChange={handleChange1}
                    >
                        <MenuItem disabled value="default">Select Country</MenuItem>
                        <MenuItem value={10}>Sudan</MenuItem> 
                        <MenuItem value={20}>Saudi Arabia</MenuItem>
                        <MenuItem value={30}>UAE</MenuItem>
                        <MenuItem value={40}>Egypt</MenuItem>
                        <MenuItem value={50}>Qatar</MenuItem>
                        <MenuItem value={60}>Kuwait</MenuItem>
                        <MenuItem value={70}>Bahrain</MenuItem>
                        <MenuItem value={80}>Sultanate of Oman</MenuItem>
                        <MenuItem value={90}>Turkey</MenuItem>
                        <MenuItem value={100}>USA</MenuItem>
                        <MenuItem value={110}>UK</MenuItem>
                        <MenuItem value={120}>India</MenuItem>
                        <MenuItem value={130}>Russia</MenuItem>
                        <MenuItem value={140}>Philippines</MenuItem>
                        <MenuItem value={150}>Jordan</MenuItem>
                        <MenuItem value={160}>Sweden</MenuItem>
                        <MenuItem value={170}>Netherlands</MenuItem>
                        <MenuItem value={180}>Canada</MenuItem>
                        <MenuItem value={190}>South Sudan</MenuItem>
                        <MenuItem value={200}>France</MenuItem>
                    </Select>
                    <Select
                        fullWidth
                        labelId="country_to"
                        id="country_to"
                        value={selectField2}
                        onChange={handleChange2}
                    >
                        <MenuItem disabled value="default">Select City</MenuItem>
                        <MenuItem value={10}>All</MenuItem>
                        <MenuItem value={20}>Riyadh</MenuItem>
                        <MenuItem value={30}>Jeddah</MenuItem>
                        <MenuItem value={40}>Dammam</MenuItem>
                    </Select>
                </div>
                <Button className="btn btn_square btn_secondary"><img src="/static/images/searchbar_icon.svg" alt="Icon" /></Button>
                <Button className="btn btn_white">Clear</Button>
            </div>
        </>
    );
};

export default ReportFilter;
