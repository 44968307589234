import { Modal } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ShippingGalleryModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    imageArray:any;
    selectedImageIndex:any;
}

const imagesData = [
    {
        id: 1,
        image: "static/images/shipping_01.jpg",
    },
    {
        id: 2,
        image: "static/images/shipping_02.jpg",
    },
    {
        id: 3,
        image: "static/images/shipping_03.jpg",
    },
    {
        id: 4,
        image: "static/images/shipping_04.jpg",
    },
    {
        id: 5,
        image: "static/images/shipping_01.jpg",
    },
    {
        id: 6,
        image: "static/images/shipping_02.jpg",
    },
    {
        id: 7,
        image: "static/images/shipping_03.jpg",
    },
];

export default function ShippingGallery({ open, onClose, setOpen,imageArray,selectedImageIndex }: ShippingGalleryModalProps) {

    const [nav1, setNav1] = useState<any | null>(null);
    const [nav2, setNav2] = useState<any | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const sliderRef1 = useRef<any | null>(null);
    const sliderRef2 = useRef<any | null>(null);

    const handleAfterChange = (index: number) => {
        setActiveIndex(index);
       if(nav2)
        nav2.slickGoTo(index);
    };

    useEffect(() => {
        setTimeout(() => {
            setNav1(sliderRef1.current);
            setNav2(sliderRef2.current);
        }, 100);
    }, [imageArray, open]);

    return (
        <Modal
            className="modal shippingGallery_modal"
            id="shippingGalleryModal"
            aria-labelledby="shippingGallery-modal-title"
            aria-describedby="shippingGallery-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Shipping Pictures</h2>
                    </div>
                    <Slider
                        className="product_gallery"
                        asNavFor={nav2}
                        slidesToShow={1}
                        slidesToScroll={1}
                        initialSlide={selectedImageIndex}
                        ref={(slider) => (sliderRef1.current = slider)}
                        infinite={imageArray?.length > 1}
                    >
                        {imageArray?.length
                            ? imageArray?.map((item:any, index:any) => (
                                <figure className="product_image" key={index}>
                                    <img
                                        src={
                                            item ||
                                            "/images/testimonial_attachment_1.jpg"
                                        }
                                        alt=""
                                    />
                                </figure>
                            ))
                            : undefined}
                    </Slider>
                    <Slider
                        className="product_thumb"
                        asNavFor={nav1}
                        afterChange={handleAfterChange}
                        ref={(slider) => (sliderRef2.current = slider)}
                        slidesToShow={4}
                        slidesToScroll={1}
                        swipeToSlide={imageArray?.length > 1}
                        focusOnSelect={imageArray?.length > 1}
                        infinite={imageArray?.length > 1}
                    >
                        {imageArray?.length
                            ? imageArray?.map((item:any, index:any) => (
                                <figure className="product_image" key={index}>
                                    <img
                                        src={
                                            item ||
                                            "/images/testimonial_attachment_1.jpg"
                                        }
                                        alt=""
                                    />
                                </figure>
                            ))
                            : undefined}
                    </Slider>
                </div>
            </div>
        </Modal >
    )
}
