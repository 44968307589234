import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import TextField from '@mui/material/TextField';
import { FormGroup, FormControlLabel, Checkbox, Button } from "@mui/material";
import EditText from "../../components/editText";

const BroadcastMessage = () => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [legal, setLegal] = useState<string>("");

    return (
        <>
            <>
                <div className="main_title">
                    <h1>Broadcast Message</h1>
                    <p>
                        <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                        Chat
                    </p>
                </div>

                <div className="broadcast_grid gap_m">
                    <div className="left_s">
                        <div className="cards">
                            <SearchBar
                                searchTerm={searchTerm}
                                setDebouncedSearchTerm={setSearchTerm}
                                placeholder="Search by email"
                            />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Select All" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="right_s">
                        <div className="cards">
                            <h2>Write your message</h2>
                            <form className="form">
                                <div className="control_group">
                                    <TextField
                                        variant="outlined"
                                        placeholder="Input Title"
                                        fullWidth
                                        hiddenLabel
                                    />
                                </div>
                                <div className="control_group">
                                    <EditText content={legal} setContent={setLegal} />
                                </div>
                                <div className="form_btn">
                                    <Button className="btn btn_secondary">Send Message</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default BroadcastMessage;
