import React, { useEffect, useRef, useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Button, Select, SelectChangeEvent, MenuItem, Checkbox, Box, Grid, Typography } from '@mui/material'
import { useAddNotificationMutation } from '../services/manage-notification';
import { showToast, showError } from '../constants/toasts';
import { CommonBody } from '../types/General';
import { generateEncryptedKeyBody } from '../utils/crypto';
import { useNavigate } from 'react-router-dom';
import { useLazyGetTravelerQuery } from '../services/travelers';
import { useLazyGetUsersQuery } from '../services/user';

interface AddNotificationModalProps {
    open: boolean;
    onClose: () => void;
    getNotificationList:any;
    setOpen: Dispatch<SetStateAction<boolean>>
}

export default function AddNotification({ open, onClose, setOpen,getNotificationList }: AddNotificationModalProps) {
    const selectRef = useRef<any>(null);
    const [checked, setChecked] = useState<boolean>(false);
    const[error,setError]=useState(false);
    const[selectAll,setSelectAll]=useState(false);
    const[names,setNames]=useState<any>([]);
    const navigate = useNavigate();
    // const [getTravelers] = useLazyGetTravelerQuery();
    // const getTravelersList = async () => {
    //     // setIsLoading(true);
    //     try {
    //       const response = await getTravelers({
    //         wpagination:true
    //         // page: 1,
    //         // limit: 10,
    //         // query: debouncedSearchTerm.trim(),
    //       }).unwrap();
    //       if (response?.statusCode === 200) {
    //         setNames(response?.data?.data || []);
    //       } else {
    //         setNames([]);
    //       }
    //     } catch (error: any) {
    //       showError(error?.data?.message || "");
    //     }
    //     // setIsLoading(false);
    //   };
    const [getUsers] = useLazyGetUsersQuery();
    const getUsersList = async () => {
        // setIsLoading(true);
        try {
          const response = await getUsers({
            wpagination:true
            // page: 1,
            // limit: 10,
            // query: debouncedSearchTerm.trim(),
          }).unwrap();
          if (response?.statusCode === 200) {
            setNames(response?.data || []);
          } else {
            setNames([]);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
        // setIsLoading(false);
      };
      const [receiver, setReceiver] = useState<string>("All");
      const[allSelected,setAllSelected]=useState(false);
      const[userNames,setUserNames]=useState<any>([]);
      console.log(userNames);
  
  
  
   
    const handleReceiverChange = (
      event: SelectChangeEvent<typeof userNames>
    ) => {
      const {
        target: { value },
      } = event;
      setUserNames(
        // Ensure the value is not null or undefined
        typeof value === "string" ? [value] : value
      );
  
    };
  useEffect(()=>{
    // getTravelersList();
    getUsersList();
  },[])
    const [selectField1, setSelectField1] = React.useState('default');
    const handleChange1 = (event: SelectChangeEvent) => {
        setSelectField1(event.target.value as string);
    };
    const[title,setTitle]=useState<any>("");
    const[message,setMessage]=useState<any>("");
const [addNotification]=useAddNotificationMutation();
 const handleAddNotification=async()=>{
      try{

        const body:any={
            title:title,
            message:message,
            //   userArray:[],
        }
        if(selectAll)
            {
                body['type']=1;
            }
            else
            {
                body['type']=2
                body['userArray']=userNames
            }
        console.log('body :', body);
        const encryptedBody= generateEncryptedKeyBody(body) as CommonBody;
        const response=await addNotification(encryptedBody).unwrap();
        if(response.statusCode==200)
          {
            showToast("Notification added successfully")
            getNotificationList();
            setTitle("");
            setMessage("");
            setSelectField1('default');
            setError(false);
            setOpen(false);
        }
    }
    catch(error:any)
    {
      showError(error.data.message);
    }
  }
    return (
        <Modal
            className="modal addNotification_modal"
            id="addNotificationModal"
            aria-labelledby="addNotification-modal-title"
            aria-describedby="addNotification-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Add Notification</h2>
                    </div>
                    <form className="form" action="">
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Notification Title"
                                fullWidth
                                hiddenLabel
                                value={title}
                                onChange={(e)=>setTitle(e.target.value)}
                            />
                              { error && title==""? (
                        <h6 className="err_msg">Title is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Notification Message"
                                fullWidth
                                hiddenLabel
                                value={message}
                                onChange={(e)=>setMessage(e.target.value)}
                            />
                             { error && message==""? (
                        <h6 className="err_msg">Message is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        {/* <div className="control_group">
                            <Select
                                fullWidth
                                labelId="gender"
                                id="gender"
                                value={selectField1}
                                onChange={handleChange1}
                            >
                                <MenuItem disabled value="default">Select Traveller</MenuItem>
                                <MenuItem value={10}>All Travellers</MenuItem>
                                <MenuItem value={10}>Traveller 1</MenuItem>
                                <MenuItem value={20}>Traveller 2</MenuItem>
                                <MenuItem value={30}>Traveller 3</MenuItem>
                            </Select>
                            { error && selectField1=="default"? (
                        <h6 className="err_msg">Select atleast one user</h6>
                      ) : (
                        ""
                      )}
                        </div> */}
                        <div className="control_group">
                            {/* <Select
                                fullWidth
                                labelId="gender"
                                id="gender"
                                value={selectField1}
                                onChange={handleChange1}
                            >
                                <MenuItem disabled value="default">Select Freight Customers</MenuItem>
                                <MenuItem value={10}>All Customers</MenuItem>
                                <MenuItem value={10}>Customer 1</MenuItem>
                                <MenuItem value={20}>Customer 2</MenuItem>
                                <MenuItem value={30}>Customer 3</MenuItem>
                            </Select> */}
                            <Select 
                     
                     // onScroll={handleScroll}
                     fullWidth
                     className="user_lst"
                     labelId="demo-simple-select-label"
                     id="gender"
                     value={userNames}
                     sx={{ overflowY: "auto" }}
                    

                     multiple
                     // multiple
                     inputProps={{
                       "aria-label": "Without label",
                     }}
                    MenuProps={{
                     sx:{maxHeight:"300px"},
                     PaperProps:{
                       ref:selectRef,
                      //  onScroll:handleScroll
                     }
                    }}
                     disabled={selectAll}
                     displayEmpty
                     onChange={handleReceiverChange}
                     renderValue={(selected)=>{
                       if(selected.length===0){
                         return <span>Select User</span>
                       }
                       const selectedUserNames = selected.map((userId:any) => {
                         const userFound = names.find((user:any) => user._id === userId);
                         return userFound ? `${userFound?.fullName}` : "";
                       });
                       return selectedUserNames.join(", ");
                     }}
                   >
                     
                     <MenuItem value="" disabled>
                       Select User
                     </MenuItem>
                    
                     {names?.map((item: any, index: any) => (
                       <MenuItem value={item?._id}>
                         {item?.fullName}
                       </MenuItem>
                     ))}
                 
                   </Select>
                            { error && (!selectAll && userNames.length==0)? (
                        <h6 className="err_msg">Select atleast one user</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography  style={{display:"flex",alignItems:"center"}}>
                    <Checkbox id="all" checked={selectAll} onChange={()=>{
                      setSelectAll(!selectAll)
                      setUserNames([]);
                      }}/>  <label htmlFor="all" style={{marginBottom:"0px",marginInline:"3px"}}>Select All</label>
                    </Typography>
                  <Box>
                    
                  </Box>
                </Grid>
                        <div className="footer_btn_flex">
                            <Button className="btn btn_highlight" onClick={() => {
                                  setError(false);
                                 setTitle("");
                                 setMessage("");
                                 setSelectField1('default');
                                setOpen(false)}}>Cancel</Button>
                            <Button className="btn btn_secondary" onClick={(e)=>{
                                e.preventDefault();
                                setError(true);
                                if(title=="") return;
                                if(message=="")return;
                                if(!selectAll && userNames.length==0) return;
                                handleAddNotification();
                               
                                
                                
                            }}>Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal >
    )
}
