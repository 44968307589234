import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import TextField from '@mui/material/TextField';
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showError, showToast } from "../../constants/toasts";
import { useEditUserByIdMutation, useLazyGetUsersByIdQuery } from "../../services/user";
import EditPassportModal from "../../Modals/EditPassportModal";
import PassportModal from "../../Modals/passportModal";
import { errorToast } from "../../helpers";
import { UploadMedia } from "../../utils/uploadMedia";
import EditVehicleDetailModal from "../../Modals/EditVehicleDetailModal";
import EditTicketModal from "../../Modals/EditTicketModal";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import PhoneInput from "react-phone-input-2";
interface UserData {
    tripCount: number
    orderDelivered: number
    onTimeDelivery: number
    DeliverQuality: number
    user: User
    lastShipment: LastShipment
    rating: number
    ratingCount: number
    travelCount: number
    message: string
}

interface User {
    personVerification: PersonVerification
    fullName: string
    firstName: string
    lastName: string
    phone: string
    image: string
    _id: string
    gender: string
    countryCode: string
    email: string
    id: string
}

interface PersonVerification {
    profileImage: string
    idCard: string
    residenceCard: string
    residenceType: number
}

interface LastShipment {
    vehicleDetail: VehicleDetail
    currentAddress: CurrentAddress
    personVerification: PersonVerification2
    shipmentDetail: ShipmentDetail
    multiCity: boolean
    ticket: any[]
    adminStatus: number
    shipmentStatus: number
    rejectionReason: any
    cargoType: number[]
    drugsVolunteer: boolean
    shipmentId: string
    status: boolean
    additionalShipment: boolean
    isBlocked: boolean
    isDeleted: boolean
    _id: string
    travelType: number
    travelDetail: TravelDetail[]
    destinationAddress: DestinationAddress[]
    userId: string
    type: number
    packageDetail: any[]
    createdAt: string
    updatedAt: string
    __v: number
    drugsCertification: number
    finalPaymentCurrency: number
    paymentArray: PaymentArray
    paymentMode: number
    paymentType: number
}

interface VehicleDetail {
    vehicleReg: string
    front: string
    side: string
    back: string
}

interface CurrentAddress {
    city: string
    district: string
    square: string
    houseNumber: string
    countryCode: string
    phone: string
    whatsappNumber: boolean
    address: string
}

interface PersonVerification2 {
    profileImage: string
    idCard: string
    residenceCard: string
    residenceType: number
}

interface ShipmentDetail {
    quantity: number
    totalWeight: any
    photo: string
    front: string
    side: string
    back: string
    packagingServiceNeeded: boolean
}

interface TravelDetail {
    departingFrom: string
    destination: string
    _id: string
    departureDate: string
    arrivalDate: string
    availableWeight: number
    travelNumber: number
}

interface DestinationAddress {
    city: string
    district: string
    square: string
    houseNumber: string
    countryCode: string
    phone: string
    whatsappNumber: boolean
    remindMe: boolean
    address: string
    _id: string
    deliveryDate: string
    deliveryTime: string
    travelNumber: number
}

interface PaymentArray {
    bankName: string
    accHolderName: string
    accNumber: string
}

const EditProfile = () => {
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [idData, setIdData] = useState({
        idCard: "",
        residenceCard: ""
    });
    const [selectField1, setSelectField1] = React.useState('default');
    const handleChange1 = (event: SelectChangeEvent) => {
        setSelectField1(event.target.value as string);
    };

    const getTravelTypeName = (travelType: number) => {
        if (travelType == 1) {
            return "Private Vehicle"
        }
        else if (travelType == 2) {
            return "Airlines"
        }
        else if (travelType == 3) {
            return "Voyage"
        }
        else if (travelType == 4) {
            return "Railway"
        }
        else if (travelType == 5) {
            return "Public Road"
        }
        else {
            return ""
        }
    }

    const [vehicleData, setVehicleData] = useState({
        front: "",
        side: "",
        back: "",
        vehicleReg: ""
    })

    const [selectField2, setSelectField2] = React.useState('default');
    const handleChange2 = (event: SelectChangeEvent) => {
        setSelectField2(event.target.value as string);
    };

    const [selectField3, setSelectField3] = React.useState('default');
    const handleChange3 = (event: SelectChangeEvent) => {
        setSelectField3(event.target.value as string);
    };
    const [vehicleModal, setVehicleModal] = React.useState(false);
    const closeVehicleModal = () => {
        setVehicleModal(false);
    };
    const [ticketModal, setTicketModal] = React.useState(false);
    const closeTicketModal = () => {
        setTicketModal(false);
    };

    const [travelList, setTravelList] = useState<any>([
        //     {
        //                 phone:"",
        //                 countryCode:"",
        //                 phoneToDisplay:"",
        //                 destinationAddress:"",
        //                 destinationCity:"",
        //                 destinationDate:"",
        //                 availableWeight:"",
        // },
        // {
        //                  phone:"",
        //                 countryCode:"",
        //                 phoneToDisplay:"",
        //                 destinationAddress:"",
        //                 destinationCity:"",
        //                 destinationDate:"",
        //                 availableWeight:"",
        // },
        // {
        //                   phone:"",
        //                 countryCode:"",
        //                 phoneToDisplay:"",
        //                 destinationAddress:"",
        //                 destinationCity:"",
        //                 destinationDate:"",
        //                 availableWeight:"",
        // },
    ])

    const [open, setOpen] = useState(false);
    function handleClose() {
        setOpen(false);
    }
    const [ticket, setTicket] = useState([]);
    const [userData, setUserData] = React.useState<UserData | undefined>();
    const [getUserbyId]: any = useLazyGetUsersByIdQuery();
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [phoneToDisplay, setPhoneToDisplay] = useState("");
    const handleChangePhone = (phone: any, country: any) => {
        setPhone(phone?.replace(country.dialCode, ""));
        setCountryCode(country.dialCode);
        setPhoneToDisplay(phone);
    };
    const [phoneData, setPhoneData] = useState<string[]>([]);
    const [countryCodeData, setCountryCodeData] = useState<string[]>([]);
    const [phoneToDisplayData, setPhoneToDisplayData] = useState<string[]>([]);
    const handleChangePhoneData = (phone: any, country: any, index: number) => {
        console.log(phone, "TEST", country);
        setPhoneData((prevData: any) => {
            return prevData?.map((data: any, i: number) => {
                if (index == i) {
                    return phone?.replace(country.dialCode, "")
                }
                return data
            })
        });
        setCountryCodeData((prevData: any) => {
            return prevData?.map((data: any, i: number) => {
                if (index == i) {
                    return country.dialCode
                }
                return data
            })
        });
        setPhoneToDisplayData((prevData: any) => {
            return prevData?.map((data: any, i: number) => {
                if (index == i) {
                    return phone
                }
                return data
            })
        });
    };

    const getData = async (id: string) => {
        try {
            const response = await getUserbyId({ id: id }).unwrap();
            if (response.statusCode == 200) {
                formik.setFieldValue('fullName', response?.data?.user?.firstName ? (response?.data?.user?.firstName + " " + response?.data?.user?.lastName) : response?.data?.user?.fullName)
                formik.setFieldValue('shipmentId', response?.data?.lastShipment?.travelId ? response?.data?.lastShipment?.travelId : "-")
                formik.setFieldValue('travelCount', response?.data?.travelCount)
                formik.setFieldValue('email', response?.data?.user?.email)
                formik.setFieldValue('gender', (response?.data?.user?.gender ? parseInt(response?.data?.user?.gender) : "default"))
                formik.setFieldValue('residencyType', response?.data?.user?.personVerification?.residenceType)
                formik.setFieldValue('currentCity', response?.data?.lastShipment?.currentAddress?.city)
                formik.setFieldValue('currentCountry', response?.data?.lastShipment?.currentAddress?.district)
                formik.setFieldValue('currentAddress', response?.data?.lastShipment?.currentAddress?.address)
                formik.setFieldValue('departureCity', response?.data?.lastShipment?.travelDetail?.[0]?.departingFrom)
                formik.setFieldValue('departureDate', response?.data?.lastShipment?.travelDetail?.[0]?.departureDate.slice(0, 10))
                formik.setFieldValue('travelType', response?.data?.lastShipment?.travelType)
                setIdData({
                    idCard: response?.data?.lastShipment?.personVerification?.idCard,
                    residenceCard: response?.data?.lastShipment?.personVerification?.residenceCard
                })
                setPhone(response?.data?.user?.phone);
                setCountryCode(response?.data?.user?.countryCode)
                setPhoneToDisplay(response?.data?.user?.countryCode + response?.data?.user?.phone)
                setTicket(response?.data?.lastShipment?.ticket || [])
                const temp = [];
                const tempPhone = [];
                const tempCountryCode = [];
                const tempPhoneToDisplay = [];
                const tempTravelType = [];
                for (let key in response?.data?.lastShipment?.travelDetail) {
                    tempPhone.push(response?.data?.lastShipment?.destinationAddress?.[key]?.phone)
                    tempCountryCode.push(response?.data?.lastShipment?.destinationAddress?.[key]?.countryCode,)
                    tempPhoneToDisplay.push(response?.data?.lastShipment?.destinationAddress?.[key].countryCode + response?.data?.lastShipment?.destinationAddress?.[key]?.phone,
                    )
                    temp.push({
                        phone: response?.data?.lastShipment?.destinationAddress?.[key]?.phone,
                        countryCode: response?.data?.lastShipment?.destinationAddress?.[key]?.countryCode,
                        phoneToDisplay: response?.data?.lastShipment?.destinationAddress?.[key].countryCode + response?.data?.lastShipment?.destinationAddress?.[key]?.phone,
                        destinationAddress: response?.data?.lastShipment?.travelDetail?.[key]?.destination,
                        destinationCity: response?.data?.lastShipment?.destinationAddress?.[key]?.city,
                        destinationDate: response?.data?.lastShipment?.travelDetail[key]?.arrivalDate?.slice(0, 10),
                        availableWeight: response?.data?.lastShipment?.travelDetail?.[key]?.availableWeight,
                    })
                    tempTravelType.push(response?.data?.lastShipment?.travelDetail?.[key].travelType)
                }

                setTravelType(tempTravelType);
                setTravelList(temp);
                setPhoneData(tempPhone);
                setCountryCodeData(tempCountryCode);
                setPhoneToDisplayData(tempPhoneToDisplay);
                setVehicleData(response?.data?.lastShipment?.vehicleDetail)
                setUserData(response?.data);
            }

        }
        catch (error: any) {
            showError(error?.data?.message || "");
        }
    }

    useEffect(() => {
        if (id) {
            getData(id);
        }


    }, [])
    const [updateData] = useEditUserByIdMutation();
    const [travelType, setTravelType] = useState<number[]>([])
    console.log('travelType :', travelType);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullName: "",
            shipmentId: "",
            travelCount: "",
            email: "",
            gender: 1,
            residencyType: 1,
            currentCountry: "",
            currentCity: "",
            currentAddress: "",
            departureCity: "",
            departureDate: "",
            travelType: 1,
        },
        onSubmit: async (values) => {
            if (travelType?.some(data => data == 1)) {
                if (!vehicleData?.back) {
                    showError("Private Vehicle back image not added")
                    return;
                }
                if (!vehicleData?.front) {
                    showError("Private Vehicle front image not added")
                    return;
                }
                if (!vehicleData?.side) {
                    showError("Private Vehicle side image not added")
                    return;
                }
                if (!vehicleData?.vehicleReg) {
                    showError("Private Vehicle registration not added")
                    return;
                }


            }
            if (travelType?.some(data => data > 1)) {
                if (!ticket.length) {
                    showError("Travel ticket not added")
                    return;
                }
            }
            console.log(values);
            const tempTravelDetails = userData?.lastShipment?.travelDetail?.map((data: any, index: number) => {
                if (index == 0) {
                    return {
                        ...data,
                        departingFrom: formik.values.departureCity,
                        departureDate: (new Date(formik.values.departureDate).toISOString()),
                        availableWeight: travelList[index]?.availableWeight,
                        destination: travelList[index]?.destinationAddress,
                        travelType: travelType[index],
                        arrivalDate: travelList?.[index]?.destinationDate
                    }
                }
                return {
                    ...data,
                    availableWeight: travelList[index]?.availableWeight,
                    travelType: travelType[index],
                    arrivalDate: travelList?.[index]?.destinationDate

                };
            })
            // if(!tempTravelDetails?.length)
            //     {
            //         tempTravelDetails?.push({ departingFrom:formik.values.departureCity,
            //             departureDate:(new Date(formik.values.departureDate).toISOString()),
            //             availableWeight:travelList[0]?.availableWeight,
            //             destination:travelList[0]?.destinationAddress,
            //     })
            //     }
            const tempDestinationAddress = userData?.lastShipment?.destinationAddress.map((data: DestinationAddress, index: number) => {
                return {
                    ...data,

                    city: travelList[index]?.destinationCity,
                    phone: phoneData?.[index],
                    countryCode: countryCodeData?.[index]?.includes("+") ? countryCodeData?.[index] : "+" + countryCodeData?.[index]


                }
            })
            const body = {
                phone: phone,
                countryCode: countryCode?.includes("+") ? countryCode : "+" + countryCode,
                lastShipment: {
                    _id: userData?.lastShipment?._id,
                    vehicleDetail: travelType?.some(data => data == 1) ? vehicleData : null,
                    currentAddress: {
                        ...userData?.lastShipment?.currentAddress,
                        city: formik.values.currentCity,
                        district: formik.values.currentCountry,
                        address: formik.values.currentAddress
                    },
                    travelDetail: tempTravelDetails,
                    travelType: formik.values.travelType,
                    ticket: travelType.some(data => data > 1) ? ticket : null,
                    destinationAddress: tempDestinationAddress,

                }
            }
            console.log('body :', body);

            try {
                const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
                const response = await updateData({ id: `${id}`, body: encryptedData }).unwrap();
                if (response?.statusCode == 200) {
                    showToast(response?.message || "User Updated Successfully");
                    // navigate("/profile-detail/"+id);
                    navigate(state ? state : "/profile-detail/" + id, { replace: true })
                }
            } catch (error: any) {
                showError(error?.data?.message || "");
                console.log(error);
            }


        },
    })
    return (
        <>
            <div className="main_title">
                <h1>Edit Profile Detail</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Registrations - Edit Profile Detail
                </p>
            </div>

            <div className="cards">
                <form className="form" onSubmit={formik.handleSubmit}>
                    <div className="gap_p">
                        <div className="control_group w_50">
                            <label>Name</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                disabled
                                name="fullName"
                                value={formik.values.fullName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="control_group w_50">
                            <label>TR</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                disabled
                                name="shipmentId"
                                value={formik.values.shipmentId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="control_group w_50">
                            <label>No of Travel</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                disabled
                                name="travelCount"
                                value={formik.values.travelCount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="control_group w_50">
                            <label>E-mail</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="email"
                                disabled
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="control_group w_50">
                            <label>Gender</label>
                            <Select
                                fullWidth
                                labelId="gender"
                                id="gender"
                                disabled
                                name="gender"
                                value={formik.values.gender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem disabled value="default">Select</MenuItem>
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={2}>Female</MenuItem>
                            </Select>
                        </div>
                        <div className="control_group w_50">
                            <label>Mobile</label>
                            {/* <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="mobile"
                                disabled
                                value={phoneToDisplay}
                                onChange={(e)=>setPhoneToDisplay(e.target.value)}
                            /> */}
                            <PhoneInput
                                specialLabel=""
                                country={"us"}
                                placeholder="0 (000) 000-000"
                                enableSearch={true}
                                disabled
                                inputStyle={{ color: "grey", backgroundColor: "white", width: "100%" }}
                                value={phoneToDisplay}
                                // onChange={(value) => formik.setFieldValue("phone", value)}
                                onChange={(phone: any, country: any) =>
                                    handleChangePhone(phone, country)
                                }
                            />
                        </div>
                        <div className="control_group w_50">
                            <label>Residency Type</label>
                            <Select
                                fullWidth
                                labelId="residencyType"
                                id="residencyType"
                                name="residencyType"
                                disabled
                                value={formik.values.residencyType}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem disabled value="default">Select</MenuItem>
                                <MenuItem value={1}>Residence</MenuItem>
                                <MenuItem value={2}>Visitor</MenuItem>
                                <MenuItem value={3}>Citizen</MenuItem>
                            </Select>
                        </div>
                        <div className="control_group w_50">
                            <label>Current Country</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="currentCountry"
                                value={formik.values.currentCountry}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="control_group w_50">
                            <label>Current City</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel

                                name="currentCity"
                                value={formik.values.currentCity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>

                        <div className="control_group w_50">
                            <label>Current Address</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel

                                name="currentAddress"
                                value={formik.values.currentAddress}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.values.departureCity && <div className="control_group w_50">
                            <label>Departure City</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="departureCity"
                                value={formik.values.departureCity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>}
                        {formik.values.departureDate && <div className="control_group w_50">
                            <label>Departure Date</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="departureDate"
                                type="date"
                                value={formik.values.departureDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>}
                        {/* <div className="control_group w_50">
                            <label>Way of Travel</label>
                            <Select
                                fullWidth
                                labelId="wayOfTravel"
                                id="wayOfTravel"
                                name="travelType"
                                value={formik.values.travelType}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem disabled value="default">Select</MenuItem>
                                <MenuItem value={1}>Private Vehicle</MenuItem>
                                <MenuItem value={2}>Airline</MenuItem>
                                <MenuItem value={3}>Voyage</MenuItem>
                                <MenuItem value={4}>Railway</MenuItem>
                                <MenuItem value={5}>Public Road</MenuItem>
                            </Select>
                        </div> */}
                        <div
                            onClick={() => {
                                setOpen(true)
                            }}
                            className="control_group w_50">
                            <label>Passport /ID</label>
                            <div className="upload_input">
                                <TextField
                                    variant="outlined"
                                    placeholder="View Passport Id"
                                    fullWidth
                                    hiddenLabel
                                />
                                <p>View Passport Id <img src="/static/images/paperClip_icon.svg" alt="Icon" /></p>
                            </div>
                        </div>
                        {
                            travelType?.some(data => data == 1) &&
                            <div className="control_group w_50">
                                <label>Vehicle Reg</label>
                                <div
                                    onClick={() => {
                                        setVehicleModal(true)
                                    }}
                                    className="upload_input">
                                    <TextField

                                        variant="outlined"
                                        placeholder="Attach link"
                                        fullWidth
                                        hiddenLabel
                                    // disabled
                                    // type="file"
                                    />
                                    <p>Edit Vehicle Reg<img src="/static/images/paperClip_icon.svg" alt="Icon" /></p>
                                </div>
                            </div>

                        }

                        {
                            travelType.some(data => data > 1) &&
                            <div className="control_group w_50">
                                <label>Ticket</label>
                                <div

                                    onClick={() => {
                                        setTicketModal(true)
                                    }}
                                    className="upload_input">
                                    <TextField

                                        variant="outlined"
                                        placeholder="Attach link"
                                        fullWidth
                                        hiddenLabel
                                    // disabled
                                    />
                                    <p>Edit Ticket<img src="/static/images/paperClip_icon.svg" alt="Icon" /></p>
                                </div>
                            </div>


                        }



                        {travelList?.map((data: any, index: number) => {

                            return <>
                                <div className="control_group w_50">
                                    <label>Mobile ({index + 1})</label>
                                    {/* <TextField
                                    variant="outlined"
                                    placeholder="Enter here"
                                    fullWidth
                                    hiddenLabel
                                    value={data?.phoneToDisplay}
                                    onChange={(e:any)=>setTravelList((prevData:any)=>{
                                     return   prevData?.map((data:any,index2:number)=>{
                                            if(index2==index)
                                            {
                                                return {...data,phoneToDisplay:e.target.value}
                                            }
                                                return data;
                                        })
                                    })}
                                /> */}
                                    <PhoneInput
                                        specialLabel=""
                                        country={"us"}
                                        placeholder="0 (000) 000-000"
                                        enableSearch={true}
                                        inputStyle={{ width: "100%" }}
                                        value={phoneToDisplayData[index]}
                                        // onChange={(value) => formik.setFieldValue("phone", value)}
                                        onChange={(phone: any, country: any) =>
                                            handleChangePhoneData(phone, country, index)
                                        }
                                    />
                                </div>

                                <div className="control_group w_50">
                                    <label>Destination Address ({index + 1})</label>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter here"
                                        fullWidth
                                        hiddenLabel
                                        value={data?.destinationAddress}
                                        onChange={(e: any) => setTravelList((prevData: any) => {
                                            return prevData?.map((data: any, index2: number) => {
                                                if (index == index2) {
                                                    return { ...data, destinationAddress: e.target.value }
                                                }
                                                return data;
                                            })
                                        })}
                                    />
                                </div>
                                <div className="control_group w_50">
                                    <label>Destination/Arrival City ({index + 1})</label>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter here"
                                        fullWidth
                                        hiddenLabel
                                        value={data?.destinationCity}
                                        onChange={(e: any) => setTravelList((prevData: any) => {
                                            return prevData?.map((data: any, index2: number) => {
                                                if (index == index2) {
                                                    return { ...data, destinationCity: e.target.value }
                                                }
                                                return data;
                                            })
                                        })}
                                    />
                                </div>
                                <div className="control_group w_50">
                                    <label>Arrival Date ({index + 1})</label>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter here"
                                        fullWidth
                                        hiddenLabel
                                        type="date"
                                        value={data?.destinationDate}
                                        onChange={(e: any) => setTravelList((prevData: any) => {
                                            return prevData?.map((data: any, index2: number) => {
                                                if (index == index2) {
                                                    return { ...data, destinationDate: e.target.value }
                                                }
                                                return data;
                                            })
                                        })}
                                    />
                                </div>
                                <div className="control_group w_50">
                                    <label>Available weights ({index + 1})</label>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter here"
                                        fullWidth
                                        hiddenLabel
                                        value={data?.availableWeight}
                                        onChange={(e: any) => setTravelList((prevData: any) => {
                                            return prevData?.map((data: any, index2: number) => {
                                                if (index == index2) {
                                                    return { ...data, availableWeight: e.target.value }
                                                }
                                                return data;
                                            })
                                        })}
                                    />
                                </div>
                                <div className="control_group w_50">
                                    <label>Way of Travel ({index + 1})</label>
                                    <Select
                                        fullWidth
                                        labelId="wayOfTravel"
                                        id="wayOfTravel"
                                        name="travelType"
                                        value={travelType[index]}
                                        onChange={(e) => {
                                            setTravelType((prev: any) => prev.map((travelData: number, travelIndex: number) => index == travelIndex ? e.target.value : travelData))
                                        }}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem disabled value="default">Select</MenuItem>
                                        <MenuItem value={1}>Private Vehicle</MenuItem>
                                        <MenuItem value={2}>Airline</MenuItem>
                                        <MenuItem value={3}>Voyage</MenuItem>
                                        <MenuItem value={4}>Railway</MenuItem>
                                        <MenuItem value={5}>Public Road</MenuItem>
                                    </Select>
                                </div>
                            </>

                        })

                        }
                    </div>
                    <div className="form_btn end">
                        <Button className="btn btn_secondary"
                            type="submit"
                        // onClick={()=>navigate(state?state:"/profile-detail/"+id)}
                        >Save Changes</Button>
                    </div>
                </form>
            </div>
            <PassportModal open={open} onClose={handleClose} setOpen={setOpen} idData={idData} />
            <EditTicketModal open={ticketModal} setOpen={setTicketModal} onClose={closeTicketModal} ticket={ticket} wayOfTravel={getTravelTypeName(formik.values.travelType)} setTicket={setTicket} />
            {/* <EditPassportModal open={open} setOpen={setOpen} onClose={handleClose} idData={idData} setIdData={setIdData}/> */}
            <EditVehicleDetailModal open={vehicleModal} setOpen={setVehicleModal} onClose={closeVehicleModal}
                vehicleData={vehicleData}
                setVehicleData={setVehicleData}
            />
        </>
    );
};

export default EditProfile;
