import { Modal} from '@mui/material'
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Slider from 'react-slick';

interface VehicleRegistrationModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    vehicleData?:{
        vehicleReg?:string|null,
        side?:string|null,
        front?:string|null,
        back?:string|null,
        ticket?:string|null,
        wayOfTravel?:string|null,
    }
}
var settings = {
    dots:true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
  };

export default function VehicleRegistrationModal({ open, onClose, setOpen ,vehicleData }: VehicleRegistrationModalProps) {
    console.log("TESTTEST",vehicleData);
    return (
        <Modal
            className="modal vehicleRegistration_modal"
            id="vehicleRegistrationModal"
            aria-labelledby="vehicleRegistration-modal-title"
            aria-describedby="vehicleRegistration-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>{vehicleData?.ticket?.length?"Travel using ticket "
                        // +(vehicleData?.wayOfTravel)
                        :"Travel by private vehicle"}</h2>
                       { vehicleData?.ticket?<p> Travelers Ticket
                            {/* <span>registration124.png</span> */}
                            </p>:<p>Vehicle Registration
                            {/* <span>registration124.png</span> */}
                            </p>}
                    </div>
                   {!vehicleData?.ticket?.length && <div className="vehicle_img gap_m">
                        <figure style={{width:"155px",height:"155px"}}>
                            <img style={{width:"100%",height:"100%"}} src={vehicleData?.front?vehicleData?.front:"/static/images/vehicle_front.jpg"} alt="Front" />
                            <figcaption>Front</figcaption>
                        </figure>
                        <figure style={{width:"155px",height:"155px"}}>
                            <img style={{width:"100%",height:"100%"}} src={vehicleData?.side?vehicleData?.side:"static/images/vehicle_side.jpg"} alt="Side" />
                            <figcaption>Side</figcaption>
                        </figure>
                        <figure style={{width:"155px",height:"155px"}}>
                            <img style={{width:"100%",height:"100%"}} src={vehicleData?.back?vehicleData?.back:"static/images/vehicle_back.jpg"} alt="Back" />
                            <figcaption>Back</figcaption>
                        </figure>
                    </div>}
                  {!vehicleData?.ticket?.length &&  <figure className="registration_img" style={{width:"505px",height:"236px"}}>
                        <img style={{width:"100%",height:"100%"}}src={vehicleData?.vehicleReg?vehicleData?.vehicleReg:"/static/images/vehicleRegistration_attachment.jpg"} alt="Registration" />
                    </figure>}
                    {vehicleData?.ticket?.length &&  
                    
                    
                  (  Array.isArray(vehicleData?.ticket)?
                  
                  
                  <div style={{width:"320px",marginInline:"18%"}}>
                                 <Slider {...settings}
                                        // centerMode={true}   
                                             className='slider-2000'
                                        >
                                       {vehicleData?.ticket?.map((data:any)=>{

                                          return <div>
                                                <figure style={{width:"100%",height:"320px"}}><img  width="100%" src={data?data:"/static/images/fc_image1.jpg"} alt='fcImage' /></figure>
                                            </div>
                                            

                                       }) 
                                        }
                                 </Slider>
                   </div>
                  :
                    <figure className="registration_img">
                        <img src={vehicleData?.ticket?vehicleData?.ticket:"/static/images/vehicleRegistration_attachment.jpg"} alt="Registration" />
                    </figure>)
                    
                    
                    
                    }
                    <div className="footer_btn_flex">
                        <Button className="btn btn_secondary" onClick={() => setOpen(false)}>Okay</Button>
                        <Button className="btn btn_highlight" onClick={()=>{
                            const temp:any=vehicleData;
                            for(let key in temp)
                                {
                                    if(temp[key])
                                        {   if(key=='wayOfTravel')
                                            {
                                            continue;
                                            }
                                            if(key=='ticket' && Array.isArray(temp[key]))
                                            {
                                                continue
                                            }
                                            window.open(temp[key])
                                        }
                                }
                                if(Array.isArray(vehicleData?.ticket))
                                    {
                                        for(let key in vehicleData?.ticket)
                                            {
                                                if(vehicleData?.ticket[key])
                                                    {
                                                        window.open(vehicleData?.ticket[key])
                                                    }
                                            }
                                    }
                        }}>Download</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
