import React, { useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Button, setRef } from '@mui/material'
import { showError, showToast } from '../constants/toasts';
import { generateEncryptedKeyBody } from '../utils/crypto';
import { CommonBody } from '../types/General';

interface AddFaqModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    addFaq:any;
    getFaqList:any;
}

export default function AddFaq({ open, onClose, setOpen,addFaq,getFaqList }: AddFaqModalProps) {

const [question,setQuestion]=useState("");
const [answer,setAnswer]=useState("");
const[error,setError]=useState(false);


const addFaqData=async()=>{
    const body={
        question:question,
        answer:answer
    }
    const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
 try{   const response=await addFaq(encryptedBody).unwrap();
    if(response?.statusCode==200)
        {
            showToast("FAQ added successfully")
            getFaqList();
        }
        }
        catch(error:any)
        {
            showError(error?.data?.message);
        }
    }
const handleSubmit=async(e:any)=>{
    e.preventDefault();
    setError(true); 
    if(question==""){ 
        // showError("Question is required");
        return;}
    if(answer==""){
        // showError("Answer is required")
        return;}
        await addFaqData();
        setError(false);
        setAnswer("");
        setQuestion("");
    setOpen(false);

}
    return (
        <Modal
            className="modal addNotification_modal"
            id="addFaqModal"
            aria-labelledby="addFaq-modal-title"
            aria-describedby="addFaq-modal-description"
            open={open}
            onClose={()=>{

                onClose();
                setError(false);

            }
        }
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Add FAQ</h2>
                    </div>
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Question"
                                fullWidth
                                value={question}
                                onChange={(e)=>setQuestion(e.target.value)}
                                hiddenLabel
                            />
                             { error && question==""? (
                        <h6 className="err_msg">Question is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Answer"
                                fullWidth
                                hiddenLabel
                                multiline
                                value={answer}
                                onChange={(e)=>setAnswer(e.target.value)}
                                maxRows={5}
                                minRows={5}
                            />
                              { error && answer==""? (
                        <h6 className="err_msg">Answer is required</h6>
                      ) : (
                        ""
                      )}
                      
                        </div>
                        <div className="footer_btn_flex">
                            <Button className="btn btn_highlight" onClick={() => {
                                setError(false);
                                setOpen(false)}}>Cancel</Button>
                            <Button className="btn btn_secondary" type='submit'>Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal >
    )
}
