import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

export const exportToPdf = (rows:any,columns:any) => {
    const doc = new jsPDF({
      orientation: "landscape",
    })
    doc.text("Gina Admin", 20, 10)
    autoTable(doc,{
      theme: "grid",
      columns: columns.map((col:any) => ({ ...col,title:col?.headerName,dataKey: col?.field })),
      body: rows
    })
    doc.save('Gina Admin.pdf')
  }