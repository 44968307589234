/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, SetStateAction, useState } from "react";
import SearchBar from "../../components/SearchBar";
import {
    Button,
    Box,
    Menu,
    MenuItem
} from "@mui/material";
import AddUser from "../../Modals/addUser";
import { showError } from "../../constants/toasts";
import { exportToExcel } from "../../utils/exportToExcel";
import { exportToPdf } from "../../utils/exportToPdf";
import { ADMIN_ROLE_ID } from "../../constants/role";
import useAuth from "../../hooks/useAuth";
interface userFilter{
searchValue:string,
setSearchValue:Dispatch<SetStateAction<string>>,
roles:any,
getSubAdminsList:any,
setType:any,
gridApi:any,
selectedRow:any,
}

const UserListFilter = ({searchValue,setSearchValue,roles,getSubAdminsList,setType,gridApi,selectedRow}:userFilter) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm,setDebouncedSearchTerm]=useState("");
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const open2 = Boolean(anchorEl2);
    const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
const user=useAuth();
    return (
        <>
            <div className="table_header">
                <div className="left_s">
                <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setSearchTerm}
                placeholder="Search Users"
                 value={searchTerm}
                 onCross={() => {
                 setSearchTerm("");
                 setSearchValue("")
                }}
                 onChange={(e:any)=>{
                    setSearchTerm(e.target.value)
                    setSearchValue(e.target.value)}

                 }
              />
                </div>
                <div className="right_s">
                    <Box>
                        <Button
                            className="btn btn_highlight"
                            aria-controls={open1 ? "basic-menu1" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? "true" : undefined}
                            onClick={handleClick1}>
                            <img src="/static/images/filter_icon.svg" alt="Icon" /> Filter
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu1"
                            anchorEl={anchorEl1}
                            open={open1}
                            onClose={handleClose1}
                            MenuListProps={{
                                "aria-labelledby": "basic-button2",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem className="export_btns v2">
                                 <Button onClick={()=>{
                                    setType(-1);
                                    setAnchorEl1(null);
                                }}><span className="round c_black"></span> All Status</Button>
                                   
                                    <Button onClick={()=>{
                                        setType(1);
                                        setAnchorEl1(null);
                                    }}><span className="round c_grey"></span> Pending</Button>
                                
                                <Button onClick={()=>{
                                    setType(2);
                                    setAnchorEl1(null);
                                }}><span className="round c_success"></span> Enabled</Button>
                                

                                <Button onClick={()=>{
                                    setType(3);
                                    setAnchorEl1(null);
                                }}><span className="round c_warning"></span> Disabled</Button>
                                <Button onClick={()=>{
                                    setType(4);
                                    setAnchorEl1(null);
                                }}><span className="round c_danger"></span> Blocked</Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <Button
                            className="btn btn_highlight"
                            aria-controls={open2 ? "basic-menu2" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? "true" : undefined}
                            onClick={handleClick2}
                        >
                            <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu2"
                            anchorEl={anchorEl2}
                            open={open2}
                            onClose={handleClose2}
                            MenuListProps={{
                                "aria-labelledby": "basic-button2",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem className="export_btns v2">
                            <Button onClick={()=>{selectedRow?.row?.length ? exportToPdf(selectedRow?.row,selectedRow?.column):showError("Please select atleast one row")}}>PDF</Button>
                <Button onClick={()=>{selectedRow?.row?.length ? gridApi.current.exportDataAsCsv():showError("Please select atleast one row")}}>CSV</Button>
                <Button onClick={()=>{selectedRow?.row?.length ? exportToExcel(selectedRow?.row,selectedRow?.column):showError("Please select atleast one row")}}>Excel</Button>
                                {/* <Button>Zip</Button>
                                <Button>Text</Button> */}
                            </MenuItem>
                        </Menu>
                    </Box>
                    {(user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isEdit)?<Button className="btn btn_secondary" onClick={() => setOpenModal(true)}>Add User</Button>:null}
                </div>
            </div>

            <AddUser open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} roles={roles} getSubAdminsList={getSubAdminsList}/>
        </>
    );
};

export default UserListFilter;
