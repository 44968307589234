import { Box, IconButton } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ReportRightCards = () => {

    return (
        <>
            <div className="cards earned_card">
                <h2>
                    <strong>Total money earned by travelers</strong>
                    <span>$200K</span>
                </h2>
                <ul>
                    <li>
                        <span><img src="/static/images/airplane_icon.svg" alt="icon" /> Earned by Air</span>
                        <strong>$100K</strong>
                    </li>
                    <li>
                        <span><img src="/static/images/bus_icon.svg" alt="icon" /> Earned by Train</span>
                        <strong>$360K</strong>
                    </li>
                    <li>
                        <span><img src="/static/images/ship2_icon.svg" alt="icon" /> Earned by Sea</span>
                        <strong>$9650K</strong>
                    </li>
                    <li>
                        <span><img src="/static/images/people_icon.svg" alt="icon" /> Earned by public vehicles </span>
                        <strong>$870K</strong>
                    </li>
                    <li>
                        <span><img src="/static/images/profile2_icon.svg" alt="icon" /> Earned by private transport</span>
                        <strong>$530K</strong>
                    </li>
                </ul>
            </div>

            <div className="cards doughnut_card">
                <ul>
                    <li>
                        Total Travelers: <strong>6200</strong>
                    </li>
                    <li>
                        Total Volunteers: <strong>120</strong>
                    </li>
                </ul>

                <figure><img src="/static/images/doughnut_chart.png" alt="" /></figure>
            </div>

            <div className="cards country_card">
                <div className="d_flex">
                    <Box>
                        <h2>Country Export/Import</h2>
                        <p>Lorem ipsum is a dummy text</p>
                    </Box>
                    <Box>
                        <IconButton><img src="/static/images/more_icon.svg" alt="Icon" /></IconButton>
                    </Box>
                </div>
                <ul>
                    <li>
                        <figure><img src="/static/images/canada_flag.png" alt="Flag" /></figure>
                        <h2>Canada <span>151585 Orders</span></h2>
                        <p className="c_danger"><ArrowDownwardIcon /> 2582 Kg</p>
                        <p className="c_success"><ArrowUpwardIcon /> 3602 Kg</p>
                    </li>
                    <li>
                        <figure><img src="/static/images/canada_flag.png" alt="Flag" /></figure>
                        <h2>Canada <span>151585 Orders</span></h2>
                        <p className="c_danger"><ArrowDownwardIcon /> 2582 Kg</p>
                        <p className="c_success"><ArrowUpwardIcon /> 3602 Kg</p>
                    </li>
                    <li>
                        <figure><img src="/static/images/canada_flag.png" alt="Flag" /></figure>
                        <h2>Canada <span>151585 Orders</span></h2>
                        <p className="c_danger"><ArrowDownwardIcon /> 2582 Kg</p>
                        <p className="c_success"><ArrowUpwardIcon /> 3602 Kg</p>
                    </li>
                    <li>
                        <figure><img src="/static/images/canada_flag.png" alt="Flag" /></figure>
                        <h2>Canada <span>151585 Orders</span></h2>
                        <p className="c_danger"><ArrowDownwardIcon /> 2582 Kg</p>
                        <p className="c_success"><ArrowUpwardIcon /> 3602 Kg</p>
                    </li>
                    <li>
                        <figure><img src="/static/images/canada_flag.png" alt="Flag" /></figure>
                        <h2>Canada <span>151585 Orders</span></h2>
                        <p className="c_danger"><ArrowDownwardIcon /> 2582 Kg</p>
                        <p className="c_success"><ArrowUpwardIcon /> 3602 Kg</p>
                    </li>
                    <li>
                        <figure><img src="/static/images/canada_flag.png" alt="Flag" /></figure>
                        <h2>Canada <span>151585 Orders</span></h2>
                        <p className="c_danger"><ArrowDownwardIcon /> 2582 Kg</p>
                        <p className="c_success"><ArrowUpwardIcon /> 3602 Kg</p>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default ReportRightCards;
