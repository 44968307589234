import * as React from 'react';
import { Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
interface FreightCustomerModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>
    shipmentDetails?:any;
}
var settings = {
    dots:true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // initialSlide: 0,
  };
export default function FreightCustomerDetail({ open, onClose, setOpen,shipmentDetails }: FreightCustomerModalProps) {
    console.log(shipmentDetails);
    
    const getLuggageName=(luggageType:number)=>{
        const CARGO_TYPE :any= {
            1: 'ENVELOPE',
            2: 'CARTON',
            3: 'SUITCASE',
            4: 'BOX',
            5: 'BARREL',
            6: 'OTHER'
        };
        
  return CARGO_TYPE[luggageType];
}
const[imageArray,setImageArray]=React.useState([]);
React.useEffect(()=>{
let temp:any=[];
if(shipmentDetails)
shipmentDetails?.packageDetail?.map((data:any)=>{
temp.push(data?.side);
temp.push(data?.photo);
temp.push(data?.front);
temp?.push(data?.back)
})
setImageArray(temp);

},[open])
    return (
        <Modal
            className="modal fcDetail_modal"
            id="fcDetailModal"
            aria-labelledby="fcDetail-modal-title"
            aria-describedby="fcDetail-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Detail of Freight Customers</h2>
                    </div>
                    <div className="fcustomer_info gap_m">
                        <div className="left_s">
                            <h2>Shipping Pictures</h2>
                            <ul className="fcImg_list gap_p" style={{justifyContent:"center"}}>
                   <div style={{width:"320px"}}>
                                 <Slider {...settings}
                                        // centerMode={true}   
                                             className='slider-2000'
                                        >
                                       {imageArray?.map((data:any)=>{

                                          return <div>
                                                <figure style={{width:"100%",height:"80px"}}><img  width="100%" src={data?data:"/static/images/fc_image1.jpg"} alt='fcImage' /></figure>
                                            </div>
                                            

                                       }) 
                                        }
                                 </Slider>
                   </div>
                            </ul>
                        </div>
                        <div className="right_s">
                            <div className="fcComment_box">
                                <h2>Freight Customer Comment</h2>
                                <ul className="fcComment_list">
                                    <li>
                                        <figure>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>
                                            <b>Richard Anderson</b>
                                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.</span>
                                        </p>
                                    </li>
                                    <li>
                                        <figure>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>
                                            <b>Richard Anderson</b>
                                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table className="v2" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Baggage Type</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Shipping Cost</TableCell>
                                    <TableCell>Delivery Home</TableCell>
                                    <TableCell>Total Weight</TableCell>
                                    <TableCell>Packaging service</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                               {Array.isArray(shipmentDetails?.packageDetail) && shipmentDetails?.packageDetail?.map((data:any,index:number)=>{
                              return  <TableRow>
                                    <TableCell>
                                        <figure className="icon_text">
                                            <img src="/static/images/briefcase_icon.svg" alt="Icon" />
                                            <figcaption>{getLuggageName(data?.cargoType)}</figcaption>
                                        </figure>
                                    </TableCell>
                                    <TableCell>{data?.quantity}</TableCell>
                                    <TableCell>$40</TableCell>
                                    <TableCell>{shipmentDetails?.[index]?.isHome?"Yes":"No"}</TableCell>
                                    <TableCell>{data?.totalWeight} Kg</TableCell>
                                    <TableCell>{data?.packagingServiceNeeded?"Yes":"No"}</TableCell>
                                </TableRow>

                                })
                             
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Modal>
    )
}
