import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import LookingForTravellersFilter from "../../features/lookingForTravellers/TravellersFilters";
import LookingForTravellersList from "../../features/lookingForTravellers/TravellersLists";
import { type } from "os";
import { useEffect, useState } from "react";
import { showError } from "../../constants/toasts";
import { useGridApiRef } from "@mui/x-data-grid";
import { useLazyGetTravelerQuery } from "../../services/travelers";
import { SelectChangeEvent } from "@mui/material";

const LookingForTravellers = () => {
    const[arrivalCities,setArrivalCities]=useState<any>([]);
  const[isLoading,setIsLoading]=useState(false);

    const[departureCities,setDepartureCities]=useState<any>([]);
    const[selectedArrivalCities,setSelectedArrivalCities]=useState<any>([]);
    const[selectedDepartureCities,setSelectedDepartureCities]=useState<any>([]);
    console.log("GINA",selectedArrivalCities,selectedDepartureCities)
    const[getAllData]=useLazyGetTravelerQuery();
    const[travelers,setTravelers]=useState<any>([]);
  const gridApi=useGridApiRef();
    const navigate = useNavigate();
    const getAllDataList=async()=>{
        try{ 
         setIsLoading(true);
         const response=await getAllData(
           {
             page: 1,
             type:2,
             query: "",
           }).unwrap();
           if(response?.statusCode==200)
             { 
               
               console.log("TESSTTTTTTTTTT",response?.data);
               setTravelers(response?.data);
                let departureCities=[];
                for(let key of response?.data)
                {
                  let flag=0;
                  for(let key2 of departureCities)
                  {
                    if(key2.name?.toLowerCase()?.trim()==key?.currentAddress?.city?.toLowerCase()?.trim() && key2.country?.toLowerCase()?.trim()==key?.currentAddress?.district?.toLowerCase()?.trim())
                    {
                      flag=1;
                    }
                  }
                  if(flag==0)
                  departureCities.push({name:key?.currentAddress?.city?.toLowerCase()?.trim(),country:key?.currentAddress?.district?.toLowerCase()?.trim(),isSelected:false});
                }
                console.log("TESTER MOHIT",departureCities)
                
                
                let arrivalCities=[];
                for(let key of response?.data)
                {
                  let flag=0;
                  for(let key2 of arrivalCities)
                  {
                    if(key2.name?.toLowerCase()?.trim()==key?.destinationAddress?.[key?.destinationAddress?.length-1]?.city?.toLowerCase()?.trim() && key2.country?.toLowerCase()?.trim()==key?.destinationAddress?.[key?.destinationAddress?.length-1]?.district?.toLowerCase()?.trim())
                    {
                      flag=1;
                    }
                  }
                  if(flag==0)
                  arrivalCities.push({name:key?.destinationAddress?.[key?.destinationAddress?.length-1]?.city?.toLowerCase()?.trim(),country:key?.destinationAddress?.[key?.destinationAddress?.length-1]?.district?.toLowerCase()?.trim(),isSelected:false})
                }
                console.log(arrivalCities)
                setArrivalCities(arrivalCities);
                setDepartureCities(departureCities)
              }
             }
         catch(error:any)
           {
             console.log("ERRROR",error)
             showError(error?.data?.message);
           }
           finally{
             setIsLoading(false);
           }
           }
           useEffect(()=>{
            getAllDataList();
           },[])
           const [filterData,setFilterData]=useState<any>({
            countryFrom:"",
            countryTo:"",
            baggage:-1,
            category:-1
           })
           function getFilteredTravelers(data:any){
            let tempData=data;
            if(filterData?.countryFrom!="")
            {
              tempData=tempData?.filter((item:any)=>(item?.currentAddress?.city?.trim()?.toLowerCase()==filterData?.countryFrom.slice(0,filterData?.countryFrom?.indexOf(','))?.trim()?.toLowerCase()
                  &&(item?.currentAddress?.district?.trim()?.toLowerCase()==filterData?.countryFrom.slice(filterData?.countryFrom?.indexOf(',')+2,filterData?.countryFrom?.length)?.trim()?.toLowerCase())))
            }
            if(filterData?.countryTo!="")
            {
              tempData=tempData?.filter((item:any)=>(item?.destinationAddress?.[item?.destinationAddress?.length-1]?.city?.trim()?.toLowerCase()==filterData?.countryTo.slice(0,filterData?.countryTo?.indexOf(','))?.trim()?.toLowerCase()
              &&(item?.destinationAddress?.[item?.destinationAddress?.length-1]?.district?.trim()?.toLowerCase()==filterData?.countryTo.slice(filterData?.countryTo?.indexOf(',')+2,filterData?.countryTo?.length)?.trim()?.toLowerCase())))
            }
            if(filterData?.baggage!=-1)
            {
              tempData=tempData.filter((item:any)=>item?.cargoType==filterData?.baggage);
            }
           if(filterData?.category!=-1)
           {
            tempData=tempData.filter((item:any)=>item?.category==filterData?.category);

           } 
           return tempData;
          }
          
           console.log(filterData);
    return (
        <>
            <div className="main_title">
                <h1>Looking for travellers</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Looking for travellers
                </p>
            </div>

            <LookingForTravellersFilter 
            setSelectedDepartureCities={setSelectedDepartureCities} 
            setSelectedArrivalCities={setSelectedArrivalCities}
            setArrivalCities={setArrivalCities} 
            setDepartureCities={setDepartureCities}    
            arrivalCities={arrivalCities} 
            departureCities={departureCities} 
            setFilterData={setFilterData}
            />

            <div className="cards">
                <LookingForTravellersList travelers={getFilteredTravelers(travelers)}/>
            </div>
        </>
    );
};

export default LookingForTravellers;

