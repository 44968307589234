import { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import Sidebar from "./SideBar";
import { SIDEBAR_WIDTH } from "../constants";
import "./Layout.scss";
import Topbar from "./Topbar";
import { Outlet, useNavigate } from "react-router-dom";
import { getFromStorage, STORAGE_KEYS } from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { setCredentials } from "../reducers/authSlice";
import { useLazyGetUserQuery } from "../services/auth";
import useAuth from "../hooks/useAuth";

function MainContainer() {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isLoading,setIsLoading]=useState(false)
  const [getUser] = useLazyGetUserQuery();
  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const user=useAuth();
  const getUserDetails = async () => {
    setIsLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    console.log("TOKKEN",token);
    if (token) {
      try {
        const result = await getUser({}).unwrap();
        if (result?.statusCode === 200 && token) {
          dispatch(
            setCredentials({
              user: result?.data,
              token: token,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    setIsLoading(false);

    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      getUserDetails();
    }
  }, []);
  return (
    <>
      <Topbar handleSidebarToggle={handleSidebarToggle} />
      <Sidebar
        mobileOpen={mobileOpen}
        handleSidebarToggle={handleSidebarToggle}
      />
      <Box
        className="main_content"
        component="main"
        sx={{
          marginLeft: "auto",
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
        }}
      >
        <Container className="sub-layout" sx={{ paddingTop: 13 }}>
          <Outlet/>
        </Container>
      </Box>
    </>
  );
}
export default MainContainer;
