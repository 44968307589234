import { useState } from "react";
import SearchBar from "../../components/SearchBar";

const WorldMap = () => {

    const [searchTerm, setSearchTerm] = useState("");

    return (
        <>
            <div className="cards worldMap_card">
                <div className="head_flex">
                    <div className="left">
                        <h2>World Map</h2>
                        <p>Choose a country to see there report</p>
                    </div>
                    <SearchBar
                        searchTerm={searchTerm}
                        setDebouncedSearchTerm={setSearchTerm}
                        placeholder="Search..."
                    />
                </div>

                <figure><img src="/static/images/world-map.png" alt="" /></figure>
            </div>
        </>
    );
};

export default WorldMap;
