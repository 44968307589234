import { Modal, Button } from '@mui/material'
import { Dispatch, SetStateAction } from 'react';

interface DeleteReviewModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>
    deleteData:any
}

export default function DeleteReview({ open, onClose, setOpen,deleteData }: DeleteReviewModalProps) {

    return (
        <Modal
            className="modal deleteReview_modal"
            id="deleteReviewModal"
            aria-labelledby="deleteReview-modal-title"
            aria-describedby="deleteReview-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="modal_title">
                        <h2>Delete Review</h2>
                        <p>Are you sure you want to delete this review</p>
                    </div>
                    <div className="footer_btn_flex">
                        <Button className="btn btn_white br" onClick={() => {
                            setOpen(false)}}>Cancel</Button>
                        <Button className="btn btn_secondary" onClick={() => {
                            deleteData();
                            setOpen(false)}}>Confirm</Button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
