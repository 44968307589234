export const ORDER_ACCEPT_STATUS:Record<number,string>= {
   1:"SHIPMENT_SUBMITTED", // shipment submitted by customer
   2:"OFFER_SUBMITTED", // offer submitted by traveller
   3:"OFFER_ACCEPTED", // offer accepted by customer
   4:"CANCELLED"
 }
  export const ORDER_TRACKING_STATUS:Record<number,string> = {
   1:"NEW",
   2:"SHIPMENT_FORWARDED",
   3:"ADVANCE_PAID",
   4:"SHIPMENT_RECEIVED",
   5:"ADVANCE_RECEIVED",
   6:"ON_THE_WAY",
   7:"DEPARTURE",
   8:"DELAY_DEPARTURE",
   9:"RETURNED",
   10:"ARRIVED",
   11:"DELAY_DELIVERY",
   12:"COMPLETED",
   13:"CANCELLED_TRAVELLER",
   14:"CANCELLED_SHIPMENT",
   15:"CLOSED",
   16:"EXTENDED",
 }
