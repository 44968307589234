import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

const Chat = () => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    return (
        <>
            <>
                <div className="main_title">
                    <h1>Private Chat</h1>
                    <p>
                        <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                        Chat
                    </p>
                </div>

                <div className="chat_grid gap_m">
                    <div className="left_s">
                        <div className="cards">
                            <SearchBar
                                searchTerm={searchTerm}
                                setDebouncedSearchTerm={setSearchTerm}
                                placeholder="Search by username or email"
                            />
                            <div className="userChat_list">
                                <div className="single">
                                    <figure><img src="/static/images/user_attachmen2.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <span className="count">2</span>
                                        <h2><b>Emma smith</b> <span>20 hrs</span></h2>
                                        <p>You are getting late..</p>
                                    </div>
                                </div>
                                <div className="single">
                                    <figure><img src="/static/images/user_attachment.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <span className="count">2</span>
                                        <h2><b>Max smith</b> <span>20 hrs</span></h2>
                                        <p>Plz come fast.</p>
                                    </div>
                                </div>
                                <div className="single">
                                    <figure><img src="/static/images/user_attachmen2.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <span className="count">2</span>
                                        <h2><b>Sean Bean</b> <span>20 hrs</span></h2>
                                        <p>Hello! How are u?</p>
                                    </div>
                                </div>
                                <div className="single">
                                    <figure><img src="/static/images/user_attachment.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <span className="count">2</span>
                                        <h2><b>Brian Cox</b> <span>20 hrs</span></h2>
                                        <p>Can we talk later?</p>
                                    </div>
                                </div>
                                <div className="single">
                                    <figure><img src="/static/images/user_attachmen2.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <span className="count">2</span>
                                        <h2><b>Michle Jordan</b> <span>20 hrs</span></h2>
                                        <p>I wiil call you back..</p>
                                    </div>
                                </div>
                                <div className="single">
                                    <figure><img src="/static/images/user_attachment.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <span className="count">2</span>
                                        <h2><b>Alex Morde</b> <span>20 hrs</span></h2>
                                        <p>Hello where are you?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right_s">
                        <div className="cards chat_card">
                            <div className="chat_head">
                                <div className="left">
                                    <figure><img src="/static/images/user_attachmen2.jpg" alt="User" /></figure>
                                    <div className="info">
                                        <h2>Brian Cox</h2>
                                        <p>Active Now <span className="active"></span></p>
                                    </div>
                                </div>
                                <div className="right">
                                    <Box>
                                        <IconButton
                                            aria-controls={open1 ? "basic-menu1" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open1 ? "true" : undefined}
                                            onClick={handleClick1}
                                        >
                                            <img src="/static/images/more2_icon.svg" alt="" />
                                        </IconButton>
                                        <Menu
                                            className="setting_menu v2"
                                            id="basic-menu1"
                                            anchorEl={anchorEl1}
                                            open={open1}
                                            onClose={handleClose1}
                                            MenuListProps={{
                                                "aria-labelledby": "basic-button1",
                                            }}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: "visible",
                                                    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "8px",
                                                    mt: 1,
                                                },
                                            }}
                                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                        >
                                            <MenuItem>Enable</MenuItem>
                                            <MenuItem>Disable</MenuItem>
                                            <MenuItem>Block</MenuItem>
                                        </Menu>
                                    </Box>
                                </div>
                            </div>
                            <div className="chat_body">
                                <div className="single_message sender">
                                    <div className="msg_head">
                                        <figure><img src="/static/images/user_attachmen2.jpg" alt="" /></figure>
                                        <h2>Brian Cox <span>1 min</span></h2>
                                    </div>
                                    <div className="msg_body">
                                        <p>How likely are you to recommend our company to your friends and family ?</p>
                                    </div>
                                </div>
                                <div className="single_message reciever">
                                    <div className="msg_head">
                                        <figure><img src="/static/images/profile_placeholder.jpg" alt="" /></figure>
                                        <h2>You <span>1 min</span></h2>
                                    </div>
                                    <div className="msg_body">
                                        <p>Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.</p>
                                    </div>
                                </div>
                                <div className="single_message sender">
                                    <div className="msg_head">
                                        <figure><img src="/static/images/user_attachmen2.jpg" alt="" /></figure>
                                        <h2>Brian Cox <span>1 min</span></h2>
                                    </div>
                                    <div className="msg_body">
                                        <p>Ok, Understood!</p>
                                    </div>
                                </div>
                                <div className="single_message reciever">
                                    <div className="msg_head">
                                        <figure><img src="/static/images/profile_placeholder.jpg" alt="" /></figure>
                                        <h2>You <span>1 min</span></h2>
                                    </div>
                                    <div className="msg_body">
                                        <p>You’ll receive notifications for all issues, pull requests!</p>
                                    </div>
                                </div>
                                <div className="typing">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div className="chat_foot">
                                <figure><img src="/static/images/profile_placeholder.jpg" alt="User" /></figure>
                                <TextField
                                    variant="outlined"
                                    placeholder="Your Message....."
                                    fullWidth
                                    hiddenLabel
                                />
                                <div className="more_items">
                                    <IconButton>
                                        <img src="/static/images/emoji_icon.svg" alt="" />
                                    </IconButton>
                                    <IconButton>
                                        <img src="/static/images/gif_icon.svg" alt="" />
                                    </IconButton>
                                    <IconButton>
                                        <img src="/static/images/voice_icon.svg" alt="" />
                                    </IconButton>
                                    <IconButton className="addIcon">
                                        <AddIcon />
                                    </IconButton>
                                </div>
                                <Button className="chat_submit"><img src="/static/images/send_icon.svg" alt="" /></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default Chat;
