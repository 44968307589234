/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, Box, Menu, MenuItem, IconButton } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';

const FeedbackReview = () => {

  const navigate = useNavigate();

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  return (
    <>
      <div className="cards table_card">
        <div className="cards_header">
          <div className="left_s">
            <h2>Store Feedback and Review</h2>
            <p>More than 400 feedbacks</p>
          </div>
          <div className="right_s">
            <p>
              <span>Month</span>
              <span>Week</span>
              <span>Today</span>
            </p>
            <Button className="btn btn_highlight"><img src="/static/images/upload_icon.svg" alt="Icon" /> Export</Button>
          </div>
        </div>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="user_block">
                    <figure onClick={() => navigate("/profile-detail")}><img src="/static/images/user_attachment.jpg" alt="" /></figure>
                    <p><b>Emma Somith</b> GN12xxxxxxx</p>
                  </div>
                </TableCell>
                <TableCell>+12 1234 58688</TableCell>
                <TableCell className="max_c">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p></TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
                <TableCell>
                  <button className="status_btn c_warning">Pending</button>
                </TableCell>
                <TableCell>
                  <div className="table_actions v3">
                    <Box>
                      <IconButton
                        aria-controls={open1 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick1}
                      >
                        <img src="/static/images/setting_icon.svg" alt="Icon" />
                      </IconButton>
                      <Menu
                        className="setting_menu"
                        id="basic-menu"
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                            borderRadius: "8px",
                            mt: 1,
                          },
                        }}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                      >
                        <MenuItem>Approve</MenuItem>
                        <MenuItem>Deny</MenuItem>
                      </Menu>
                    </Box>
                    <Box>
                      <IconButton>
                        <img src="/static/images/write_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton>
                        <img src="/static/images/trash_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="user_block">
                    <figure onClick={() => navigate("/profile-detail")}><img src="/static/images/user_attachment.jpg" alt="" /></figure>
                    <p><b>Alex Fuiso</b> GN12xxxxxxx</p>
                  </div>
                </TableCell>
                <TableCell>+12 1234 58688</TableCell>
                <TableCell className="max_c">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p></TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
                <TableCell>
                  <button className="status_btn c_warning">Pending</button>
                </TableCell>
                <TableCell>
                  <div className="table_actions v3">
                    <Box>
                      <IconButton
                        aria-controls={open2 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? "true" : undefined}
                        onClick={handleClick2}
                      >
                        <img src="/static/images/setting_icon.svg" alt="Icon" />
                      </IconButton>
                      <Menu
                        className="setting_menu"
                        id="basic-menu"
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                            borderRadius: "8px",
                            mt: 1,
                          },
                        }}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                      >
                        <MenuItem>Approve</MenuItem>
                        <MenuItem>Deny</MenuItem>
                      </Menu>
                    </Box>
                    <Box>
                      <IconButton>
                        <img src="/static/images/write_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton>
                        <img src="/static/images/trash_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="user_block">
                    <figure onClick={() => navigate("/profile-detail")}><img src="/static/images/user_attachment.jpg" alt="" /></figure>
                    <p><b>Ali Hakim</b> GN12xxxxxxx</p>
                  </div>
                </TableCell>
                <TableCell>+12 1234 58688</TableCell>
                <TableCell className="max_c">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p></TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
                <TableCell>
                  <button className="status_btn c_success">Approved</button>
                </TableCell>
                <TableCell>
                  <div className="table_actions v3">
                    <Box>
                      <IconButton
                        aria-controls={open3 ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open3 ? "true" : undefined}
                        onClick={handleClick3}
                      >
                        <img src="/static/images/setting_icon.svg" alt="Icon" />
                      </IconButton>
                      <Menu
                        className="setting_menu"
                        id="basic-menu"
                        anchorEl={anchorEl3}
                        open={open3}
                        onClose={handleClose3}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                            borderRadius: "8px",
                            mt: 1,
                          },
                        }}
                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                      >
                        <MenuItem>Approve</MenuItem>
                        <MenuItem>Deny</MenuItem>
                      </Menu>
                    </Box>
                    <Box>
                      <IconButton>
                        <img src="/static/images/write_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton>
                        <img src="/static/images/trash_icon.svg" alt="Icon" />
                      </IconButton>
                    </Box>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="text_center">
          <a href="javascript:void(0)">Show More</a>
        </div>
      </div>
    </>
  );
};

export default FeedbackReview;
