/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const LookingForTravellersFilter = ({setSelectedDepartureCities,setSelectedArrivalCities,arrivalCities,departureCities,setFilterData}:any) => {

    const [selectField1, setSelectField1] = React.useState("Select Country From");
    console.log(selectField1);

    const [selectField2, setSelectField2] = React.useState("Select Country To");
    console.log(selectField2);

    const [selectField3, setSelectField3] = React.useState<any>(-1);
    const handleChange3 = (event: SelectChangeEvent) => {
        setSelectField3(event.target.value);
    };

    const [selectField4, setSelectField4] = React.useState<any>(-1);
    const handleChange4 = (event: SelectChangeEvent) => {
        setSelectField4(event.target.value);
    };

    const countryFrom = ['Ottawa, Canada', 'Riyadh, Saudi Arab', 'Canberra, Australia', 'Lahore, Pakistan'];
    const countryTo = ['Ottawa, Canada', 'Riyadh, Saudi Arab', 'Canberra, Australia', 'Lahore, Pakistan'];

    return (
        <>
            <div className="table_header select_filter">
                <div className="d_flex">
                    <Autocomplete
                        disablePortal
                        id="countryFrom"
                        options={departureCities?.map((data:any)=>data?.name+", "+data?.country)}
                        inputValue={selectField1}
                        onInputChange={(event, newInputValue) => {
                         setSelectField1(newInputValue);
                            }}
                        renderInput={(params) => <TextField {...params}
                        placeholder="Select Country From" />}
                    />
                    <Autocomplete
                        disablePortal
                        id="countryFrom"
                        options={arrivalCities?.map((data:any)=>data?.name+", "+data?.country)}
                        inputValue={selectField2}
                        onInputChange={(event, newInputValue) => {
                         setSelectField2(newInputValue);
                            }}
                        renderInput={(params) => <TextField {...params} placeholder="Select Country To" />}
                    />
                    <Select
                        fullWidth
                        labelId="baggage_type"
                        id="baggage_type"
                        value={selectField3}
                        onChange={handleChange3}
                    >
                        <MenuItem disabled value={-1}>Type of Baggage</MenuItem>
                        <MenuItem value={1}>Envelop</MenuItem>
                        <MenuItem value={2}>Cartoon</MenuItem>
                        <MenuItem value={3}>Suitcase</MenuItem>
                        <MenuItem value={4}>Box</MenuItem>
                        <MenuItem value={5}>Barrel</MenuItem>
                        <MenuItem value={6}>Toys, Furniture, Tools & Others</MenuItem>
                    </Select>
                    <Select
                        fullWidth
                        labelId="travel_category"
                        id="travel_category"
                        value={selectField4}
                        onChange={handleChange4}
                    >
                        <MenuItem disabled value={-1}>Category</MenuItem>
                        <MenuItem value={1}>Pharmaceutical & Health care products and books</MenuItem>
                        <MenuItem value={2}>Beauty & Perfumes</MenuItem>
                        <MenuItem value={3}>Cloths</MenuItem>
                        <MenuItem value={4}>Foodstuf</MenuItem>
                        <MenuItem value={5}>Mobile, Tablet, Laptop and accessories</MenuItem>
                        <MenuItem value={6}>Home tools, Furniture and toys</MenuItem>
                        <MenuItem value={7}>Documents and printed matter</MenuItem>
                        <MenuItem value={8}>other</MenuItem>
                    </Select>
                </div>
                <Button className="btn btn_primary" 
                style={{minWidth:"100px",backgroundColor:"white",color:"black",fontSize:"12px",padding:'0'}}
                onClick={()=>{
                    setSelectField4(-1);
                    setSelectField3(-1);
                    setFilterData({
                        countryFrom:"",
                        countryTo:"",
                        baggage:-1,
                        category:-1
                       });
                }}
                >Clear Filter</Button>

                <Button className="btn btn_secondary"
                
                onClick={()=>{
                    setFilterData({
                        countryFrom:selectField1,
                        countryTo:selectField2,
                        baggage:selectField3,
                        category:selectField4
                    })
                }}
                >Search</Button>
            </div>
        </>
    );
};

export default LookingForTravellersFilter;
