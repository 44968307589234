import React from "react";
import "./Auth.scss";
import { Outlet } from "react-router-dom";

const AuthLayout = ({ children }: any) => {
  return (
    <div className="authContainer">
      <div className="container-auth">
        <div className="authpage">
          <div className="authLeft">
            <figure>
              <img src="/static/images/authLogo.png" alt="" />
            </figure>
          </div>
          <div className="authRight"><Outlet/></div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
