const ReportStats = () => {

    return (
        <>
            <div className="reportStats_boxes gap_m">
                <div className="cards orderCard">
                    <h2>21,152,000</h2>
                    <p>Total No. of orders (Globally)</p>
                    <figure><img src="/static/images/order_graph.svg" alt="Graph" /></figure>
                </div>
                <div className="cards presentationCard">
                    <h2>YTD Presentation</h2>
                    <p>Lorem ipsum is a dummy text</p>
                    <ul className="presentation_list gap_p">
                        <li>
                            <figure>
                                <img src="/static/images/complete_orders.svg" alt="Icon" />
                            </figure>
                            <p><b>Completed orders</b> <span>21,152,000</span></p>
                        </li>
                        <li>
                            <figure>
                                <img src="/static/images/delayed_orders.svg" alt="Icon" />
                            </figure>
                            <p><b>Delayed orders</b> <span>21,152,000</span></p>
                        </li>
                        <li>
                            <figure>
                                <img src="/static/images/shipped_weight.svg" alt="Icon" />
                            </figure>
                            <p><b>Total Shipped Weights</b> <span>125423 KG</span></p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ReportStats;
