import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button } from '@mui/material';

const OrderList = () => {

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            <div className="custom_accordion">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        Shipping NO  XXX XXX 000100
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer className="table_container">
                            <Box className="heading"></Box>
                            <Table className="v2" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>From</TableCell>
                                        <TableCell>To</TableCell>
                                        <TableCell>Luggage type</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Total Weight (Kgs)</TableCell>
                                        <TableCell>Earned amount</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>1</TableCell>
                                        <TableCell>Riyadh</TableCell>
                                        <TableCell>New York</TableCell>
                                        <TableCell>suite case</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>$40</TableCell>
                                        <TableCell>
                                            <Button className="btn_text c_success">Approved</Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>2</TableCell>
                                        <TableCell>Riyadh</TableCell>
                                        <TableCell>New York</TableCell>
                                        <TableCell>suite case</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>$40</TableCell>
                                        <TableCell>
                                            <Button className="btn_text c_success">Approved</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        Shipping NO  XXX XXX 000100
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer className="table_container">
                            <Box className="heading"></Box>
                            <Table className="v2" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>From</TableCell>
                                        <TableCell>To</TableCell>
                                        <TableCell>Luggage type</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Total Weight (Kgs)</TableCell>
                                        <TableCell>Earned amount</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>1</TableCell>
                                        <TableCell>Riyadh</TableCell>
                                        <TableCell>New York</TableCell>
                                        <TableCell>suite case</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>$40</TableCell>
                                        <TableCell>
                                            <Button className="btn_text c_success">Approved</Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>2</TableCell>
                                        <TableCell>Riyadh</TableCell>
                                        <TableCell>New York</TableCell>
                                        <TableCell>suite case</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>$40</TableCell>
                                        <TableCell>
                                            <Button className="btn_text c_success">Approved</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        Shipping NO  XXX XXX 000100
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer className="table_container">
                            <Box className="heading"></Box>
                            <Table className="v2" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>From</TableCell>
                                        <TableCell>To</TableCell>
                                        <TableCell>Luggage type</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Total Weight (Kgs)</TableCell>
                                        <TableCell>Earned amount</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>1</TableCell>
                                        <TableCell>Riyadh</TableCell>
                                        <TableCell>New York</TableCell>
                                        <TableCell>suite case</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>$40</TableCell>
                                        <TableCell>
                                            <Button className="btn_text c_success">Approved</Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>2</TableCell>
                                        <TableCell>Riyadh</TableCell>
                                        <TableCell>New York</TableCell>
                                        <TableCell>suite case</TableCell>
                                        <TableCell>2</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>$40</TableCell>
                                        <TableCell>
                                            <Button className="btn_text c_success">Approved</Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

export default OrderList;
