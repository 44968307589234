export const getLuggageName=(luggageType:number)=>{
    const CARGO_TYPE :Record<number,string>= {
        1: 'ENVELOPE',
        2: 'CARTON',
        3: 'SUITCASE',
        4: 'BOX',
        5: 'BARREL',
        6: 'OTHER'
    };
    return CARGO_TYPE[luggageType];
    }