import { Star } from '@mui/icons-material';
import { SelectChangeEvent, TextField, Select, MenuItem, Button, Rating, TextareaAutosize } from '@mui/material';
import React, { FormEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { EditText2 } from '../../components/EditText2';
import "./review.css";
import { useAddReviewMutation } from '../../services/review';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { CommonBody } from '../../types/General';
import { showError, showToast } from '../../constants/toasts';
import { isValidEmail } from '../../utils/validations';
const ReviewLink = () => {
 const[description,setDescription]=useState("");
  const [value, setValue] = React.useState<number | null>(2.5);
  const[reviewSubmit,setReviewSubmit]=useState(false);
const[userData,setUserData]=useState({
    name:"",
    email:""
})
const{id}=useParams();
    const[addReview]=useAddReviewMutation();
    const addReviewData=async()=>{
        const body={
            name: userData?.name,
            email: userData?.email,
            rating: value,
            description: description
        }
       try{ const encryptedData=generateEncryptedKeyBody(body) as CommonBody
        const response=await addReview({id:id,body:encryptedData}).unwrap();
        if(response.statusCode==200)
            {
                showToast("Review sent Successfully");
                setReviewSubmit(true)
            }}
            catch(error:any)
            {
                showError(error?.data?.message);
            }
    }
const handleSubmit=(e:FormEvent<HTMLFormElement>)=>{
e.preventDefault();
if(userData?.email.trim()=="")
{
showError("Email is required")
return;
}
if(!isValidEmail(userData?.email))
{
        showError("Invalid email address")
        return
}
if(userData?.name.trim()=="")
{
showError("Name is required");
return;
}
if(description.trim()=="")
    {
        showError("Description is required")
        return;
    }
addReviewData();
}
  return (
      <>
          <div className="main_title" style={{marginTop:"-20px"}}>
              <h1 style={{color:"white",textAlign:"center",fontSize:"40px",textTransform:"uppercase"}}>Rate the user</h1>
          </div>

          <div className="cards">
            { reviewSubmit?<>
            
         <div style={{height:"400px",textAlign:"center",
          justifyContent:"center",display:"flex", padding:"100px"
         }}>
                <div className="card2">
    <div className="content2">
      <svg
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 9V5H4V9H20ZM20 11H4V19H20V11ZM3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM5 12H8V17H5V12ZM5 6H7V8H5V6ZM9 6H11V8H9V6Z"
        ></path>
      </svg>
      <p className="para">
        Thankyou for your valuable feedback 
      </p>
    </div>
  </div>
         </div>

            </>: <form className="form" style={{padding:"20px"}} onSubmit={handleSubmit}>
                  <div className="gap_p">
                      <div className="control_group w_100">
                          <label>Email</label>
                          <TextField
                              variant="outlined"
                              placeholder="Enter Email"
                              fullWidth
                              hiddenLabel
                              value={userData?.email}
                              onChange={(e)=>{

                                setUserData(prev=>({...prev,email:e.target.value}))
                              }}
                          />
                      </div>
                      <div className="control_group w_50">
                          <label>Name</label>
                          <TextField
                              variant="outlined"
                              placeholder="Enter name"
                              fullWidth
                              hiddenLabel
                              value={userData?.name}
                              onChange={(e)=>{

                                setUserData(prev=>({...prev,name:e.target.value}))
                              }}
                          />
                      </div>
                      <div className="control_group w_50">
                          <label>Rating</label>
                          <div className="control_group">
                            <Rating
                                name="size-large"
                                value={value}
                                className='svg_rate'
                                precision={0.5}
                                
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                size="large"
                                // onChangeActive={(event, newHover) => {
                                //     setHover(newHover);
                                // }}
                                emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
                            />
                        </div>
                      </div>
                      <div className="control_group w_100" style={{height:"200px"}}>
                          <label>Description</label>
                          {/* <TextField
                              variant="outlined"
                              placeholder="Enter here"
                              fullWidth
                              hiddenLabel
                          /> */}
      {/* {<EditText2
       content={description}
       setContent={setDescription}
      />} */}
       <TextField
                                variant="outlined"
                                placeholder="Description"
                                fullWidth
                                hiddenLabel
                                multiline
                                value={description}
                                onChange={(e)=>setDescription(e.target.value)}
                                maxRows={5}
                                minRows={5}
                            />
                      </div>
                      
                     
                  </div>
                  <div className="form_btn end">
                      <Button className="btn btn_secondary" type='submit'>Submit Review</Button>
                  </div>
              </form>}
          </div>
      </>
  );
};


export default ReviewLink