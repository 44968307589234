import { Modal } from '@mui/material'
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

interface PassportModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    idData?:{
        idCard:string,
        residenceCard:string
      };
}

export default function PassportModal({ open, onClose, setOpen,idData}: PassportModalProps) {

    return (
        <Modal
            className="modal passport_modal"
            id="passportModal"
            aria-labelledby="passport-modal-title"
            aria-describedby="passport-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Passport / ID</h2>
                        <div className="btn_flex">
                            <Button className="btn btn_highlight">
                                <img src="/static/images/import_icon.svg" alt="Icon" /> QR code
                            </Button>
                            {/* <Button className="btn btn_highlight">
                                <img src="/static/images/import_icon.svg" alt="Icon" /> Finger Print
                            </Button> */}
                        </div>
                    </div>
                    <div className="main_title" style={{textAlign:"center"}}>
                        <p>
                        Id Card
                        </p>
                    </div>
                    <figure className="passport_img"  style={{marginTop:"-20px"}}>
                        <img src={idData?.idCard?idData?.idCard:"/static/images/passport_attachment.jpg"} alt="Passport" />
                    </figure>
                    <div className="main_title" style={{textAlign:"center",marginTop:"20px"}}>
                        <p>
                        Residence Card
                        </p>
                    </div>
                    <figure className="passport_img" style={{marginTop:"-20px"}}>
                        <img src={idData?.residenceCard?idData?.residenceCard:"/static/images/passport_attachment.jpg"} alt="Passport" />
                    </figure>
                    <div className="footer_btn_flex">
                        <Button className="btn btn_secondary" onClick={() => setOpen(false)}>Okay</Button>
                        <Button className="btn btn_highlight" onClick={()=>{
                             const temp:any=idData;
                             for(let key in temp)
                                {   
                                    if(temp[key])
                                        {   console.log(temp[key])
                                            window.open(temp[key])
                                        }
                                }
                        }}>Download</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
