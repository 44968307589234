/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import TableComponent from "../../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import GiveFeedback1 from "../../../Modals/giveFeedback1";
import GiveFeedback2 from "../../../Modals/giveFeedback2";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import { Feedback, ShipmentFeedback } from "../../../types/shipmentFeedback";
import { showError } from "../../../constants/toasts";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const NotYetList = ({
    columnVisibilityModel,
    feedback,
    setSelectedRow,
    gridApi,
    getDataList,
}: {
    columnVisibilityModel: any;
    feedback: any;
    setSelectedRow: any;
    gridApi: any;
    getDataList: any;
}) => {
    const navigate = useNavigate();

    const value1 = 0;

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const [openModal2, setOpenModal2] = useState(false);
    const handleCloseModal2 = () => {
        setOpenModal2(false);
    };

    const rows: any = [
        // {
        //     id: 1,
        //     shipments: "xxx 1263-64512",
        //     destinationCity: "Istanbul",
        //     currentCity: "Canberra",
        // },
        // {
        //     id: 2,
        //     shipments: "xxx 1263-64512",
        //     destinationCity: "Istanbul",
        //     currentCity: "Canberra",
        // },
        // {
        //     id: 3,
        //     shipments: "xxx 1263-64512",
        //     destinationCity: "Istanbul",
        //     currentCity: "Canberra",
        // },
        // {
        //     id: 4,
        //     shipments: "xxx 1263-64512",
        //     destinationCity: "Istanbul",
        //     currentCity: "Canberra",
        // },
        // {
        //     id: 5,
        //     shipments: "xxx 1263-64512",
        //     destinationCity: "Istanbul",
        //     currentCity: "Canberra",
        // },
    ];
    feedback.forEach((element: ShipmentFeedback) => {
        rows.push({
            id: element?._id,
            freightCustomer: element?.customer?.fullName ? element?.customer?.fullName : "-",
            freightCustomerDetail: element?.customer,
            traveler: element?.traveller?.fullName ? element?.traveller?.fullName : "-",
            travelerDetail: element?.traveller,
            beneficiary: element?.beneficiaryDetail?.name ? element?.beneficiaryDetail?.name : "-",
            beneficiaryDetail: element?.beneficiaryDetail,
            shipment: element?.orderId,
            currentCity: element?.beneficiaryDetail?.customerCurrentCity,
            destinationCity: element?.beneficiaryDetail?.city,
            feedback: element.feedback,
            status: element?.feedback?.length < 3 ? "Partly" : "Fully"
        })
    });
    const user = useAuth();
    const columns: GridColDef<(typeof rows)[number]>[] = [
        {
            flex: 1,
            minWidth: 200,
            field: "freightCustomer",
            headerName: "Freight Customers",
            editable: false,
            renderCell: (data: any) => {
                return (
                    <div className="user_block">
                        <figure onClick={() =>
                            ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.freightCustomerDetail?._id) : null)

                        }>
                            <img src={data?.row?.freightCustomerDetail?.personVerification?.profileImage || "/static/images/user-placeholder.jpg"} alt="" />
                        </figure>
                        <p className="flexD cursor_p">
                            <b onClick={() => {
                                showError("No Feedback from freight customer")
                                return
                            }
                                // ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId) : null)
                            }>{`${data?.row?.freightCustomerDetail?.firstName} ${data?.row?.freightCustomerDetail?.lastName}` || "Michael Johnson"}</b>
                            <a onClick={() => {
                                showError("No Feedback from freight customer")
                            }}><Rating name="read-only" value={0} readOnly precision={0.5} emptyIcon={<StarIcon />} /></a>
                        </p>
                    </div>
                );
            },
        },
        {
            flex: 1,
            minWidth: 180,
            field: "traveler",
            headerName: "Travelers",
            editable: false,
            renderCell: (data: any) => {
                return (
                    <div className="user_block">
                        <figure onClick={() =>
                            ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.travelerDetail?._id) : null)
                        }>
                            <img src={data?.row?.travelerDetail?.personVerification?.profileImage || "/static/images/user-placeholder.jpg"} alt="" />
                        </figure>
                        <p className="flexD cursor_p">
                            <b onClick={() => {
                                    showError("No Feedback from traveller")
                                    return
                            }
                                // ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId) : null)
                            }>{`${data?.row?.travelerDetail?.firstName} ${data?.row?.travelerDetail?.lastName}` || "Emma smith"}</b>
                            <a onClick={() => {
                                    showError("No Feedback from traveller")
                                    return
                            }}><Rating name="read-only" value={ 0} readOnly precision={0.5} emptyIcon={<StarIcon />} /></a>
                        </p>
                    </div>
                );
            },
        },
        {
            flex: 1,
            minWidth: 180,
            field: "beneficiary",
            headerName: "Beneficiary",
            editable: false,
            renderCell: (data: any) => {
                return (
                    <div className="user_block" style={{ position: "relative" }}>
                        <figure onClick={() =>
                            ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? (data?.row?.beneficiaryDetail?.userId ? navigate("/profile-detail/" + data?.row?.beneficiaryDetail?.userId) : null) : null)
                        }>
                            {!data?.row?.beneficiaryDetail?.userId && <IconButton style={{ position: "absolute", bottom: -15, left: 15 }}><Cancel fontSize="small" color="error" /></IconButton>}

                            <img src={data?.row?.beneficiaryDetail?.image || "/static/images/user-placeholder.jpg"} alt="" />
                        </figure>
                        <p className="flexD cursor_p">
                            <b onClick={() => {
                                    showError("No Feedback from beneficiary")
                                    return
                            }
                                // ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId) : null)
                            }>{data?.row?.beneficiaryDetail?.name || "Usama Ali"}</b>
                            <a onClick={() => {
                                    showError("No Feedback from beneficiary")
                                    return
                            }}><Rating name="read-only" value={0} readOnly precision={0.5} emptyIcon={<StarIcon />} /></a>
                        </p>
                    </div>
                );
            },
        },
        
        {
            flex: 1,
            minWidth: 120,
            field: "shipment",
            headerName: "Shipment No",
            editable: false,
            sortable: false,
        },
        {
            flex: 1,
            minWidth: 150,
            field: "destinationCity",
            headerName: "Destination City",
            editable: false,
        },
        {
            flex: 1,
            minWidth: 120,
            field: "currentCity",
            headerName: "Current city",
            editable: false,
        },
    ];

    let hidden = ["fullName"];

    return (
        <>
            <div className="sorting_table">
                <TableComponent
                    columns={columns}
                    rows={rows}
                    gridApi={gridApi}
                    setSelectedRow={setSelectedRow}
                    columnVisibilityModel={columnVisibilityModel}
                />
            </div>
            {/* 
            <GiveFeedback1 open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} />
            <GiveFeedback2 open={openModal2} onClose={handleCloseModal2} setOpen={setOpenModal2} /> */}
        </>
    );
};

export default NotYetList;
