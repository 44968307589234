import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layout/authLayout";
import Input from "../../components/Input";
import Button from "../../components/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import { showToast, showError } from "../../constants/toasts";
import { setToStorage, STORAGE_KEYS, removeFromStorage, getFromStorage } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { usePostLogInMutation } from "../../services/auth";
import { useAppDispatch } from "../../hooks/store";
const Login = () => {
  const navigate = useNavigate();
  const dispatch :any= useAppDispatch();
  const[isLoading,setIsLoading]=useState(false);
  const[loginMutation]=usePostLogInMutation();
  const formik=useFormik({
    enableReinitialize:true,
    initialValues:{
      email:"",
      password:"",
      remember:false,
    },
    validationSchema:Yup.object({
      email:Yup.string().required("Email is required").matches(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        "Enter a valid email address!"
      ),
      password:Yup.string().required("Password is required")
    }),
    onSubmit:async ()=>{
    formik.setSubmitting(true);

    let body = {
      key: formik.values.email,
      password: formik.values.password,
      deviceType:"WEB",
      // deviceToken:deviceToken
      // appKey: new Date().toISOString(),
    };
    console.log(body,"login_body");

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      const response = await loginMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        showToast("Logged In Successfully."||response?.message);
        setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
        dispatch(
          setCredentials({
            user: response?.data,
            token: response?.data?.token || "",
          })
        );

        if (formik.values.remember) {
          setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
        } else {
          removeFromStorage(STORAGE_KEYS.credentials);
        }

        navigate("/dashboard");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
    formik.setSubmitting(false);
    }
  })
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="authBox">
        <h2>Sign In</h2>
        <p className="Gina">Welcome to GinaEx</p>
        <form onSubmit={formik.handleSubmit}>
          <Input 
          placeholder="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.touched.email && formik.errors.email}
          />
          <Input 
          placeholder="Password"
          name="password"
          password={true}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.touched.password && formik.errors.password}

          />
          <div className="form_control d_flex">
            <div className="remember_box">
              <FormControlLabel
                control={<Checkbox />}
                checked={formik.values.remember}
                name="remember"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Remember me"
              />
            </div>
            <a
              href="javacript:void(0)"
              className="anchor_link"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </a>
          </div>
          <Button value="Sign In"
          onClick={formik.handleSubmit}
          // onClick={() => navigate("/dashboard")} 
          />
        </form>
      </div>
    </>
  );
};

export default Login;
