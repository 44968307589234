import BeneficiaryFilter from "./beneficiary/beneficiaryFilter";
import GoogleRating from "./dashboard/GoogleRating";
import CustomersQuestionnaires from "./dashboard/customersQuestionnaires";
import SelectDuration from "./dashboard/duraction";
import FeedbackReview from "./dashboard/feedbackReview";
import TravelersQuestionnaire from "./dashboard/travelersQuestionnaire";
import BronzeList from "./membershipLevel/bronzeList";
import ReportFilter from "./report/reportFilter";
import ReportStats from "./report/reportStats";
import CancelRefundList from "./reportAnIssue/cancelRefund";
import CancelledRequestList from "./reportAnIssue/cancelledRequest";
import RemoveItemList from "./reportAnIssue/removeItem";
import ReportIssueList from "./reportAnIssue/reportIssueList";
import FeedbackFilter from "./shipmentTracks/feedback/feedbackFilter";
import AcceptedOrdersList from "./shipmentTracks/newOrders/acceptedOrdersList";
import CancelledFlightsList from "./shipmentTracks/passengerFlight/cancelledFlightList";
import DelayedFlightsList from "./shipmentTracks/passengerFlight/delayedFlightList";
import OnTimeFlightsList from "./shipmentTracks/passengerFlight/onTimeFlightsList";
import PassengerFlightFilter from "./shipmentTracks/passengerFlight/passengerFlightFilter";
import ApprovedTravelersFilter from "./travelers/approvedTravelersFilter";
import ApprovedTravelersList from "./travelers/approvedTravelersList";
import NewTravelersFilter from "./travelers/newTravelersFilter";
import NewTravelersList from "./travelers/newTravelersList";
import RejectedTravelersFilter from "./travelers/rejectedTravelersFilter";
import RejectedTravelersList from "./travelers/rejectedTravelersList";
import ProfileTop from "./ProfileDetail/profileTop";
import PersonalInformation from "./ProfileDetail/personalInformation";
import TravelList from "./ProfileDetail/travelList";
import OrderList from "./ProfileDetail/orderList";
import InvitedPersons from "./ProfileDetail/invitedPersons";
import ProfileReviews from "./ProfileDetail/reviews";
import ReportRightCards from "./report/reportRightCards";
import WorldMap from "./report/worldMap";
import WeightsBreakdown from "./report/weightsBreakdown";
import ReviewLink from "./reviewLinkPage";

export default {
  GoogleRating,
  SelectDuration,
  FeedbackReview,
  TravelersQuestionnaire,
  CustomersQuestionnaires,
  NewTravelersList,
  NewTravelersFilter,
  ApprovedTravelersList,
  RejectedTravelersList,
  ApprovedTravelersFilter,
  RejectedTravelersFilter,
  BeneficiaryFilter,
  AcceptedOrdersList,
  BronzeList,
  CancelledRequestList,
  CancelRefundList,
  RemoveItemList,
  ReportIssueList,
  ReportFilter,
  ReportStats,
  PassengerFlightFilter,
  OnTimeFlightsList,
  DelayedFlightsList,
  CancelledFlightsList,
  FeedbackFilter,
  ProfileTop,
  PersonalInformation,
  TravelList,
  OrderList,
  InvitedPersons,
  ProfileReviews,
  ReportRightCards,
  WorldMap,
  WeightsBreakdown,
  ReviewLink
};
