/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent";
import PassportModal from "../../Modals/passportModal";
import { IconButton, Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { showToast, showError } from "../../constants/toasts";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useEditTravelerByIdMutation } from "../../services/travelers";
import VehicleRegistrationModal from "../../Modals/vehicleRegistration";
import RejectRevert from "../../Modals/rejectRevert";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";

type props = {
  hidden: string[];
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  columnVisibilityModel: any;
  travelers: any;
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
  setTotalDataCount: any;
};

const NewTravelersList = ({
  hidden,
  openModal,
  setOpenModal,
  columnVisibilityModel,
  travelers,
  setSelectedRow,
  gridApi,
  getDataList,
  setTotalDataCount,
}: props) => {
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [openVehicleModal, setOpenVehicelModal] = useState(false);
  const handleVehicleModalClose = () => {
    setOpenVehicelModal(false);
  };
  const [selectedId, setSelectedId] = useState("");
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const handleRejectModalClose = () => {
    setOpenRejectModal(false);
  };

  const [idData, setIdData] = useState({
    idCard: "",
    residenceCard: "",
  });
  const [vehicleData, setVehicleData] = useState({
    vehicleReg: "",
    side: "",
    front: "",
    back: "",
    ticket: "",
    wayOfTravel: "",
  });
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const navigate = useNavigate();
  const getResidentTypeName = (residenceType: number) => {
    if (residenceType == 1) {
      return "CITIZEN";
    } else if (residenceType == 2) {
      return "RESIDENT";
    } else if (residenceType == 3) {
      return "VISITOR";
    } else {
      return "";
    }
  };
  const getTravelTypeName = (travelType: number) => {
    if (travelType == 1) {
      return "Private Vehicle";
    } else if (travelType == 2) {
      return "Airlines";
    } else if (travelType == 3) {
      return "Voyage";
    } else if (travelType == 4) {
      return "Railway";
    } else if (travelType == 5) {
      return "Public Road";
    } else {
      return "";
    }
  };
  const [updateData] = useEditTravelerByIdMutation();
  const [reason, setReason] = useState("");
  const handleStatusChange = async (id: any, adminStatus: any) => {
    try {
      const body =
        adminStatus == 3
          ? {
              cancelReason: reason,
              adminStatus: adminStatus,
            }
          : {
              adminStatus: adminStatus,
            };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Traveler status updated successfully" || response?.message);
      getDataList();
      setAnchorEl1(null);
      setTotalDataCount((prevData: any) => {
        if (adminStatus == 2)
          return {
            ...prevData,
            type1: prevData.type1 - 1,
            type2: prevData.type2 + 1,
          };
        if (adminStatus == 3)
          return {
            ...prevData,
            type1: prevData.type1 - 1,
            type3: prevData.type3 + 1,
          };
        return prevData;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };

  const ActionCell = ({ data }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const travelerId = data?.row?.id;

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 2)}>
              Approve
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedId(data?.row?.id);
                setOpenRejectModal(true);
              }}
            >
              Reject
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate("/edit-profile-detail/" + data?.row?.userId, {
                  state: "/registrations/travelers",
                })
              }
            >
              Edit
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  };

  const rows: any = [];
  travelers?.map((data: any) => {
    rows.push({
      id: data?._id,
      currentCity: data?.currentAddress?.city,
      name: data?.userDetails?.[0]?.fullName,
      mobile:
        data?.userDetails?.[0]?.countryCode + data?.userDetails?.[0]?.phone,
      departureCityDate:
        data?.travelDetail?.[0]?.departingFrom +
        " /" +
        data?.travelDetail?.[0]?.departureDate?.slice(0, 10),
      arrivalCityDate:
        data?.travelDetail?.[data?.travelDetail?.length - 1]?.destination +
        "/" +
        data?.travelDetail?.[
          data?.travelDetail?.length - 1
        ]?.arrivalDate?.slice(0, 10),
      email: data?.userDetails?.[0]?.email,

      image: data?.userDetails?.[0]?.personVerification?.profileImage,
      travelNo: data?.travelDetail?.length,
      TR: "TR" + data?.travelId,
      residencyType: data?.personVerification?.residenceType
        ? getResidentTypeName(data?.personVerification?.residenceType)
        : "-",
      idCard: data?.personVerification?.idCard,
      residenceCard: data?.personVerification?.residenceCard,
      vehicleDetail: data?.vehicleDetail,
      ticket: data?.ticket,
      userId: data?.userDetails?.[0]?._id,
      wayOfTravel: getTravelTypeName(data?.travelType),
      travelType: data?.travelType,
    });
  });
  const user = useAuth();
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 140,
      field: "TR",
      headerName: "TR",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 210,
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.userId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <Tooltip
              title={
                <p style={{ color: "white" }}>
                  {data?.row?.name}
                  <br />
                  {data?.row?.mobile}
                  <br />
                  {data?.row?.email}
                </p>
              }
            >
              <p className="cursor_p">
                <b>{data?.row?.name}</b>
                {data?.row?.mobile}
                <br />
                {data?.row?.email}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 110,
      field: "passportId",
      headerName: "Passport ID",
      editable: false,
      sortable: false,
      disableExport: true,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => {
        return (
          <IconButton
            className="text_icon"
            onClick={() => {
              setIdData({
                idCard: data?.row?.idCard,
                residenceCard: data?.row?.residenceCard,
              });
              setOpenModal(true);
            }}
          >
            <img src="/static/images/external_icon.svg" alt="" />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 130,
      field: "residencyType",
      headerName: "Residency Type",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 170,
      field: "departureCityDate",
      headerName: "Departure City/Date",
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 160,
      field: "arrivalCityDate",
      headerName: "Arrival City/Date",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      flex: 1,
      minWidth: 120,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 130,
      field: "email",
      headerName: "Email Address",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   flex: 1,
    //   minWidth: 130,
    //   field: "direction",
    //   headerName: "Direction",
    //   editable: false,
    //   sortable: false,
    // },
    {
      flex: 1,
      minWidth: 150,
      field: "currentCity",
      headerName: "Current City",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 160,
      field: "ticketVehicleReg",
      headerName: "Ticket/Vehicle Reg",
      editable: false,
      disableExport: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => {
        return (
          <IconButton
            className="text_icon"
            onClick={() => {
              setVehicleData({
                ...data?.row?.vehicleDetail,
                ticket: data?.row?.ticket,
                wayOfTravel: data?.row?.wayOfTravel,
              });
              if (data?.row?.travelType) setOpenVehicelModal(true);
            }}
          >
            <img src="/static/images/external_icon.svg" alt="" />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 130,
      field: "travelNo",
      headerName: "# of travel",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 80,
      disableExport: true,
      field: "action",
      headerName: "Action",
      editable: false,
      sortable: false,
      renderCell: (data) => <ActionCell data={data} />,
    },
  ];
  // useEffect(()=>{
  //         if(rows){
  //           setSelectedRow((prev:any) => ({
  //             ...prev,
  //             row: rows
  //           }))
  //         }
  //        },[travelers])
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columnVisibilityModel={columnVisibilityModel}
          columns={columns?.filter((item: any) => !hidden.includes(item.field))}
          rows={rows}
        />
      </div>

      <PassportModal
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        idData={idData}
      />
      <VehicleRegistrationModal
        open={openVehicleModal}
        onClose={handleVehicleModalClose}
        setOpen={setOpenVehicelModal}
        vehicleData={vehicleData}
      />
      <RejectRevert
        open={openRejectModal}
        onClose={handleRejectModalClose}
        setOpen={setOpenRejectModal}
        handleStatusChange={() => handleStatusChange(selectedId, 3)}
        reason={reason}
        setReason={setReason}
      />
    </>
  );
};

export default NewTravelersList;
