/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid"; 
import ComplaintDetail from "../../Modals/complaintDetail";
import { useNavigate } from "react-router-dom";
import ViewRemark from "../../Modals/remark";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";

const CancelRefundList = () => {
    
    const navigate = useNavigate();

    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    
    const [openModal2, setOpenModal2] = useState(false);
    const handleCloseModal2 = () => {
        setOpenModal2(false);
    };

    const rows = [
        {
            id: 1,
            date: "12 jan 2024",
            shippingNo: "323523423",
            closedBy: "shahzad M.",
        },
        {
            id: 2,
            date: "12 jan 2024",
            shippingNo: "323523423",
            closedBy: "shahzad M.",
        },
        {
            id: 3,
            date: "12 jan 2024",
            shippingNo: "323523423",
            closedBy: "shahzad M.",
        },
        {
            id: 4,
            date: "12 jan 2024",
            shippingNo: "323523423",
            closedBy: "shahzad M.",
        },
        {
            id: 5,
            date: "12 jan 2024",
            shippingNo: "323523423",
            closedBy: "shahzad M.",
        },
    ];
    const user=useAuth();

    const columns: GridColDef<(typeof rows)[number]>[] = [
        {
            flex: 1,
            minWidth: 200,
            field: "customerName",
            headerName: "Customer name",
            editable: false,
            renderCell: (data:any) => {
                return (
                    <div className="user_block">
                        <figure onClick={() => 
                          ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)? navigate("/profile-detail/"+data?.row?.userId) : null)

                            }>
                            <img src="/static/images/user_attachment.jpg" alt="" />
                        </figure>
                        <p>
                            <b>Brandon King</b> +923456789
                        </p>
                    </div>
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "date",
            headerName: "date",
            editable: false,
        },
        {
            flex: 1,
            minWidth: 120,
            field: "shippingNo",
            headerName: "shipping no",
            editable: false,
            sortable: false,
        },
        {
            flex: 1,
            minWidth: 150,
            field: "complaintDetail",
            headerName: "Complaint Detail",
            editable: false,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: () => {
                return (
                    <p className="view_link" onClick={() => setOpenModal(true)}>View</p>
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "status",
            headerName: "Status",
            editable: false,
            sortable: false,
            renderCell: () => {
                return (
                    <Button className="btn_text c_success">New</Button>
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "closedBy",
            headerName: "closed by",
            editable: false,
            sortable: false,
        },
        {
            flex: 1,
            minWidth: 100,
            field: "remark",
            headerName: "remark",
            editable: false,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: () => {
                return (
                    <p className="view_link" onClick={() => setOpenModal2(true)}>View</p>
                );
            },
        },
        {
            flex: 1,
            minWidth: 60,
            field: "action",
            headerName: "Action",
            editable: false,
            sortable: false,
            renderCell: () => {
                return (
                    <div className="table_actions">
                        <Box>
                            <IconButton
                                aria-controls={open1 ? "basic-menu1" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick1}
                            >
                                <img src="/static/images/more_icon.svg" alt="" />
                            </IconButton>
                            <Menu
                                className="setting_menu"
                                id="basic-menu1"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button1",
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "8px",
                                        mt: 1,
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem>Active</MenuItem>
                                <MenuItem>Closed</MenuItem>
                            </Menu>
                        </Box>
                    </div>
                );
            },
        },
    ];

    let hidden = ["fullName"];

    return (
        <>
            <div className="sorting_table">
                <TableComponent
                    columns={columns?.filter((item) => !hidden.includes(item.field))}
                    rows={rows}
                />
            </div>

            <ComplaintDetail open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} />
            <ViewRemark open={openModal2} onClose={handleCloseModal2} setOpen={setOpenModal2} />
        </>
    );
};

export default CancelRefundList;
