import React from "react";
import "./App.scss";
import Routing from "./Routes";

const App = () => {

  return <Routing />;
};

export default App;
