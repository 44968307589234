import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import { Button, IconButton } from "@mui/material";
import AddNewRole from "../../../Modals/addNewRole";
import EditNewRole from "../../../Modals/editRole";
import { showError, showToast } from "../../../constants/toasts";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { useAddRoleMutation, useDeleteRoleByIdMutation, useEditRoleByIdMutation, useLazyGetRolesQuery } from "../../../services/subadminRoles";
import { Delete, DeleteTwoTone } from "@mui/icons-material";
import { handleDelete } from "../../../utils/commonFunctions";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import useAuth from "../../../hooks/useAuth";

const Roles = () => {

    const navigate = useNavigate();
    const[rolesData,setRolesData]=useState<any>([]);
    const [openModal, setOpenModal] = useState(false);
    const[selectedId,setSelectedId]=useState("");
    const staticPermission=[
        {
        label:"Registration",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Shipment Tracks",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Report an issue",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Looking for travelers",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Reports",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Membership Level",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"User Management",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Manage Notification",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        {
        label:"Content Management",
        isEdit:false,
        isView:false,
        isDelete:false
        },
        
    ];
    const[permission,setPermission]=useState(staticPermission);
    const handleCloseModal = () => {
        setSelectedId("");
        setPermission(staticPermission);
        setOpenModal(false);
    };
    const[newRole,setNewRole]=useState("");

    const[getRoles]=useLazyGetRolesQuery();
    const[deleteById]=useDeleteRoleByIdMutation();

    const getRolesList = async () => {
      try {
        const response = await getRoles({
          // page: page,
          // limit: 10,
          // query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (response?.statusCode === 200) {
          console.log("ROLE",response);
          setRolesData(response?.data?.data|| []);
          // setTotalCount(response?.data?.count);
        } else {
          setRolesData([]);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    };

    const[addRole]=useAddRoleMutation();
    const[updateRole]=useEditRoleByIdMutation();
const handleAddRole = async(event: React.FormEvent,setOpen:any,setAllAccess:any) => {
        event.preventDefault();
        if(newRole=="")
        {
          showError("Role name is required");
          return;
        }
        if(newRole.length>60)
          {
            showError("Role name cant exceed 60 characters");
          }

        const body={
          role:newRole,
          permissions:permission
        }
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        try{
        const response=(selectedId?await updateRole({id:selectedId,body:encryptedBody}).unwrap():await addRole(encryptedBody).unwrap());
        if(response.statusCode==200)
        {
            setOpen(false);
          showToast("Role "+(selectedId?"updated":"added")+" successfully");
          getRolesList();
          setPermission((prevData:any)=>{
            return prevData.map((row:any)=>{
              return {...row,isView:false,isDelete:false,isEdit:false}
            })
          })
          setNewRole("");  
          setAllAccess(false);
        }
        }
        catch(error:any){
          showError(error?.data?.message);
        }
      
      };

      useEffect(()=>{
        getRolesList();
      },[])
      const user=useAuth();
    return (
        <>
            <>
                <div className="main_title d_flex">
                    <div className="left_s">
                        <h1>Roles</h1>
                        <p>
                            <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                            User Management - Roles
                        </p>
                    </div>
                    <div className="right_s">
                    {(user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isEdit)? <Button className="btn btn_secondary" onClick={() => setOpenModal(true)}>Create Roles</Button>:null}
                    </div>
                </div>

                <div className="roles_list gap_m">
                    
                    
                    {rolesData?.map((data:any,index:number)=>{
                       return <div className="cards" style={{position:"relative",paddingBottom:"60px"}}>
                        {(user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isDelete)?<IconButton color="error" sx={{position:"absolute",top:12,right:15}} 
                        onClick={() => handleDelete(deleteById, data?._id, getRolesList)}>
                            <DeleteTwoTone/>
                            </IconButton>:null}
                        <h2>{data?.role}</h2>
                        <p>Total users with this role: {data?.userCount}</p>
                        <ul style={{ display: "grid",gridTemplateColumns: "repeat(2, 1fr)",gap: "10px"}}>
                            {data?.permissions?.map((row:any)=>{
                                return (row.isView?<li style={{fontSize:"12px"}}>{row.label}</li>:null);
                            })
                            }
                        </ul>
                        <div className="btn_flex" style={{position:"absolute",bottom:10}}>
                            <Button className="btn btn_highlight" onClick={()=>navigate(`details/${data?._id}`)}>View Role</Button>
                            {(user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isEdit)? <Button className="btn btn_highlight" onClick={() => {
                                setSelectedId(data?._id);
                                setNewRole(data?.role)
                                setPermission((prevData:any)=>{
                                    return prevData.map((row:any)=>{
                                        const temp=data?.permissions?.find((item:any)=>item.label==row.label)
                                        if(temp)
                                            {
                                             return temp;
                                            }
                                            else
                                            {
                                                return row;
                                            }
                                    })
                                });
                                setOpenModal(true)}}>Edit Role</Button>:null}
                        </div>
                    </div>
                    })
                   
                    }
                </div>
            </>

            <AddNewRole data={selectedId} setData={setSelectedId} open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} permission={permission} setPermission={setPermission} newRole={newRole} setNewRole={setNewRole} handleAddRole={handleAddRole}/>
        </>
    );
};

export default Roles;
