import secureLocalStorage from "react-secure-storage";

export const setToStorage = (key: any, data: any) => {
  secureLocalStorage.setItem(key, data);
};

export const getFromStorage = (key: any) => {
  return secureLocalStorage.getItem(key);
};

export const removeFromStorage = (key: any) => {
  secureLocalStorage.removeItem(key);
};
