/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import {
  Button,
  Box,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { showError } from "../../../constants/toasts";
import { exportToExcel } from "../../../utils/exportToExcel";
import { exportToPdf } from "../../../utils/exportToPdf";

const FeedbackFilter = ({
  setColumnVisibilityModel,
  columnVisibilityModel,
  setSearchTerm,
  searchTerm,
  gridApi,
  selectedRow,
}: {
  setColumnVisibilityModel: any,
  columnVisibilityModel: any,
  setSearchTerm: any,
  searchTerm: any,
  gridApi: any,
  selectedRow: any,
}) => {
  // const [searchTerm, setSearchTerm] = useState("");

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  return (
    <>
      <Box className="search_box c_white">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setSearchTerm}
          placeholder="Search shipment feedback"
          value={searchTerm}
          onCross={() => {
            setSearchTerm("");
            //  setSearchValue("")
          }}
          onChange={(e: any) => {
            setSearchTerm(e.target.value)
            // setSearchValue(e.target.value)
          }

          }
        />
      </Box>
      <Box>
        <Button
          className="btn btn_white"
          aria-controls={open1 ? "basic-menu1" : undefined}
          aria-haspopup="true"
          aria-expanded={open1 ? "true" : undefined}
          onClick={handleClick1}
        >
          <img src="/static/images/eye_icon.svg" alt="Icon" /> Display Fields
        </Button>
        <Menu
          className="filter_menu"
          id="basic-menu1"
          anchorEl={anchorEl1}
          open={open1}
          onClose={handleClose1}
          MenuListProps={{
            "aria-labelledby": "basic-button1",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
              borderRadius: "10px",
              mt: 1,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <h2>Display & Sort Fields</h2>
            <p>Displaying 10 fields in a row</p>
          </MenuItem>
          <MenuItem className="checkbox_group">
            <FormGroup className="gap_m">

              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.freightCustomer}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        freightCustomer: !prevData.freightCustomer,
                      }))
                    }
                  />
                }
                label="Freight Customers"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.traveler}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        traveler: !prevData.traveler,
                      }))
                    }
                  />
                }
                label="Traveler"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.beneficiary}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        beneficiary: !prevData.beneficiary,
                      }))
                    }
                  />
                }
                label="Beneficiary"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.shipment}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        shipment: !prevData.shipment,
                      }))
                    }
                  />
                }
                label="Shipment No"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.currentCity}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        currentCity: !prevData.currentCity,
                      }))
                    }
                  />
                }
                label="Current city"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.destinationCity}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        destinationCity: !prevData.destinationCity,
                      }))
                    }
                  />
                }
                label="Destination City"
              />
              {columnVisibilityModel.hasOwnProperty('status') && <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.status}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        status: !prevData.status,
                      }))
                    }
                  />
                }
                label="Status"
              />}
            </FormGroup>
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        <Button
          className="btn btn_secondary"
          aria-controls={open3 ? "basic-menu3" : undefined}
          aria-haspopup="true"
          aria-expanded={open3 ? "true" : undefined}
          onClick={handleClick3}
        >
          <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
        </Button>
        <Menu
          className="filter_menu"
          id="basic-menu3"
          anchorEl={anchorEl3}
          open={open3}
          onClose={handleClose3}
          MenuListProps={{
            "aria-labelledby": "basic-button3",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
              borderRadius: "10px",
              mt: 1,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <h2>Choose Option</h2>
            <p>Select an option to export</p>
          </MenuItem>
          <MenuItem className="export_btns">
            <Button
              onClick={() => {
                selectedRow?.row?.length
                  ? exportToPdf(
                    selectedRow?.row,
                    selectedRow?.column
                  )
                  : showError("Please select atleast one row");
              }}
            >
              PDF
            </Button>
            <Button
              onClick={() => {
                selectedRow?.row?.length
                  ? gridApi.current.exportDataAsCsv()
                  : showError("Please select atleast one row");
              }}
            >
              CSV
            </Button>
            <Button
              onClick={() => {
                selectedRow?.row?.length
                  ? exportToExcel(selectedRow?.row, selectedRow?.column)
                  : showError("Please select atleast one row");
              }}
            >
              Excel
            </Button>
            {/* <Button>Txt</Button> */}
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default FeedbackFilter;
