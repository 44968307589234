import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { DataGrid, GridFilterModel, GridLogicOperator, GridToolbar, useGridApiContext, useGridApiRef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import * as XLSX from 'xlsx'
type props = {
  columns: any;
  rows: any;
  columnVisibilityModel?: any;
  setSelectedRow?: any;
  gridApi?: any;
};

export default function TableComponent({ rows, columns, columnVisibilityModel, setSelectedRow, gridApi }: props) {
  // const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
  //   items: [
  //     {
  //       id:1,field:'availableWeight', value:"29",operator:"contains",
  //     },
  //     {
  //       id: 2,
  //       field: 'role',
  //       value: searchValue,
  //       operator: 'contains',
  //     },
  //   ],
  //   logicOperator: GridLogicOperator.And,
  // });

  // useEffect(()=>{
  //   setFilterModel((prevData:any)=>{
  //    return ({
  //       items:[
  //         {
  //         id:2,field:'role',value:searchValue,operator:'contains',
  //       },
  //     ],
  //     logicOperator: GridLogicOperator.And,
  //     })
  //   })
  // },[searchValue])
  // const handleSearch = (value: string) => {
  //   setSearchValue(value);
  // };
  // useEffect(()=>{
  //   console.log("sssssssssssssssssssssssssssss",rows)
  //   if(rows)
  //   setSelectedRow((prevData:any)=>{
  //     return {...prevData,row:rows}
  //    })
  // },[rows])
  useEffect(() => {
    if (gridApi?.current) {
      const data2 = gridApi?.current?.getVisibleColumns();
      setSelectedRow((prevData: any) => {
        return { ...prevData, column: data2?.slice(1, data2?.length) }
      })
      // setSelectedRow((prevData:any)=>{
      //  return {...prevData,column:data2?.slice(1,data2?.length-1)}
      // })
    }
  }, [columnVisibilityModel])
  return (
    <>
      {/* <Button onClick={()=>{
    setColumnVisibilityModel((prevData:any)=>({name:!prevData.name}))}}>TESTER</Button> */}
      {/* <Button onClick={()=>gridApi.current.exportDataAsCsv()}>TESTER</Button>
    <Button onClick={()=>gridApi.current.exportDataAsPrint()}>TESTER</Button>
    <Button onClick={()=>{
      
      const data=gridApi.current.getSelectedRows()
  
      // downloadPdf();
      }}>TESTER</Button> */}
      {/* <Button onClick={()=>{

const downloadExcel = () => {
  const newData = rows.map((row:any) => {
    delete row.tableData
    delete row.id
    delete row.image
    return row
  })
  const workSheet = XLSX.utils.json_to_sheet(newData)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, "students")
  //Buffer
  XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
  //Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
  //Download
  XLSX.writeFile(workBook, "StudentsData.xlsx")


}
downloadExcel();
    }}>TESTER</Button> */}

      <DataGrid
        apiRef={gridApi}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }
        }
        onRowSelectionModelChange={() => {
          const data: any = gridApi?.current?.getSelectedRows();
          console.log(data);
          if (gridApi?.current) {
            if (data?.size == 0)
              setSelectedRow((prevData: any) => {
                return { ...prevData, row: [] }
              })
            else {
              setSelectedRow((prevData: any) => {
                let temp = [];
                for (let [key1, value] of data) {

                  //  console.log('key1 :', key1);
                  temp?.push(rows.find((data: any) => data?.id == key1));

                }
                return { ...prevData, row: temp }
              });
            }
          }

        }
        }
        // filterModel={filterModel}
        // onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}

        columnVisibilityModel={columnVisibilityModel}
        // onColumnVisibilityModelChange={(newModel) =>
        //   setColumnVisibilityModel(newModel)
        // }
        // slots={{
        //   toolbar: GridToolbar,
        // }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        // slotProps={{
        //   toolbar: {
        //     showQuickFilter: true,
        //     quickFilterProps: { debounceMs: 500 },
        //     value: searchValue||"",
        //     clearSearch: () => handleSearch(""),
        //     onChange: (event: ChangeEvent<HTMLInputElement>) =>
        //       handleSearch(event.target.value),
        //   },
        // }}
        sx={{
          '.MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
          '.MuiDataGrid-virtualScroller': {
            height: rows.length ? "100%" : '50px'
          }
        }}
        rowHeight={70}
      />
    </>
  );
}
