import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Tabs, Tab, Box, TextField } from "@mui/material";
import EditText from "../../components/editText";
import AddFaq from "../../Modals/addFaq";
import { useFormik } from "formik";
import { showToast, showError } from "../../constants/toasts";
import useAuth from "../../hooks/useAuth";
import { useLazyGetCmsQuery, useUpdateCmsMutation } from "../../services/cms";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { useAddFaqMutation, useDeleteFaqByIdMutation, useLazyGetFaqQuery } from "../../services/faq";
import { isValidInput } from "../../utils/validations";
import { handleDelete } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import { ADMIN_ROLE_ID } from "../../constants/role";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ManageCms = () => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const user=useAuth();
    const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
    const [aboutUs, setAboutUs] = useState<string>("");
    const [termsAndConditions, setTermsAndConditions] = useState<string>("");
    const[email,setEmail]=React.useState("");
    const[phone,setPhone]=React.useState("");
    const[isLoading,setIsLoading]=useState(false);
    const[faq,setFaq]=useState<any>([]);
    const [value, setValue] = React.useState(0);
    const[phoneDisplay,setPhoneDisplay]=React.useState("");
    const [phoneCode, setPhoneCode] = useState("+91");
    const[whatsappPhoneDisplay,setWhatsappPhoneDisplay]=React.useState("");
    const [whatsappPhoneCode, setWhatsappPhoneCode] = useState("+91");
    const[imoPhoneDisplay,setImoPhoneDisplay]=React.useState("");
    const [imoPhoneCode, setImoPhoneCode] = useState("+91");
    
    const[getCms]=useLazyGetCmsQuery();
    const[putCms]=useUpdateCmsMutation();
    const[getFaq]=useLazyGetFaqQuery();
    const[deleteById]=useDeleteFaqByIdMutation();
    const[addFaq]=useAddFaqMutation();



    const formik= useFormik({
      enableReinitialize: true,
      initialValues: {
        email: "",
        phone: "",
        messengerId:"",
        instagramId:"",
        whatsapp:"",
        imo:""
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .required("Email is required")
          .matches(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            "Enter a valid email address"
          ),
  
        phone: Yup.string()
          .required("Phone number is required")
          .min(6, "Phone number must be at least 6 characters")
          .max(16, "Phone number must be at least 16 characters"),
          
        messengerId:Yup.string().required("Messenger ID is required"),
        instagramId:Yup.string().required("Messenger ID is required"),
      }),
      onSubmit: async (values) => {
        formik.setSubmitting(true);
        updateCmsData();
      }
    });

const getCmsData=async()=>{ 
      setIsLoading(true);
      try{
      const response=await getCms({}).unwrap();
      if(response.statusCode==200)
      { 
        setPrivacyPolicy(response?.data?.privacyPolicy);
        setTermsAndConditions(response?.data?.termsAndCondition);
        setAboutUs(response?.data?.about_us);
        formik.setFieldValue("email", response.data.email);
        formik.setFieldValue("messengerId", response?.data?.messengerId);
        formik.setFieldValue("instagramId", response?.data?.instagramId);
        
        formik.setFieldValue("phone", response?.data?.phone);
        setPhoneCode(response?.data?.countryCode);
        setPhoneDisplay(response?.data?.countryCode+response?.data?.phone);
        
        formik.setFieldValue("whatsapp", response?.data?.whatsappPhone);
        setWhatsappPhoneCode(response?.data?.whatsappCountryCode);
        setWhatsappPhoneDisplay(response?.data?.whatsappCountryCode+response?.data?.whatsappPhone);

        formik.setFieldValue("imo", response?.data?.imoPhone);
        // setImoPhoneCode(response?.data?.imoCountryCode);
        // setImoPhoneDisplay(response?.data?.imoCountryCode+response?.data?.imoPhone);

        console.log("CMS FETCH SUCCESS");
      }
      setIsLoading(false);
    }
    catch(error)
    {
      console.log(error);
    }}

    const handleChangePhone = (phone: any, country: any) => {
      formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
      setPhoneDisplay(country?.dailcode+phone);
      setPhoneCode(country?.dialCode);
    };

    const handleChangeWhatsappPhone = (phone: any, country: any) => {
      formik.setFieldValue("whatsapp", phone?.replace(country.dialCode, ""));
      setWhatsappPhoneDisplay(country?.dailcode+phone);
      setWhatsappPhoneCode(country?.dialCode);
    };

    const handleChangeImoPhone = (phone: any, country: any) => {
      formik.setFieldValue("imo", phone?.replace(country.dialCode, ""));
      setImoPhoneDisplay(country?.dailcode+phone);
      setImoPhoneCode(country?.dialCode);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    const updateCmsData=async()=>{
      try{
        const body={
          privacyPolicy:privacyPolicy,
          email:formik.values.email,
          phone: formik.values.phone,
          countryCode:(phoneCode.includes("+") ? "" : "+") + phoneCode,
          whatsappPhone: formik.values.whatsapp,
          whatsappCountryCode:(whatsappPhoneCode.includes("+") ? "" : "+") + whatsappPhoneCode,
          imoPhone: formik.values.imo,
          imoCountryCode:"IGNORE THIS PLEASE"||(imoPhoneCode.includes("+") ? "" : "+") + imoPhoneCode,
          termsAndCondition:termsAndConditions,
          about_us:aboutUs,
          messengerId:formik.values.messengerId,
          instagramId:formik.values.instagramId,
        }
        console.log('body',body);
        const encryptedData=generateEncryptedKeyBody(body) as CommonBody;
        const response=await putCms(encryptedData).unwrap();
        if(response.statusCode==200)
        {
          showToast("CMS updated sucessfully");
          console.log("CMS UPDATE SUCCESS");
        }
      }
      catch(error:any){
        showError(error?.data?.message);
        console.log(error);
      }
    }
    const[debouncedSearchTerm,setDebouncedSearchTerm]=useState("");
    const [totalCount, setTotalCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    let totalPages = Math.ceil(totalCount / 10);
    const onPageChange = (newPage: number) => {
      setPage(newPage);
    };
  
const getFaqData=async()=>{ 
        setIsLoading(true);
        try{
        const response=await getFaq({
            page:page,
            size:10,
            query: debouncedSearchTerm.trim(),
        }).unwrap();
        if(response.statusCode==200)
        { 
            setFaq(response?.data?.data);
            setTotalCount(response?.data?.count);
            console.log(response?.data);
        }
        setIsLoading(false);
      }
      catch(error)
      {
        console.log(error);
      }}


    React.useEffect(()=>{
      getCmsData();
    },[])
    React.useEffect(()=>{
        getFaqData();
    },[page,debouncedSearchTerm]);
    
    React.useEffect(()=>{
        setPage(1);
    },[debouncedSearchTerm]);
    const [valueTabs, setValueTabs] = React.useState(0);
    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValueTabs(newValue);
    };

    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const[error,setError]=useState(false);
    return (
        <>
            <div className="main_title">
                <h1>Manage CMS</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Manage CMS
                </p>
            </div>

            <div className="table_header">
                <div className="left_s">
                    <Box className="custom_tabs1" sx={{ width: "100%" }}>
                        <Tabs
                            value={valueTabs}
                            onChange={handleChangeTabs}
                            aria-label="basic tabs example"
                            className="custom_tabs_links"
                        >
                            <Tab label="Contact Us" {...a11yProps(0)} />
                            <Tab label="About Us" {...a11yProps(1)} />
                            <Tab label="Privacy Policy" {...a11yProps(2)} />
                            <Tab label="Terms And Conditions" {...a11yProps(3)} />
                            <Tab label="FAQ" {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                </div>
            </div>

            <div className="cards">
               
                <CustomTabPanel value={valueTabs} index={0}>
                <form className="form" onSubmit={formik.handleSubmit}>
                    <div className="gap_p">
                        <div className="control_group w_50">
                            <label>Email Address</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // helperText={formik.touched.email && formik.errors.email}
                            />
                             { formik.touched.email && formik.errors.email? (
                        <h6 className="err_msg">Email is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="control_group w_50">
                            <label>Messenger Id</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="messengerId"
                                value={formik.values.messengerId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // helperText={formik.touched.messengerId && formik.errors.messengerId}
                            />
                             { formik.touched.messengerId && formik.errors.messengerId? (
                        <h6 className="err_msg">Messenger Id is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="control_group w_50">
                            <label>Imo Id</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="imo"
                                value={formik.values.imo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // helperText={formik.touched.messengerId && formik.errors.messengerId}
                            />
                             { formik.touched.imo && formik.errors.imo? (
                        <h6 className="err_msg">Imo Id is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="control_group w_50">
                            <label>Instagram Id</label>
                            <TextField
                                variant="outlined"
                                placeholder="Enter here"
                                fullWidth
                                hiddenLabel
                                name="instagramId"
                                value={formik.values.instagramId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // helperText={formik.touched.messengerId && formik.errors.messengerId}
                            />
                             { formik.touched.messengerId && formik.errors.messengerId? (
                        <h6 className="err_msg">Messenger Id is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <div className="control_group w_50">
                            <label>Phone Number</label>
                            <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      specialLabel=""
                      value={phoneDisplay}
                      onChange={handleChangePhone}
                      inputStyle={{ width: "100%" }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                        </div>
                        <div className="control_group w_50">
                            <label>Whatsapp Phone Number</label>
                            <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      specialLabel=""
                      value={whatsappPhoneDisplay}
                      onChange={handleChangeWhatsappPhone}
                      inputStyle={{ width: "100%" }}
                    />
                    {formik.touched.whatsapp && formik.errors.whatsapp ? (
                      <h6 className="err_msg">
                        {formik.errors.whatsapp}
                      </h6>
                    ) : (
                      ""
                    )}
                        </div>
                        {/* <div className="control_group w_50">
                            <label>Imo Phone Number</label>
                            <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      specialLabel=""
                      value={imoPhoneDisplay}
                      onChange={handleChangeImoPhone}
                      inputStyle={{ width: "100%" }}
                    />
                    {formik.touched.imo && formik.errors.imo ? (
                      <h6 className="err_msg">
                        {formik.errors.imo}
                      </h6>
                    ) : (
                      ""
                    )}
                        </div> */}
                  
                    </div>
                    <div className="form_btn end">
                              {(user?.role==ADMIN_ROLE_ID ||user?.subadminRoleId?.permissions?.[7]?.isEdit)?<Button className="btn btn_secondary" type="submit">Save</Button> : null}
                    </div>
                </form>
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={1}>
                <form className="form" onSubmit={formik.handleSubmit}>
                        <EditText content={aboutUs} setContent={setAboutUs} />
                        <div className="form_btn end">
                                  {(user?.role==ADMIN_ROLE_ID ||user?.subadminRoleId?.permissions?.[7]?.isEdit)?<Button className="btn btn_secondary" type="submit">Save</Button> : null}
                        </div>
                    </form>
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={2}>
                <form className="form" onSubmit={formik.handleSubmit}>
                        <EditText content={privacyPolicy} setContent={setPrivacyPolicy} />
                        <div className="form_btn end">
                                  {(user?.role==ADMIN_ROLE_ID ||user?.subadminRoleId?.permissions?.[7]?.isEdit)?<Button className="btn btn_secondary" type="submit">Save</Button> : null}
                        </div>
                  </form>
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={3}>
                <form className="form" onSubmit={formik.handleSubmit}>
                        <EditText content={termsAndConditions} setContent={setTermsAndConditions} />
                        <div className="form_btn end">
                                  {(user?.role==ADMIN_ROLE_ID ||user?.subadminRoleId?.permissions?.[7]?.isEdit)?<Button className="btn btn_secondary" type="submit">Save</Button> : null}
                        </div>
                   </form>
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={4}>
                    <div className="table_header">
                        <div className="left_s">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            value={searchTerm}
                            onCross={() => setSearchTerm("")}
                            onChange={(val: any) => {
                            if (isValidInput(val.target.value)) {
                            setSearchTerm(val.target.value);
                                }
                            }}
                            placeholder="Search..."
                            />
                        </div>
                        <div className="right_s">
                        {(user?.role==ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[7]?.isEdit)? <Button className="btn btn_secondary" onClick={() => setOpenModal(true)}>Add FAQ</Button> : null}
                        </div>
                    </div>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="v3">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Question</TableCell>
                                    <TableCell>Answer</TableCell>
                                    <TableCell>Date</TableCell>
                                    {(user?.role==ADMIN_ROLE_ID ||user?.subadminRoleId?.permissions?.[7]?.isDelete)? <TableCell>Action</TableCell> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              {faq?.map((row:any,i:number)=>{
                                return <TableRow>
                                <TableCell>{(page-1)*10+i+1}</TableCell>
                                <TableCell>{row?.question}</TableCell>
                                <TableCell className="max_c2">
                                    <p>{row?.answer}</p>
                                </TableCell>
                                <TableCell>{row?.createdAt.slice(0,10)}</TableCell>
                                {(user?.role==ADMIN_ROLE_ID ||user?.subadminRoleId?.permissions?.[7]?.isDelete)?<TableCell>
                                    <Box>
                                        <IconButton onClick={()=>handleDelete(deleteById, row?._id, getFaqData)}>
                                            <img src="/static/images/trash_icon.svg" alt="Icon" />
                                        </IconButton>
                                    </Box>
                                </TableCell> : null}
                                </TableRow>

                              }) } 
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
          module={faq}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
                </CustomTabPanel>
            </div>
           
            <AddFaq open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} addFaq={addFaq} getFaqList={getFaqData}/>
        </>
    );
};

export default ManageCms;
