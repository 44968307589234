import { Checkbox, FormControlLabel, FormGroup, Modal, Button } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField';

interface Permission{
    label:string;
    isEdit:boolean;
    isView:boolean;
    isDelete:boolean;
}

interface AddRoleModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
    permission:Permission[];
    newRole:any;
    setNewRole:any;
    setPermission:any;
    handleAddRole:any;
    data:any;
    setData:any;
}

export default function AddNewRole({ open, onClose, setOpen,permission,newRole,setNewRole,setPermission,handleAddRole,data,setData }: AddRoleModalProps) {
const[error,setError]=useState(false);
const[allAccess,setAllAccess]=useState(false);
useEffect(()=>{

if(data){
let flag=0;
permission.map((data:any)=>{    
if(data.isView==false||data.isEdit===false||data.isDelete===false)
{
flag=1;
}
if(flag==0)
 {
        setAllAccess(true);
 }
 else
 {
    setAllAccess(false);
 }
})}
},[open])
    return (
        <Modal
            className="modal addRole_modal"
            id="addRoleModal"
            aria-labelledby="addRole-modal-title"
            aria-describedby="addRole-modal-description"
            open={open}
            onClose={()=>{
                // setPermission((prevData:any[])=>{
                //     return prevData.map((data:any)=>{
                //         return ({...data,isView:false,isDelete:false,isEdit:false})
                //     })
                // })
                setAllAccess(false);
                setNewRole("");
                onClose();
            }}

        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>{data?"Edit":"Add"} New Role</h2>
                        <p>Here you can add a new user</p>
                    </div>
                    <form action="">
                        <div className="control_group">
                            <TextField
                                variant="outlined"
                                placeholder="Role name"
                                fullWidth
                                hiddenLabel
                                value={newRole}
                                onChange={(e)=>setNewRole(e.target.value)}
                            />
                             { error && newRole==""? (
                        <h6 className="err_msg">Role name is required</h6>
                      ) : (
                        ""
                      )}
                        </div>
                        <h3>Roles permission</h3>
                        <ul className="roles_list">
                            <li>
                                <p>Administrator access</p>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={allAccess} onChange={()=>{
                                            if(!allAccess)
                                            {
                                                setPermission((prevData:Permission[])=>{
                                                    return prevData.map((data:Permission)=>{
                                                        return ({...data,isView:true,isDelete:true,isEdit:true})
                                                    })
                                                })
                                            }else{
                                                setPermission((prevData:Permission[])=>{
                                                    return prevData.map((data:Permission)=>{
                                                        return ({...data,isView:false,isDelete:false,isEdit:false})
                                                    })
                                                })
                                            }
                                            setAllAccess(!allAccess);
                                        }}/>}
                                        label={!allAccess?"Select all":"DeSelect All"}
                                    />
                                </FormGroup>
                            </li>
                           {permission?.map((data:Permission,index:number)=>(

                                <li>
                                <p>{data.label}</p>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={data.isView} onChange={()=>{
                                        setPermission((prevData:Permission[])=>{
                                            return prevData.map((row:Permission,i:number)=>{
                                                if(index==i)
                                                    {   if(row.isView)
                                                        {

                                                            return {...row,isView:false,isDelete:false,isEdit:false}
                                                        }
                                                        return {...row,isView:!data.isView}

                                                    }
                                            return row;
                                            })
                                        })
                                        setAllAccess(false);
                                        }} />}
                                        label="Read"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={data.isEdit} onChange={()=>{
                                            setPermission((prevData:Permission[])=>{
                                                return prevData.map((row:Permission,i:number)=>{
                                                    if(index==i)
                                                        {   if(!row.isEdit)
                                                            {
                                                                return {...row,isView:true,isEdit:true}

                                                            }
                                                            return {...row,isEdit:!data.isEdit}
                                                        }
                                                return row;
                                                })
                                            })
                                        setAllAccess(false);

                                            }}/>}
                                        label="Create/Edit"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={data.isDelete} onChange={()=>{
                                            setPermission((prevData:Permission[])=>{
                                                return prevData.map((row:Permission,i:number)=>{
                                                    if(index==i)
                                                        {
                                                            if(!row.isDelete)
                                                                {
                                                                    return {...row,isView:true,isDelete:true}
    
                                                                }
                                                            return {...row,isDelete:!data.isDelete}
                                                        }
                                                return row;
                                                })
                                            })
                                        setAllAccess(false);

                                            }}/>}
                                        label="Delete"
                                    />
                                </FormGroup>
                            </li>
    )) 
                            }
                        </ul>
                        <div className="footer_btn_flex">
                            <Button className="btn btn_highlight"
                            onClick={()=>{
                                setOpen(false);
                                setNewRole("");
                                setAllAccess(false);
                                onClose();
                                setError(false);
                            }}>Discard</Button>
                            <Button className="btn btn_secondary"
                            onClick={(e:any)=>{
                                setError(true);
                                if(newRole=="")return;
                                handleAddRole(e,setOpen,setAllAccess);
                                setError(false);
                            }}
                            >Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal >
    )
}
