import React, { useState } from "react";
import PassportResidenceModal from "../../Modals/passportResidenceModal";
import VehicleRegistrationModal from "../../Modals/vehicleRegistration";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Button, IconButton } from '@mui/material';
import ViewComment from "../../Modals/viewComment";
import ShippingGallery from "../../Modals/shippingGallery";
import { useNavigate, useParams } from "react-router-dom";
import PassportModal from "../../Modals/passportModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import { travelTypeImages } from "../../constants/wayOfTravelImages";
const PersonalInformation = ({userData}:any) => {
    console.log("MOOOOO",userData);
    const navigate = useNavigate();
    const[selectedImageIndex,setSelectedImageIndex]=useState(0);
    const[imageArray,setImageArray]=React.useState([]);
    const [travelType,setTravelType]=useState<number[]>([]);
React.useEffect(()=>{
let temp:any=[];
userData?.lastShipment?.packageDetail?.map((data:any)=>{
temp.push(data?.side);
temp.push(data?.photo);
temp.push(data?.front);
temp?.push(data?.back)
})
setImageArray(temp);
if(userData?.lastShipment?.travelDetail)
for(let key of userData?.lastShipment?.travelDetail)
{
    if(key?.travelType)
        setTravelType(prev=>[...prev,key.travelType])
}
},[userData])


const countryCurrencies:any = {
    1: "United States Dollar",
    7: "Russian Ruble",
    20: "Egyptian Pound",
    27: "South African Rand",
    30: "Euro",
    31: "Euro",
    32: "Euro",
    33: "Euro",
    34: "Euro",
    36: "Australian Dollar",
    39: "Euro",
    40: "Euro",
    41: "Swiss Franc",
    43: "Euro",
    44: "British Pound Sterling",
    45: "Danish Krone",
    46: "Swedish Krona",
    47: "Norwegian Krone",
    48: "Polish Zloty",
    49: "Euro",
    51: "Peruvian Sol",
    52: "Mexican Peso",
    53: "Cuban Peso",
    54: "Argentine Peso",
    55: "Brazilian Real",
    56: "Chilean Peso",
    57: "Colombian Peso",
    58: "Venezuelan Bolívar",
    60: "Malaysian Ringgit",
    61: "Australian Dollar",
    62: "Indonesian Rupiah",
    63: "Philippine Peso",
    64: "New Zealand Dollar",
    65: "Singapore Dollar",
    66: "Thai Baht",
    81: "Japanese Yen",
    82: "South Korean Won",
    84: "Vietnamese Dong",
    86: "Chinese Yuan",
    90: "Turkish Lira",
    91: "Indian Rupees",
    92: "Pakistani Rupee",
    93: "Afghan Afghani",
    94: "Sri Lankan Rupee",
    95: "Burmese Kyat",
    98: "Iranian Rial",
    211: "South Sudanese Pound",
    212: "Moroccan Dirham",
    213: "Algerian Dinar",
    216: "Tunisian Dinar",
    218: "Ecuadorian Sucre",
    220: "Gambian Dalasi",
    221: "West African CFA franc",
    222: "Mauritanian Ouguiya",
    223: "Malian Franc",
    224: "Guinean Franc",
    225: "West African CFA franc",
    226: "Central African CFA franc",
    227: "West African CFA franc",
    228: "West African CFA franc",
    229: "West African CFA franc",
    230: "Ethiopian Birr",
    231: "Eritrean Nakfa",
    232: "Central African CFA franc",
    233: "Ghanaian Cedi",
    234: "Icelandic Krona",
    235: "Central African CFA franc",
    236: "Central African CFA franc",
    237: "Central African CFA franc",
    238: "Cape Verdean Escudo",
    239: "Sao Tome and Principe Dobra",
    240: "Central African CFA franc",
    241: "Central African CFA franc",
    242: "Central African CFA franc",
    243: "Congolese Franc",
    244: "Angolan Kwanza",
    245: "West African CFA franc",
    246: "West African CFA franc",
    247: "Saint Helenian Pound",
    248: "Seychellois Rupee",
    249: "Sudanese Pound",
    250: "Rwandan Franc",
    251: "Ethiopian Birr",
    252: "Somali Shilling",
    253: "Djiboutian Franc",
    254: "Kenyan Shilling",
    255: "Tanzanian Shilling",
    256: "Ugandan Shilling",
    257: "Burundian Franc",
    258: "Mozambican Metical",
    260: "Zambian Kwacha",
    261: "Malagasy Ariary",
    262: "Comorian Franc",
    263: "Zimbabwean Dollar",
    264: "Namibian Dollar",
    265: "Malawian Kwacha",
    266: "Lesotho Loti",
    267: "Botswana Pula",
    268: "Swazi Lilangeni",
    269: "Central African CFA franc",
    290: "Saint Helenian Pound",
    291: "Eritrean Nakfa",
    297: "Aruban Florin",
    298: "Faroese Króna",
    299: "Greenlandic Krone",
    350: "Gibraltar Pound",
    351: "Euro",
    352: "Euro",
    353: "Euro",
    354: "Icelandic Króna",
    355: "Albanian Lek",
    356: "Indian Rupee",
    357: "Euro",
    358: "Euro",
    359: "Bulgarian Lev",
    370: "Euro",
    371: "Euro",
    372: "Euro",
    373: "Moldovan Leu",
    374: "Armenian Dram",
    375: "Belarusian Ruble",
    376: "Euro",
    377: "Euro",
    378: "Euro",
    379: "Euro",
    380: "Ukrainian Hryvnia",
    381: "Serbian Dinar",
    382: "Euro",
    383: "Euro",
    385: "Euro",
    386: "Euro",
    387: "Bosnia and Herzegovina Convertible Mark",
    389: "Euro",
    420: "Czech Koruna",
    421: "Euro",
    423: "Swiss Franc",
    500: "Falkland Islands Pound",
    501: "Belize Dollar",
    502: "Guatemalan Quetzal",
    503: "Salvadoran Colón",
    504: "Moroccan Dirham",
    505: "Nicaraguan Córdoba",
    506: "Costa Rican Colón",
    507: "Panamanian Balboa",
    508: "East Caribbean Dollar",
    509: "Haitian Gourde",
    590: "Euro",
    591: "Bolivian Boliviano",
    592: "Guyanese Dollar",
    593: "Ecuadorian Sucre",
    594: "Euro",
    595: "Paraguayan Guaraní",
    596: "Euro",
    597: "Surinamese Dollar",
    598: "Uruguayan Peso",
    599: "Netherlands Antillean Guilder",
    670: "East Timorese Centavo",
    672: "Australian Dollar",
    673: "Brunei Dollar",
    674: "Bhutanese Ngultrum",
    675: "Papua New Guinean Kina",
    676: "Tongan Pa'anga",
    677: "Solomon Islands Dollar",
    678: "Vanuatu Vatu",
    679: "Fijian Dollar",
    680: "Palauan Dollar",
    681: "French Pacific Franc",
    682: "Cook Islands Dollar",
    683: "Niue Dollar",
    685: "Samoan Tala",
    686: "West African CFA franc",
    687: "French Pacific Franc",
    688: "Tuvaluan Dollar",
    689: "French Pacific Franc",
    690: "Seychellois Rupee",
    691: "United States Dollar",
    692: "United States Dollar",
    850: "North Korean Won",
    852: "Hong Kong Dollar",
    853: "Macanese Pataca",
    855: "Cambodian Riel",
    856: "Lao Kip",
    880: "Bangladeshi Taka",
    960: "Maldive Rufiyaa",
    961: "Turkish Lira",
    962: "Nepalese Rupee",
    963: "Syrian Pound",
    964: "Iraqi Dinar",
    965: "Kuwaiti Dinar",
    966: "Saudi Riyal",
    967: "Yemeni Rial",
    968: "Omani Rial",
    971: "UAE Dirham",
    972: "Israeli New Shekel",
    973: "Bahraini Dinar",
    974: "Qatari Riyal",
    975: "Bhutanese Ngultrum",
    976: "Mongolian Tögrög",
    977: "North Korean Won",
    992: "Tajikistani Somoni",
    993: "Turkmenistani Manat",
    994: "Azerbaijani Manat",
    995: "Georgian Lari",
    996: "Kyrgyzstani Som",
    998: "Uzbekistani Som"
};

const{id}=useParams();
    const[idData,setIdData]=useState({
        idCard:"",
        residenceCard:""
      });
      console.log(idData);
      const[vehicleData,setVehicleData]=useState<{vehicleReg?:string,
      side?:string,
      front?:string,
      back?:string,
      ticket?:string,
      wayOfTravel?:string
  }>({
        vehicleReg:"",
          side:"",
          front:"",
          back:"",
          ticket:"",
          wayOfTravel:""
      });
    const [openModal1, setOpenModal1] = useState(false);
    const handleCloseModal1 = () => {
        setOpenModal1(false);
    };

    const [openModal2, setOpenModal2] = useState(false);
    const handleCloseModal2 = () => {
        setOpenModal2(false);
    };

    const [openModal3, setOpenModal3] = useState(false);
    const handleCloseModal3 = () => {
        setOpenModal3(false);
    };

    const [openModal4, setOpenModal4] = useState(false);
    const handleCloseModal4 = () => {
        setOpenModal4(false);
    };
    const getResidentTypeName=(residenceType:number)=>{
        if(residenceType==1)
            {
                return "CITIZEN"
            }
            else if(residenceType==2)
                {
                    return "RESIDENT";
                }
                else if(residenceType==3)
                    {
                        return "VISITOR";
                    }
                    else
                    {
                        return "";
                    }
    }
    const getTravelTypeName=(travelType:number)=>{
        if(travelType==1)
            {
                return "Private Vehicle"
            }
            else if(travelType==2)
                {
                    return "Airlines"
                }
                else if(travelType==3)
                    {
                        return "Voyage"
                    }
                    else if(travelType==4)
                        {
                            return "Railway"
                        }
                        else if(travelType==5)
                            {
                                return "Public Road"
                            }
                            else 
                               {
                                 return ""
                                }
    }
    var settings = {
        dots:true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        // initialSlide: 0,
      };
      const user=useAuth();
    return (
        <>
            <div className="profileInfo_head">
                <h2>Personal Detail</h2>
                {  (user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isEdit)? 
                <Button className="btn btn_secondary" onClick={() => navigate("/edit-profile-detail/"+id,{replace:true})}>Edit Details</Button> : null}
            </div>

            <div className="profileInfo_list">
                <ul className="text_info gap_p">
                    <li className="w_50">
                        <strong>TR</strong>
                        <span>{userData?.lastShipment?.travelId?userData?.lastShipment?.travelId:"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Name</strong>
                        <span>{userData?.user?.fullName?userData?.user?.fullName:"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>E-mail</strong>
                        <span>{userData?.user?.email?userData?.user?.email:"-"}</span>
                    </li>
                    {/* <li className="w_50">
                        <strong>Mobile</strong>
                        <span>{userData?.user?.phone?(userData?.user?.countryCode+" "+userData?.user?.phone):"-"}</span>
                    </li> */}
                    <li className="w_50">
                        <strong>Residency Type </strong>
                        <span>{userData?.user?.personVerification?.residenceType?getResidentTypeName(userData?.user?.personVerification?.residenceType):"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Gender</strong>
                        <span>{userData?.user?.gender?(userData?.user?.gender=="1"?"Male":"Female"):"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>Current City</strong>
                        <span>{((userData?.lastShipment?.currentAddress?.city?userData?.lastShipment?.currentAddress?.city:"-")+", "+(userData?.lastShipment?.currentAddress?.district?userData?.lastShipment?.currentAddress?.district:"-"))}</span>
                    </li>
                    <li className="w_50">
                        <strong>Passport /ID</strong>
                        <IconButton className="text_icon" onClick={() => {
                setIdData({
                  idCard:userData?.lastShipment?.personVerification?.idCard,
                  residenceCard:userData?.lastShipment?.personVerification?.residenceCard
                })
                if(userData?.lastShipment?.personVerification?.residenceCard && userData?.lastShipment?.personVerification?.idCard)
                setOpenModal1(true)
                }}>
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>
                    </li>
                    <li className="w_50">
                        <strong>Current Address</strong>
                        <span>{userData?.lastShipment?.currentAddress?.address?userData?.lastShipment?.currentAddress?.address:"-"}</span>
                    </li>
            {     travelType?.some(data=>data==1) &&   <li className="w_50">
                        <strong>Vehicle Reg</strong>
                        <IconButton className="text_icon" onClick={()=>{
                
                // setVehicleData({...userData?.lastShipment?.vehicleDetail,
                //   ticket:userData?.lastShipment?.ticket,
                //   wayOfTravel:getTravelTypeName(userData?.lastShipment?.travelType)
                // });
                setVehicleData({...userData?.lastShipment?.vehicleDetail,ticket:null});
                  if(userData?.lastShipment?.travelDetail?.length)
                  setOpenModal2(true)
                  
                
                }}>
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>  </li>}
                   {travelType?.some(data=>data>1) &&  <li className="w_50">
                        <strong>Travel Ticket</strong>
                        <IconButton className="text_icon" onClick={()=>{
          
                setVehicleData({
                    ticket:userData?.lastShipment?.ticket,
                    wayOfTravel:getTravelTypeName(userData?.lastShipment?.travelType)
                  });
                  if(userData?.lastShipment?.travelDetail?.length)
                  setOpenModal2(true)
                  
                
                }}>
                <img src="/static/images/external_icon.svg" alt="" />
              </IconButton>  </li>}
                    {/* <li className="w_50">
                        <strong>Used weights</strong>
                        <span>16 Kg</span>
                    </li> */}
                    <li className="w_50">
                        <strong>Departure City/Date</strong>
                        <span>{(userData?.lastShipment?.travelDetail?.[0]?.departingFrom?userData?.lastShipment?.travelDetail?.[0]?.departingFrom:"-") + " /" + (userData?.lastShipment?.travelDetail?.[0]?.departureDate?.slice(0,10)?userData?.lastShipment?.travelDetail?.[0]?.departureDate?.slice(0,10):"-")}</span>
                    </li>
                    {/* <li className="w_50">
                        <strong>Way of Travel</strong>
                        <span>{userData?.lastShipment?.travelType?getTravelTypeName(userData?.lastShipment?.travelType):"-"}</span>
                    </li>
                   */}
                  
                {   userData?.lastShipment?.destinationAddress?.map((data:any,index:number)=>{

              return     <>
                        <li className="w_50">
                            <strong>Arrival City ({index+1})/Date</strong>
                            <span>{(data?.city?data?.city:"-")+"/"+(userData?.lastShipment?.travelDetail?.[index]?.arrivalDate?userData?.lastShipment?.travelDetail?.[index]?.arrivalDate?.slice(0,10):"-")}</span>
                        </li>
                        <li className="w_50">
                            <strong>Mobile ({index+1})</strong>
                            <span>{data?.phone?data?.countryCode+" "+data?.phone:"-"}</span>
                        </li>
                        <li className="w_50">
                            <strong>Destination Address ({index+1})</strong>
                            <span>{userData?.lastShipment?.travelDetail?.[index]?.destination?userData?.lastShipment?.travelDetail?.[index]?.destination:"-"}</span>
                        </li>
                        <li className="w_50">
                        <strong>Way of Travel  ({index+1})</strong>
                        <span>{userData?.lastShipment?.travelDetail?.[index]?.travelType?(<span>{getTravelTypeName(userData?.lastShipment?.travelDetail?.[index]?.travelType)}<figure><img className="black-image-profile" src={travelTypeImages[userData?.lastShipment?.travelDetail?.[index]?.travelType]}/></figure></span>):"-"}</span>
                    </li>
                        <li className="w_50">
                            <strong>Available weights ({index+1})</strong>
                            <span>{userData?.lastShipment?.travelDetail?.[index]?.availableWeight?`${userData?.lastShipment?.travelDetail?.[index]?.availableWeight} Kg`:"-"}</span>
                        </li>
                   </>




                }) 
                  }
                  
                    <li className="w_50">
                        <strong>Arrival Date</strong>
                        <span>{userData?.lastShipment?.travelDetail?.[userData?.lastShipment?.travelDetail?.length-1]?.arrivalDate?userData?.lastShipment?.travelDetail?.[userData?.lastShipment?.travelDetail?.length-1]?.arrivalDate?.slice(0,10):"-"}</span>
                    </li>
                    <li className="w_50">
                        <strong>No of Travels </strong>
                        <span>{userData?.travelCount?userData?.travelCount:"-"}</span>
                    </li>
                </ul>

                <div className="gap_p">
                   {userData?.lastShipment?.paymentType==1 ? <div className="w_50">
                        <h3>Payment Procedure Information _Advance (50%)</h3>
                        <ul className="text_info gap_p">
                            <li>
                                <strong>Currency</strong>
                                <span>{userData?.lastShipment?.finalPaymentCurrency==1?
                                (userData?.lastShipment?.currentAddress?.countryCode
                                    ?
                                    countryCurrencies[parseInt(userData?.lastShipment?.currentAddress?.countryCode?.slice(1,userData?.lastShipment?.currentAddress?.countryCode.length+1))]
                                    :
                                    "-")
                                :
                                (userData?.lastShipment?.destinationAddress?.[userData?.lastShipment?.destinationAddress?.length-1]?.countryCode
                                    ?
                                    countryCurrencies[parseInt(userData?.lastShipment?.destinationAddress?.[userData?.lastShipment?.destinationAddress?.length-1]?.countryCode?.slice(1,userData?.lastShipment?.destinationAddress?.[userData?.lastShipment?.destinationAddress?.length-1]?.countryCode.length+1))]
                                    :
                                    "-")

                                }</span>
                            </li>
                            <li>
                                <strong>Bank Name</strong>
                                <span>{userData?.lastShipment?.paymentArray?.bankName?userData?.lastShipment?.paymentArray?.bankName:"-"}</span>
                            </li>
                            <li>
                                <strong>Account Holder Name</strong>
                                <span>{userData?.lastShipment?.paymentArray?.accHolderName?userData?.lastShipment?.paymentArray?.accHolderName:"-"}</span>
                            </li>
                            <li>
                                <strong>Bank Name</strong>
                                <span>{userData?.lastShipment?.paymentArray?.accNumber?userData?.lastShipment?.paymentArray?.accNumber:"-"}</span>
                            </li>
                        </ul>
                    </div>
:
                    <div className="w_50">
                        <h3>Payment Procedure Information _Upon Delivery (100%)</h3>
                        <ul className="text_info gap_p">
                        <li>
                                <strong>Currency</strong>
                                <span>{userData?.lastShipment?.finalPaymentCurrency==1?
                                (userData?.lastShipment?.currentAddress?.countryCode
                                    ?
                                    countryCurrencies[parseInt(userData?.lastShipment?.currentAddress?.countryCode?.slice(1,userData?.lastShipment?.currentAddress?.countryCode.length+1))]
                                    :
                                    "-")
                                :
                                (userData?.lastShipment?.destinationAddress?.[userData?.lastShipment?.destinationAddress?.length-1]?.countryCode
                                    ?
                                    countryCurrencies[parseInt(userData?.lastShipment?.destinationAddress?.[userData?.lastShipment?.destinationAddress?.length-1]?.countryCode?.slice(1,userData?.lastShipment?.destinationAddress?.[userData?.lastShipment?.destinationAddress?.length-1]?.countryCode.length+1))]
                                    :
                                    "-")

                                }</span>
                            </li>
                            <li>
                                <strong>Bank Name</strong>
                                <span>{userData?.lastShipment?.paymentArray?.bankName?userData?.lastShipment?.paymentArray?.bankName:"-"}</span>
                            </li>
                            <li>
                                <strong>Account Holder Name</strong>
                                <span>{userData?.lastShipment?.paymentArray?.accHolderName?userData?.lastShipment?.paymentArray?.accHolderName:"-"}</span>
                            </li>
                            <li>
                                <strong>Bank Name</strong>
                                <span>{userData?.lastShipment?.paymentArray?.accNumber?userData?.lastShipment?.paymentArray?.accNumber:"-"}</span>
                            </li>

                        </ul>
                    </div>}
                </div>
            </div>

            {  imageArray?.length ? <div className="shipping_img">
                <h3>Shipping Pictures</h3>
                <ul>
               <div style={{width:"440px",marginLeft:"40px"}}> 
               <Slider {...settings}
                                            // centerMode={true}   
                                                 className='slider-2000'>
    
                       {imageArray?.map((data:any,index:number)=>{

                        return <div>
                            <figure style={{width:"100%",height:"110px"}} onClick={() => {
                                setSelectedImageIndex(index);
                                setOpenModal4(true)
                                }}><img  width="100%" src={data} alt="Shipping" /></figure>
                        </div>

                       })
                        }
                     </Slider>
               </div>
                </ul>

                <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table className="v2" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Shipping Customers</TableCell>
                                <TableCell>Customer comment</TableCell>
                                <TableCell>Delivery Home</TableCell>
                                <TableCell>Packaging service</TableCell>
                                <TableCell>Shipping</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Shipping cost</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>
                                            <b>Riyadh</b> +9203024595467
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="view_link" onClick={() => setOpenModal3(true)}>Comment <img src="/static/images/external_icon.svg" alt="Icon" /></p>
                                </TableCell>
                                <TableCell>No</TableCell>
                                <TableCell>Yes</TableCell>
                                <TableCell>
                                    <figure className="icon_text">
                                        <img src="/static/images/briefcase_icon.svg" alt="Icon" />
                                        <figcaption>Suit Case</figcaption>
                                    </figure>
                                </TableCell>
                                <TableCell>25</TableCell>
                                <TableCell>40$</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>
                                    <div className="user_block">
                                        <figure onClick={() => navigate("/profile-detail")}>
                                            <img src="/static/images/user_attachment.jpg" alt="" />
                                        </figure>
                                        <p>
                                            <b>Riyadh</b> +9203024595467
                                        </p>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="view_link" onClick={() => setOpenModal3(true)}>Comment <img src="/static/images/external_icon.svg" alt="Icon" /></p>
                                </TableCell>
                                <TableCell>No</TableCell>
                                <TableCell>Yes</TableCell>
                                <TableCell>
                                    <figure className="icon_text">
                                        <img src="/static/images/briefcase_icon.svg" alt="Icon" />
                                        <figcaption>Suit Case</figcaption>
                                    </figure>
                                </TableCell>
                                <TableCell>25</TableCell>
                                <TableCell>40$</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
       :null }
                        <PassportModal open={openModal1} onClose={handleCloseModal1} setOpen={setOpenModal1} idData={idData} />

                        <VehicleRegistrationModal  open={openModal2} onClose={handleCloseModal2} setOpen={setOpenModal2} vehicleData={vehicleData} />

            <ViewComment open={openModal3} onClose={handleCloseModal3} setOpen={setOpenModal3} />
            <ShippingGallery open={openModal4} onClose={handleCloseModal4} setOpen={setOpenModal4} imageArray={imageArray} selectedImageIndex={selectedImageIndex}/>
        </>
    );
};

export default PersonalInformation;
