import { Button, Modal } from '@mui/material'
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { Feedback } from '../types/shipmentFeedback';

interface GiveFeedback2ModalProps {
    open: boolean;
    onClose: () => void;
    setOpen: Dispatch<SetStateAction<boolean>>
    data: Feedback
}

export default function GiveFeedback2({ open, onClose, setOpen, data }: GiveFeedback2ModalProps) {

    const value1 = 4;

    return (
        <Modal
            className="modal giveFeedback_modal"
            id="giveFeedback2Modal"
            aria-labelledby="giveFeedback2-modal-title"
            aria-describedby="giveFeedback2-modal-description"
            open={open}
            onClose={onClose}
        >
            <div className="modal-dialog">
                <div className="modal-body">
                    <div className="btn-close" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </div>
                    <div className="modal_title">
                        <h2>Feedback</h2>
                        <p>Feedback about the shipment</p>
                    </div>

                    <h3>Did you received your delivery on time ?</h3>
                    <p>{data?.receivedOnTime ? "Yes" : "No"}</p>

                    <h3>
                        The commitment to delivery according to the agreed dates
                        <Rating name="read-only" value={data?.commitmentToDeliver || 0} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                    </h3>

                    <h3>
                        The quality of the shipment and its contents are ok ?
                        <Rating name="read-only" value={data?.qualityOfShipmentContent || 0} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                    </h3>

                    <h3>
                        Rate the overall experience with this traveler
                        <Rating name="read-only" value={data?.overallExperienceWithTraveller || 0} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                    </h3>

                    <h3>Feedback</h3>
                    <p>{data?.feedback || "No feedback given"}</p>
                    <div className="footer_btn_flex">
                        <Button className="btn btn_primary" onClick={() => setOpen(false)}>OK</Button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
