import { END_POINTS } from "../helpers/constants/urls";
import { CommonBody } from "../types/General";
// import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";
interface updateCms{
  privacyPolicy:string,
  email:string,
  phone: string,
  countryCode:string,
  termsAndCondition:string,
  about_us:string,
  messengerId:string,
}
type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<
      CommonResponseType & { data: any },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.cms}`,
        method: "GET",
      }),
    }),
  updateCms: builder.mutation<
    CommonResponseType & { data: updateCms},
    CommonBody
  >({
    query: (body) => ({
      url: `${END_POINTS.cms}`,
      method: "PUT",
      body
    }),
  }),

})});
export const {
    useLazyGetCmsQuery,
    useUpdateCmsMutation
}=dashboardApi;