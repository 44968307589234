/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from "react-router-dom";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";

const GoldList = () => {

    const navigate = useNavigate();

    const value1 = 4.5;
    const value2 = 4.5;
    const value3 = 4;

    const rows = [
        {
            id: 1,
            loyalPoints: "100Kgs",
            tripRecorded: "400",
            ordersNumber: "30",
            reliability: "10 (High)",
        },
        {
            id: 2,
            loyalPoints: "100Kgs",
            tripRecorded: "400",
            ordersNumber: "30",
            reliability: "10 (High)",
        },
        {
            id: 3,
            loyalPoints: "100Kgs",
            tripRecorded: "400",
            ordersNumber: "30",
            reliability: "10 (High)",
        },
        {
            id: 4,
            loyalPoints: "100Kgs",
            tripRecorded: "400",
            ordersNumber: "30",
            reliability: "10 (High)",
        },
        {
            id: 5,
            loyalPoints: "100Kgs",
            tripRecorded: "400",
            ordersNumber: "30",
            reliability: "10 (High)",
        },
    ];
    const user=useAuth();
    const columns: GridColDef<(typeof rows)[number]>[] = [
        {
            flex: 1,
            minWidth: 180,
            field: "passenger",
            headerName: "Passenger",
            editable: false,
            renderCell: (data:any) => {
                return (
                    <div className="user_block">
                        <figure onClick={() => 
                        ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)? navigate("/profile-detail/"+data?.row?.userId) : null)

                            }>
                            <img src="/static/images/user_attachment.jpg" alt="" />
                        </figure>
                        <p>
                            <b>Brandon King</b>
                            <Rating name="read-only" value={value1} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                        </p>
                    </div>
                );
            },
        },
        {
            flex: 1,
            minWidth: 140,
            field: "contacts",
            headerName: "Contacts",
            editable: false,
            renderCell: () => {
                return (
                    <p>youth@gmail.com <br /> +923456789</p>
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "loyalPoints",
            headerName: "Loyal Points",
            editable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 120,
            field: "tripRecorded",
            headerName: "Trip Recorded",
            editable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 100,
            field: "ordersNumber",
            headerName: "No. Orders",
            editable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 100,
            field: "reliability",
            headerName: "Reliability",
            editable: false,
            sortable: false,
            align: "center",
            headerAlign: "center"
        },
        {
            flex: 1,
            minWidth: 120,
            field: "Commitment",
            headerName: "Commitment",
            editable: false,
            sortable: false,
            renderCell: () => {
                return (
                    <Rating className="v2" name="read-only" value={value2} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                );
            },
        },
        {
            flex: 1,
            minWidth: 120,
            field: "Quality",
            headerName: "Quality",
            editable: false,
            sortable: false,
            renderCell: () => {
                return (
                    <Rating className="v2" name="read-only" value={value3} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                );
            },
        },
    ];

    let hidden = ["fullName"];

    return (
        <>
            <div className="sorting_table">
                <TableComponent
                    columns={columns?.filter((item) => !hidden.includes(item.field))}
                    rows={rows}
                />
            </div>
        </>
    );
};

export default GoldList;
