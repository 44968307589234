// import React, { Dispatch, SetStateAction } from "react";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// import { FormControl, MenuItem, Select } from "@mui/material";

// type props = {
//   module: any;
//   page: number;
//   onPageChange: (newPage: number) => void;
//   totalPages: number;
//   totalCount: number;
//   setPage: Dispatch<SetStateAction<number>>;
// };

// export default function Pagination({
//   module,
//   page,
//   onPageChange,
//   totalPages,
//   setPage,
//   totalCount,
// }: props) {
//   const startIndex = (page - 1) * 10 + 1;
//   const endIndex = Math.min(startIndex + 10 - 1, totalCount);
//   return (
//     <div className="pagination_wrp">
//       <p>
//         Showing <strong>{module?.length ? `${module?.length}` : 0}</strong> from
//         <strong> {totalCount || ""}</strong> data
//       </p>
//       <div className="pagination_number">
//         {totalPages > 5 ? (
//           <FormControl fullWidth className="all_page">
//             <Select
//               className="dropse"
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               inputProps={{
//                 "aria-label": "Without label",
//               }}
//               displayEmpty
//               value={page}
//               onChange={(pages: any) => setPage(pages.target.value)}
//             >
//               <MenuItem value="" disabled>
//                 Select
//               </MenuItem>
//               {Array.from({ length: totalPages }).map((_, index) => (
//                 <MenuItem key={index + 1} value={index + 1}>
//                   {index + 1}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         ) : null}
//         <button
//           className="page_icon first_icon"
//           onClick={() => onPageChange(page - 1)}
//           disabled={page === 1}
//         >
//           <KeyboardDoubleArrowLeftIcon className="mat_icon" />
//         </button>
//         <ul className="pagination_list">
//           {totalPages > 4
//             ? Array.from({ length: totalPages })
//                 .slice(0, 3)
//                 .map((_, index) => (
//                   <li
//                     className={page === index + 1 ? "active" : ""}
//                     key={index}
//                     onClick={() => onPageChange(index + 1)}
//                     // disabled={page === index + 1}
//                     // type="button"
//                   >
//                     {index + 1}
//                   </li>
//                 ))
//                 .concat(
//                   <span
//                     key="ellipsis"
//                     className="ellipsis"
//                     style={{ color: "black" }}
//                   >
//                     ...
//                   </span>,
//                   <li
//                     className="active"
//                     // type="button"
//                     style={{
//                       display:
//                         page < 4 || page === totalPages ? "none" : undefined,
//                     }}
//                     key={totalPages}
//                   >
//                     {page}
//                   </li>,
//                   <li
//                     className={page === totalPages ? "active" : "inActv"}
//                     key={totalPages}
//                     // type="button"
//                     onClick={() => onPageChange(totalPages)}
//                     // disabled={page === totalPages}
//                   >
//                     {totalPages}
//                   </li>
//                 )
//             : Array.from({ length: totalPages }).map((_, index) => (
//                 <li
//                   className={page === index + 1 ? "active" : "inActv"}
//                   // type="button"
//                   key={index}
//                   onClick={() => onPageChange(index + 1)}
//                   // disabled={page === index + 1}
//                 >
//                   {index + 1}
//                 </li>
//               ))}
//         </ul>
//         {/* <ul className="pagination_list">
//           <li className="active">1</li>
//           <li>2</li>
//           <li>3</li>
//           <li>4</li>
//         </ul> */}
//         <button
//           className="page_icon"
//           onClick={() => onPageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           <KeyboardDoubleArrowRightIcon className="mat_icon" />
//         </button>
//       </div>
//     </div>
//   );
// }
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type props = {
  module: any;
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
};

const Pagination = ({ module, page, onPageChange, totalPages }: props) => {
  return (
    <div className="pagination" style={{ marginTop: "20px" }}>
      <p>
        <span>Showing {module?.length ? `${module?.length}` : 0} items </span>
      </p>
      <div className="pages">
        <button
          className="prevbtn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
        >
          <ArrowBackIosIcon />
        </button>
        <div className="count">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              className={page === index + 1 ? "actv" : "inActv"}
              key={index}
              onClick={() => onPageChange(index + 1)}
              disabled={page === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          className="prevbtn"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );
};

export default Pagination;

