import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import SearchBar from "../../../components/SearchBar";
import { Box, Button, Menu, MenuItem, Tabs, Tab } from "@mui/material";
import SilverList from "../../../features/membershipLevel/silverList";

const MembershipLevelSilver = () => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const open3 = Boolean(anchorEl3);
    const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    return (
        <>
            <div className="main_title">
                <h1>Membership Level</h1>
                <p>
                    <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
                    Membership Level - Silver
                </p>
            </div>

            <div className="table_header">
                <div className="left_s">
                    <Box className="custom_tabs1" sx={{ width: "100%" }}>
                        <Tabs className="custom_tabs_links">
                            <Tab label="Bronze Users" onClick={() => navigate("/membership-level/bronze")} />
                            <Tab label="Silver Users" className="Mui-selected" onClick={() => navigate("/membership-level/silver")} />
                            <Tab label="Gold Users" onClick={() => navigate("/membership-level/gold")} />
                        </Tabs>
                    </Box>
                </div>
                <div className="right_s">
                    <Button className="btn btn_white fw_400" onClick={() => navigate("/broadcast-message")}>Broadcast Message</Button>
                    <Box className="search_box c_white">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setSearchTerm}
                            placeholder="Search silver passenger"
                        />
                    </Box>
                    <Box>
                        <Button
                            className="btn btn_secondary"
                            aria-controls={open3 ? "basic-menu3" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open3 ? "true" : undefined}
                            onClick={handleClick3}
                        >
                            <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
                        </Button>
                        <Menu
                            className="filter_menu"
                            id="basic-menu3"
                            anchorEl={anchorEl3}
                            open={open3}
                            onClose={handleClose3}
                            MenuListProps={{
                                "aria-labelledby": "basic-button3",
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                                    borderRadius: "10px",
                                    mt: 1,
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem>
                                <h2>Choose Option</h2>
                                <p>Select an option to export</p>
                            </MenuItem>
                            <MenuItem className="export_btns">
                                <Button>PDF</Button>
                                <Button>CSV</Button>
                                <Button>Excel</Button>
                                <Button>Txt</Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </div>
            </div>

            <div className="cards">
                <SilverList />
            </div>
        </>
    );
};

export default MembershipLevelSilver;

